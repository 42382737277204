import { useEffect, useState } from 'react';
import { MenuGeneric } from "components/Menus";
import EmployeeHappiness from './polls/employee-happiness';

import * as service from "./api";

const modules = {
    1: EmployeeHappiness,
}
const DefaultModule = () => (
    "Diese Umfrage kann noch nicht ausgewertet werden"
)

const selectAll = ( linkId ) => () => {
    /* Get the text field */
    var copyText = document.getElementById( linkId );

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 999); /* For mobile devices */
}

export const ApplyingPollRun = (props) => (
    <ToolsPollRun { ...props } usage="applying" />
)
export const CoachingPollRun = (props) => (
    <ToolsPollRun { ...props } usage="coaching" />
)

const ToolsPollRun = ({ usage, session }) => {
    const [run, setRun] = useState({});
    const [runStats, setRunStats] = useState({});
    const [pollStats, setPollStats] = useState({});

    const runId = window.location.href.split('?')[1].split('/')[2];
    const base = window.location.href.split('?')[0];
    const pollLink = `${base}?${usage}/poll_form/${run.id}/${run.uuid}`;

    useEffect(() => {
        service.loadPollRun(session, runId, setRun, console.error);
    }, [runId, session]);
    useEffect(() => {
        if (!run.poll_id || run.num_identities < 3) {
            return;
        }

        service.loadPollRunStats(session, runId, setRunStats, console.error);
        service.loadPollStats(session, run.poll_id, setPollStats, console.error);
    }, [run.num_identities, run.poll_id, runId, session]);

    const header = <div>
        <MenuGeneric active={ usage } />
        <a href={usage === 'applying' ? `?applying/standardpolls`: `?coaching/coachingpolls`}>Zurück zu allen Umfragen</a>
    </div>

    if (run.id && run.num_identities < 3) {
        return (
            <div>
                { header }
                <h2>Noch nicht genug Teilnehmer</h2>
                <p>Bitte lade mehr Kolleg:innen dazu ein, an der Umfrage teilzunehmen.</p>
                <div className="input-group mb-3">
                    <input className="form-control" onClick={ selectAll(`poll_run_${run.id}`) } id={ `poll_run_${run.id}` } type="text" readOnly value={ pollLink } />
                </div>
            </div>
        );
    }

    if (!run.id || !pollStats.poll_items) {
        return (
            <div>
                { header }
                <p>Laden...</p>
            </div>
        )
    }

    const Results = modules[run.poll_id] || DefaultModule;

    return (
        <div>
            { header }
            <div className="mb-5">
                <Results
                    runStats={ runStats }
                    pollStats={ pollStats }
                />
            </div>
        </div>
    )
}
export default ToolsPollRun;