import { translate } from "lib/language"
import Title from "./title"

const Note = (entry) => (
    <div className="su-border mb-5">
        <div className="bg-white p-3 text-center box-shadow">
            <Title { ...entry } />
            <p>{ translate(entry, "content") }</p>
        </div>
    </div>
)
export default Note