import React, { useState } from 'react';

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import * as service from "./api.js";

export function RenewInviteUserModal( props ) {
    const [ errorMessage, setErrorMessage ] = useState("");

    let user = props.user;

    let errorCallback = (msg) => {
        setErrorMessage(msg);
    };

    let message = <div>
            <p>{user.firstname} {user.lastname} wurde erneut eine Einladung an {user.email} geschickt.</p>
            <div className="alert alert-info">Bitte gegebenenfalls auch im Spam-Ordner nachschauen.</div>
        </div>;

    if(errorMessage !== "") {
        message = <div>
                <div className="alert alert-danger">Error: {errorMessage}</div>
            </div>;
    }

    service.inviteUserToAccount(props.session, user.firstname, user.lastname, user.email, props.accountId, null, errorCallback);

    return (<Modal show={true} centered>
        <Modal.Header>
            <Modal.Title>Einladung erneuern</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {message}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.cancelCallback}>
                Fenster schließen
            </Button>
        </Modal.Footer>
    </Modal>);
}