import { translate } from "lib/language";
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Title from "./title";

const Paragraph = (entry) => (
    <div className="su-nugget-text mb-5">
        <Title {...entry} />
        <ReactMarkdown remarkPlugins={[remarkGfm]} className="su-markdown">
            {translate(entry, "content")}
        </ReactMarkdown>
    </div>
);
export default Paragraph;
