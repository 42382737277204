import { translate } from 'lib/language';
import Title from './title';
import './video.scss';

const Video = (entry) => (
  <div>
    {
      //if entry.url is a vimeo link then render vimeo player
      translate(entry, 'url').includes('vimeo') ? (
        <div className="su-nugget-video mb-5">
          <Title {...entry} />
          <p className="mb-3">{translate(entry, 'description')}</p>

          <div className="su-border su-border-top">
            <div className="su-vimeo bg-light-grey">
              <iframe
                src={translate(entry, 'url')}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                title={translate(entry, 'title')}
              ></iframe>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
          </div>
        </div>
      ) : (
        <div className="su-nugget-video mb-5">
          <Title {...entry} />
          <p className="mb-3">{translate(entry, 'description')}</p>

          <div className="su-border su-border-top">
            <div className="su-video bg-light-grey">
              <video
                width="100%"
                height="240"
                controls
                poster={entry.thumbnail && entry.thumbnail.url}
              >
                <source
                  src={translate(entry, 'url')}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      )
    }
  </div>
);
export default Video;
