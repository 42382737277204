import { translate } from "lib/language"
import "./quote.scss"
import Title from "./title"

const Quote = (entry) => (
    <blockquote className="su-quote p-3 mb-5">
        <Title { ...entry } />
        <p>{ translate(entry, "content") }</p>
    </blockquote>
)
export default Quote