import { ResponsivePie } from "@nivo/pie";
import { translate } from "lib/language";
import { fromPairs, map, zip } from "lodash";

const CenteredMetric = (myProps) => (layerProps) => {
    // Tutorial: https://codesandbox.io//kind-morning-z3ygm?file=/src/App.js:152-196
    const { centerX, centerY } = layerProps;
    const score = myProps.score.toString();

    if (score === "") {
        return "";
    }

    let fontSize = "24px";
    if (score.length > 3) {
        fontSize = "18px";
    }
    return (
        <text
            x={centerX}
            y={centerY * 0.9}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
                color: "#FFFFFF",
                fontSize: fontSize,
            }}
        >
            {score}
        </text>
    );
};

const NpsGauge = (chart) => {
    // ['Promotoren', 'Detraktoren', 'Indifferente', 'NPS']
    const data_labels = translate(chart, "data_labels");
    // ['promotors', 'neutrals', 'detractors']
    const data_names = chart.data_names;

    // Input data:
    // [
    //     {
    //         type: "decimal"
    //         unit: ['%', '%', '%', '']
    //         values: [60.123, 30.123, 9.754, 30]
    //     }
    // ]
    const { values: data_values, unit: data_units } = chart.data[0];

    console.assert(
        data_names.length === 4,
        "[Document:npsgauge] Input data_names are not the correct format! Expected 4 entries."
    );
    console.assert(
        data_labels.length === 4,
        "[Document:npsgauge] Input languages.data_labels are not the correct format! Expected 4 entries."
    );
    console.assert(
        data_values.length === 4,
        "[Document:npsgauge] Input data[0].values are not the correct format! Expected 4 entries."
    );

    const labels = fromPairs(zip(data_names, data_labels));
    const values = fromPairs(zip(data_names, data_values));
    const units = fromPairs(zip(data_names, data_units));
    const colors = {
        promotors_percent: "green",
        neutrals_percent: "#f3f3f3",
        detractors_percent: "red",
    };
    const in_order = ["promotors_percent", "neutrals_percent", "detractors_percent"];

    const score = `NPS: ${Math.round(values.nps)}`;
    const data = map(in_order, (name) => ({
        id: labels[name],
        value: values[name] / (units[name] === "%" ? 100 : 1),
        color: colors[name],
    }));

    return (
        <div>
            <h3 className="text-center">{translate(chart, "title")}</h3>
            <div
                className="m-auto"
                style={{
                    width: `${chart.width || 220}px`,
                    height: `${chart.height || 150}px`,
                }}
            >
                <ResponsivePie
                    colors={{ datum: "data.color" }}
                    data={data}
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    startAngle={-90}
                    endAngle={90}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    borderWidth={1}
                    borderColor={{
                        from: "color",
                        modifiers: [["darker", 0.2]],
                    }}
                    enableArcLabels={false}
                    enableArcLinkLabels={false}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                        from: "color",
                        modifiers: [["darker", 2]],
                    }}
                    activeOuterRadiusOffset={0}
                    valueFormat=">.0p"
                    layers={[
                        "arcs",
                        "arcLabels",
                        "arcLinkLabels",
                        "legends",
                        CenteredMetric({ score }),
                    ]}
                />
            </div>
        </div>
    );
};

export default NpsGauge;
