const Input = ({ name, value, currentValue, onChange }) => {
  return (
    <input
      className="mr-3 mt-2 default-w-poll-input focus-visible-orange"
      name={name}
      currentValue={currentValue}
      onChange={(e) => {
        value = e.target.value;
        onChange(name, value);
      }}
    ></input>
  );
};

export default Input;
