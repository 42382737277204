import { useCallback, useEffect, useState } from "react";

import LoginDialog from "./login";
import RegisterDialog from "./register";
import ForgotPasswordDialog from "./forgot_password";
import AccountPicker from "./account_picker";
import SessionContext from 'lib/session';

import * as service from "./api";

const EnsureLoggedIn = ({ children, style }) => {
    const accountId = window.location.pathname.substring(1);
    const [error, setError] = useState(null);
    const [session, setSessionState] = useState({
        accountId,
        apiKey: window.localStorage.getItem("apiKey"),
    })
    const [mode, setMode] = useState("login");

    // Persist the session to localStorage and update the current session
    const setSession = useCallback((data) => {
        setSessionState({
            accountId,
            apiKey: data.apiKey,
            profile: data.profile,
        });
        window.localStorage.setItem("apiKey", data.apiKey);
    }, [accountId]);

    useEffect(() => {
        if (!session.apiKey) {
            return;
        }

        service.me(session.apiKey, (profile) => {
            setSession({
                accountId: session.accountId,
                apiKey: session.apiKey,
                profile,
            })
        }, (message) => {
            if(message.indexOf("ERROR_CODE_UNAUTHORIZED") !== -1) {
                setSession({});
            }
            else {
                setError(message);
            }
        });
    }, [session.apiKey, session.accountId, setSession]);

    if (error) {
        return error;
    }

    // Not logged in
    if (!session.apiKey) {
        // We want to register
        if(mode === "register") {
            return (
                <RegisterDialog toggleMode={setMode}/>
            );
        }

        // We forgot our password
        if(mode === "forgot_password") {
            return (
                <ForgotPasswordDialog toggleMode={setMode}/>
            );
        }

        // We want to log in
        return (
            <LoginDialog toggleMode={setMode} setSession={ setSession } style={ style } />
        );
    }

    // Waiting for profile to load
    if (!session.profile) {
        return "Laden...";
    }

    // Pick our account
    if (!session.accountId) {
        return (
            <AccountPicker
                profile={ session.profile }
                //onChange={ (e) => {
                    //window.location.pathname = `/${e.target.value}`;
                //}}
            />
        )
    }

    // We are logged in, continue rendering children; A session context is provided here
    return (
        <SessionContext.Provider value={ session }>
            { children }
        </SessionContext.Provider>
    );
}
export default EnsureLoggedIn;