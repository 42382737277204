import SuTooltip from "components/Tooltip";
import { userCan } from "lib/permissions";
import { useContext } from "react";
import SessionContext from "lib/session";

import iconEdit from "styles/images/edit.svg";
import iconDelete from "styles/images/delete.svg";
import iconQr from "styles/images/qr-code-icon.svg";
import iconOpenPollRun from "styles/images/link-new-tab.svg";
import iconEval from "styles/images/eval.svg";

const iconSvgs = {
    edit: iconEdit,
    delete: iconDelete,
    qr: iconQr,
    open: iconOpenPollRun,
    eval: iconEval,
};

const PollRunAction = ({ title, icon, callback, permission }) => {
    const session = useContext(SessionContext);

    if (permission && !userCan(session, permission)) {
        return;
    }

    return (
        <SuTooltip placement="bottom" tooltipId={icon} text={title}>
            <span
                className="mr-2 su-table-action-icon action-icon-big"
                role="button"
                onClick={callback}
            >
                <img src={iconSvgs[icon]} alt={title} />
            </span>
        </SuTooltip>
    );
};
export default PollRunAction;
