import { translate } from "lib/language"

const File = (entry) => (
    <div className="su-border mb-5">
        <div className="su-nugget-file card">
            <div className="card-header pb-0">{ translate(entry, "title") }</div>
            <div className="card-body">
                <p>{ translate(entry, "description") }</p>
                <a download className="btn btn-primary" href={ translate(entry, "url") }>Download</a>
            </div>
        </div>
    </div>
)
export default File