import { getBase, getHelper, logHelper } from "lib/api";

const API_URL = getBase("knowledge");

export const loadJourneys = (session, onSuccess = ()=>{}, onError = ()=>{} ) => {
    const url = `${API_URL}/journey/account/${session.accountId}/user/${session.profile.id}`;

    getHelper(session, url, onSuccess, onError)
};

export const logJourneyOpened = async (session, journeyId, onSuccess, onError = console.error) => {
    const url = `${API_URL}/journey/open/${journeyId}`;

    await logHelper(session, url, {account_id: session.accountId}, onSuccess, onError);
};