import { useState, useEffect } from 'react';

import * as service from '../api';
const { translate } = require('lib/language');

const DropdownOption = ({ label, value, name }) => {
  return (
    <option value={value} name={name}>
      {label}
    </option>
  );
};

const Dropdown = (definition) => {
  const [userList, setUserList] = useState([]);
  const { range, type, name, currentValue, onChange } = definition;
  const options = [];
  const labels = translate(definition, 'range');
  const accountId = window.location.pathname.substring(1); //get account id from url

  useEffect(() => {
    if (type === 'userlist_by_role') {
      const role_ids = range.map((id) => parseInt(id)); // role_ids defined in range of pollItem
      service.loadUsersByRole(accountId, role_ids, setUserList);
    }
  }, [range, accountId, type]);

  //wait for userList to load
  if (type === 'userlist_by_role' && userList.length === 0) {
    return (
      <div>
        <select className="select-center">
          <option
            label="Rolle Coach nicht vergeben"
            key="no user"
            value="no user"
            name={name}
          ></option>
        </select>
        <span style={{color: "red"}} className='font-size-1'>*Bitte vor dem Ausfüllen dieser Umfrage im Team-Editor einen Coach festlegen.</span>
      </div>
    );
  }

  if (type === 'textlist' || type === 'numericlist') {
    range.forEach((option, optIndex) => {
      options.push(
        <DropdownOption
          label={labels[optIndex]}
          key={optIndex}
          value={option}
          name={name}
        />
      );
    });
  } else if (type === 'userlist_by_role') {
    if (!currentValue && userList.length > 0) {
      onChange(name, userList[0].id);
    }
    userList.forEach((option, optIndex) => {
      options.push(
        <DropdownOption
          label={`${option.firstname} ${option.lastname}`}
          key={optIndex}
          value={option.id}
          name={name}
        />
      );
    });
  }

  return (
    <div>
      <select
        className={
          'poll-dropdown select-center ' +
          (currentValue ? 'has-value' : '')
        }
        key={name}
        onChange={(e) => {
          if (e.target.value !== currentValue){onChange(name, parseInt(e.target.value))}
        }}
        value={currentValue }
      >
        {options}
      </select>
    </div>
  );
};
export default Dropdown;
