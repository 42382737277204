import ActionsMenu from "components/ActionsMenu";
import KpiStatus from "components/KpiStatus";
import { useContext } from "react";
import * as service from "./api";
import * as toasts from "lib/toasts";
import SessionContext from "lib/session";
import { userCan } from "lib/permissions";

const GoalKpi = ({ id, goalId, name, focus, onChange }) => {
    const session = useContext(SessionContext);

    return (
        <div className="col-4" key={id}>
            <div className="card p-3">
                {userCan(session, "TOOLS_GOAL_EDIT") && (
                    <div className="position-absolute mt-1" style={{ right: 0 }}>
                        <ActionsMenu
                            width="250px"
                            className="mr-3 position-relative d-inline"
                            actions={[
                                {
                                    icon: "none",
                                    text:
                                        focus === "goal"
                                            ? "Fokus auf aktuellen Wert"
                                            : "Fokus auf Zielwert",
                                    onClick: () => {
                                        service.updateGoalKpi(
                                            session,
                                            goalId,
                                            id,
                                            { focus: focus === "goal" ? "value" : "goal" },
                                            onChange,
                                            toasts.makeErrorToast
                                        );
                                    },
                                },
                                {
                                    icon: "delete",
                                    text: "Entfernen",
                                    onClick: () =>
                                        service.detachKpi(
                                            session,
                                            goalId,
                                            id,
                                            onChange,
                                            toasts.makeErrorToast
                                        ),
                                },
                            ]}
                        />
                    </div>
                )}
                <h2 className="mb-4">{name}</h2>
                <KpiStatus id={id} focus={focus} />
            </div>
        </div>
    );
};
export default GoalKpi;
