import { map } from "lodash";

import Video from "./content/video";
import Image from "./content/image";
import File from "./content/file";
import Paragraph from "./content/paragraph";
import Note from "./content/note";
import Quote from "./content/quote";
import Link from "./content/link";
import Block from "./content/block";
import NpsGauge from "./content/npsgauge";
import Gauge from "./content/gauge";
import Spider from "./content/spider";
import NpsPeople from "./content/npspeople";

const modules = {
    video: Video,
    image: Image,
    file: File,
    paragraph: Paragraph,
    standard: Paragraph,
    note: Note,
    quote: Quote,
    link: Link,
    block: Block,
    npspeople: NpsPeople,
    npsgauge: NpsGauge,
    gauge: Gauge,
    spider: Spider,
};
const DefaultContent = (props) => (
    <pre>
        {props.type || props.source} --- {JSON.stringify(props)}
    </pre>
);
export const renderContent = (entry, index) => {
    const Content = modules[entry.type || entry.source || "block"] || DefaultContent;

    return <Content {...entry} key={index} depth={entry.depth || 1} />;
};

const Document = ({ content }) => map(content, renderContent);
export default Document;
