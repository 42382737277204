import SuTooltip from "components/Tooltip"

const NavLinkImage = ({ img, title, link }) => (
    <SuTooltip key={link} placement={"bottom"} tooltipId={link} text={title}>
    <img
        style={{ opacity: link ? 1 : 0.25 }}
        src={ img }
        width="40" height="56"
        alt={ title }
        data-toggle="tooltip"
        data-placement="top"  />
    </SuTooltip>
)
const NavLink = ({ img, title, link, children }) => {
    const content = children || <NavLinkImage img={ img } title={ title } link={ link } />

    return link ? (
        <a href={ link }>{ content }</a>
    ) : (
        <span>{ content }</span>
    )
}
export default NavLink