import { translate } from "lib/language"

const Title = (entry) => {
    const content = entry.children || translate(entry, "title")

    return entry.depth === 1 ? (
        <h2 className={ entry.className }>{ content }</h2>
    ) : (
        <h3 className={ entry.className }>{ content }</h3>
    )
}
export default Title