import React, { useState } from 'react';

const StarRating = ({ name, range, onChange, currentValue }) => {
  const [hover, setHover] = useState(0);

  const stars = [];
  for (let i = 1; i <= parseInt(range[1]); i++) {
    stars.push(
      <span
        className={
          i <= (hover || currentValue)
            ? 'star-on p-2'
            : 'star-off p-2'
        }
        style={{
          fontSize: '1.6rem',
          cursor: 'pointer',
          border: 0,
          backgroundColor: 'transparent',
        }}
        onClick={(e) => {
          e.preventDefault();
          onChange(name, i);
        }}
        key={i}
        value={i}
        name={name}
        onMouseEnter={() => setHover(i)}
        onMouseLeave={() => setHover(currentValue)}
      >
        &#9733;
      </span>
    );
  }
  return <div className="rating">{stars}</div>;
};
export default StarRating;
