import { useEffect, useState, useRef } from "react";
import { MenuGeneric } from "components/Menus";
import HeaderProfile from "components/HeaderProfile";
import { translate } from "lib/language";
import { find } from "lodash";

import * as service from "./api";
import { userCan } from "lib/permissions";
import PollRun from "./pollrun";

export const ApplyingPolls = (props) => <ToolsPolls {...props} usage="applying" />;
export const CoachingPolls = (props) => <ToolsPolls {...props} usage="coaching" />;

const ToolsPolls = ({ usage, session }) => {
    const [pollRuns, setPollRuns] = useState([]);
    const [polls, setPolls] = useState([]);
    const [team, setTeam] = useState([]);
    const newPollRun = useRef(false);

    useEffect(() => {
        service.loadPolls(session, usage, setPolls, console.error);
        service.loadPollRuns(session, usage, setPollRuns, console.error);
        service.loadTeam(session, setTeam, console.error);
    }, [session, usage]);

    const onPollRunChange = () => service.loadPollRuns(session, usage, setPollRuns, console.error);
    if (team.length === 0) {
        return "Loading...";
    }

    //if new pollRun was created, show Edit Modal of this pollRun
    /*
    if (newPollRun.current === true) {
        //sort pollRuns by created_at descending for opening EditModal on create
        const sortedRunsCreatedAt =
        [...pollRuns].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) || pollRuns;
        newPollRun.current = false;
        const createdPollRun = sortedRunsCreatedAt[0];
        const createdPollName = find(polls, { id: createdPollRun.poll_id }).name;
        makeEditPollRunModal(createdPollRun, createdPollName, session);
    }
    */

    return (
        <div>
            <HeaderProfile session={session} />
            <MenuGeneric active={usage} />
            <a href={`${session.accountId}?${usage}`} className="color-orange">
                Zurück zu den Tools
            </a>

            <div id="su-tool-polls" className="mb-5">
                {userCan(session, "TOOLS_POLLRUN_ADD") && (
                    <div>
                        <h3 className="mb-4 mt-3">Neue Umfrage anlegen</h3>
                        <div className="row">
                            {polls.map((poll) => (
                                <div className="col-12 col-lg-4 mb-5" key={poll.id}>
                                    <div className="card su-journey">
                                        <div className="card-header">
                                            <div className="su-header-meta">Umfrage</div>
                                            {translate(poll, "title")}
                                        </div>
                                        <div className="card-body pt-0">
                                            <a
                                                href="/"
                                                className="btn btn-primary btn-small w-100"
                                                onClick={service.createPollRun(
                                                    session,
                                                    usage,
                                                    poll.id,
                                                    poll.name,
                                                    setPollRuns,
                                                    newPollRun
                                                )}
                                            >
                                                + Neue Umfrage anlegen
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <h3 className="mb-4">Bisherige Umfragen</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Umfrage</th>
                            <th>Erstellt von</th>
                            <th className="d-none d-lg-table-cell">Beginn</th>
                            <th>Ende</th>
                            <th title="Teilnehmer">TN</th>
                            <th>Teilgenommen</th>
                            <th>Link</th>
                            <th>Aktionen</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pollRuns.map((run) => (
                            <PollRun
                                run={run}
                                poll={find(polls, { id: run.poll_id })}
                                team={team}
                                key={run.id}
                                onChange={onPollRunChange}
                                usage={usage}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
export default ToolsPolls;
