import { getBase, getHelper, postHelper, deleteHelper, getFormDataHeaders } from "lib/api";
import * as toasts from "lib/toasts";

const API_URL = getBase("toolset");

export const loadKpis = (session, onSuccess = () => {}, onError = () => {}) => {
    const url = `${API_URL}/kpi/account/${session.accountId}`;

    getHelper(session, url, onSuccess, onError);
};
export const loadKpi = (session, kpi, onSuccess = () => {}, onError = () => {}) => {
    const url = `${API_URL}/kpi/${kpi}`;

    getHelper(session, url, onSuccess, onError);
};

export const createKpi = (session, kpi, onSuccess, onError = console.error) => {
    const url = `${API_URL}/kpi`;

    postHelper(session, url, kpi, onSuccess, onError);
};
export const updateKpi = (session, kpi_id, kpi, onSuccess, onError = console.error) => {
    const url = `${API_URL}/kpi/${kpi_id}`;

    console.info("Updating KPI", kpi_id, "to", kpi);
    postHelper(session, url, kpi, onSuccess, onError);
};
export const updateKpiGoal = (session, goal_id, goal, onSuccess, onError = console.error) => {
    const url = `${API_URL}/kpi_goal/${goal_id}`;

    console.info("Updating KPI goal", goal_id, "to", goal);
    postHelper(session, url, goal, onSuccess, onError);
};

export const createKpiGoal = (session, goal, onSuccess, onError = console.error) => {
    const url = `${API_URL}/kpi_goal`;

    postHelper(session, url, goal, onSuccess, onError);
};

export const deleteKpi = (session, kpi_id, onSuccess, onError = console.error) => {
    const url = `${API_URL}/kpi/${kpi_id}`;

    deleteHelper(session, url, onSuccess, onError);
};

export const deleteKpiGoal = (session, goal_id, onSuccess, onError = console.error) => {
    const url = `${API_URL}/kpi_goal/${goal_id}`;

    deleteHelper(session, url, onSuccess, onError);
};

export const importKpisFromCsv = (formData, onSuccess = () => {}, onError = () => {}) => {
    toasts.makeLoadingToast("CSV importieren...", "toastImportCsv");
    fetch(`${API_URL}/kpi/import/kpi_values`, {
        method: "POST",
        headers: getFormDataHeaders(),
        body: formData,
    })
        .then((response) => response.json())
        .then((data) => {
            if (data.message) {
                onError(toasts.makeErrorToast(JSON.stringify(data.message), "toastImportCsv"));
            } else {
                toasts.makeSuccessToast(
                    `${data.num_imported_kpis} KPIs wurden importiert`,
                    "toastImportCsv"
                );
                onSuccess();
            }
        });
};
