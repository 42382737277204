import { useState } from "react";
import { formatDate } from "lib/language";
import { copyToClipboard } from "lib/clipboard";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import ApiKeyModal from "./apikey_modal";
import SuTooltip from "components/Tooltip";
import { userCan } from "lib/permissions";
import { map } from "lodash";
import * as service from "./api";
import * as toasts from "lib/toasts";

// import iconEdit from "styles/images/edit.svg";
import iconDelete from "styles/images/delete.svg";
import copy from "styles/images/copy.svg";

const ApiKey = ({ apikey, session, onDelete }) => {
    const [activeForm, setActiveForm] = useState(null);
    const { creator, created_at, name, id } = apikey;

    const onClose = () => {
        setActiveForm(null);
    };

    if (apikey === null) {
        return <tr>Laden...</tr>;
    }

    const renderForm = () => {
        switch (activeForm) {
            case "apikey-delete":
                return (
                    <DeleteConfirmModal
                        title="API-Key löschen"
                        altText={`Möchtest du den API-Key ${name} wirklich löschen? Alle Apps, die diesen API-Key verwenden, können dann keine Daten
                                mehr mit der scale up App austauschen.`}
                        onClose={onClose}
                        onDelete={() =>
                            service.deleteApiKey(session, id, onDelete, toasts.makeErrorToast)
                        }
                    />
                );

            case "apikey-edit":
                return (
                    <ApiKeyModal onClose={onClose} session={session} apikeyId={id} mode="edit" />
                );
            default:
                return;
        }
    };

    const renderActions = (name, session) => {
        const actions = [];
        //control display of actions

        // if (userCan(session, "ACCOUNT_APIKEY_EDIT")) {
        //     actions.push({
        //         icon: iconEdit,
        //         title: "ApiKey bearbeiten",
        //         callback: () => setActiveForm("apikey-edit")
        //     });
        // }
        if (userCan(session, "ACCOUNT_APIKEY_DELETE")) {
            actions.push({
                icon: iconDelete,
                title: "API-Key löschen",
                callback: () => setActiveForm("apikey-delete"),
            });
        }

        const icons = map(actions, (action) => (
            <SuTooltip
                key={action.title}
                placement={"bottom"}
                tooltipId={action.icon}
                text={action.title}
            >
                <span
                    key={action.title}
                    className="mr-2 su-table-action-icon action-icon-big"
                    onClick={(e) => {
                        e.preventDefault();
                        action.callback();
                    }}
                >
                    <img src={action.icon} alt={action.title} />
                </span>
            </SuTooltip>
        ));

        return <td>{icons}</td>;
    };

    return (
        <tr>
            {renderForm()}
            <td className="align-middle">{name}</td>
            <td className="align-middle d-none d-lg-table-cell">{formatDate(created_at)}</td>
            <td className="align-middle">
                {!creator ? "unbekannt" : `${creator.firstname} ${creator.lastname}`}
            </td>
            <td className="align-middle">
                <span
                    onClick={copyToClipboard(apikey.apikey, "API-Key")}
                    className="d-iblock pr-2 su-table-action-icon cursor-pointer"
                >
                    <img className="icon-left" src={copy} width="14" height="14" alt="Kopieren" />
                    {apikey.apikey}
                </span>
            </td>
            {renderActions(name, session)}
        </tr>
    );
};
export default ApiKey;
