import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import React, { useState } from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
const { translate } = require('lib/language');

const Slider = (definition) => {
  const { range, type, name, currentValue, onChange } = definition;
  const [value, setValue] = useState(currentValue || 0); // if window changes slider gets rendered with current value
  const labels = translate(definition, 'range');
  //steps are 1 per default
  let min = 0;
  let max = 10;

  if ((type === "nps" || type === "integer")) {
    min = parseInt(range[0]);
    max = parseInt(range[1]);
  }

  return (
    <div className="su-slider mt-n1">
      <RangeSlider
        value={value}
        name={name}
        tooltip="auto"
        variant="warning"
        tooltipLabel={(currentValue) => `${currentValue}`}
        onChange={(e) => {
          e.preventDefault();
          setValue(parseInt(e.target.value));
          onChange(name, parseInt(e.target.value));
        }}
        min={min || 0}
        max={max || 10}
        currentValue={currentValue}
      />
      {(type === "nps" || type === "integer") && (
        <div className="d-flex justify-content-between font-size-1 color-light-grey mt-2">
          <span>{labels[0]}</span>
          <span>{labels[1]}</span>
        </div>
      )}
    </div>
  );
};
export default Slider;
