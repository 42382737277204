import { map } from "lodash";

/*
Example usage:

    import IconScopeAccount from "styles/images/scope_account.svg"
    import IconScopeUser from "styles/images/scope_user.svg"

    const [scope, setScope] = useState("account")

    <InputWithIcons
        className="mb-4"
        label="Bezug"
        value={ scope }
        onChange={ setScope }
        options={{
            "account": {
                text: "Team",
                src: IconScopeAccount,
            },
            "user": {
                text: "Person",
                src: IconScopeUser,
            },
        }}
    />

*/
const InputWithIcons = ({ options, label, value, onChange, className }) => (
    <div className={ className }>
        <p className="font-su">
            { label }
            <span className="color-orange"> { (options[value] || {text: value}).text }</span>
        </p>

        { map(options, (option, key) => (
            <img
                width="40"
                height="40"
                role="button"
                alt={ option.text }
                className={ `mr-3 ${key !== value ? "filter-grayscale" : "filter-su"}` }
                onClick={ () => onChange(key) }
                src={ option.icon }
                key={ key }
            />
        )) }
    </div>
)
export default InputWithIcons