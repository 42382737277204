import { getHeaders, getBase } from 'lib/api';
import * as toasts from 'lib/toasts';

const API_URL = getBase('admin');

export const loadAccountData = (
  session,
  onSuccess = () => {},
  onError = () => {}
) => {
  const url = `${API_URL}/account/${session.accountId}`;

  fetch(url, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.message) {
        onError(data.message, 'GET', url);
      } else {
        onSuccess(data);
      }
    });
};

export const updateAccountData = (
  session,
  accountData,
  setAccountData,
  setMessage,
  onSuccess = () => {},
  onError = () => {}
) => {
  toasts.makeLoadingToast('Daten speichern...', 'toastUpdate');

  fetch(`${API_URL}/account/${session.accountId}`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      name: accountData.name,
      domains: accountData.domains,
      num_empolyees: accountData.num_empolyees,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.message) {
        onError(
          toasts.makeErrorToast(
            JSON.stringify(data.message),
            'toastUpdate'
          )
        );
      } else {
        onSuccess(
          toasts.makeSuccessToast(
            'Daten erfolgreich gespeichert',
            'toastUpdate'
          )
        );
        loadAccountData(
          session,
          setAccountData,
          setMessage,
          onSuccess,
          onError
        ); //Profil Daten neu laden
      }
    });
};

export const deleteAccount = (
  session,
  onSuccess = () => {},
  onError = () => {}
) => {
  fetch(`${API_URL}/account/${session.accountId}`, {
    method: 'DELETE',
    headers: getHeaders(),
    body: JSON.stringify({
      user_id: session.accountId,
    }),
  })
    //logout if 0 accounts left and not sup coach, else go to account picker
    .then(() => {
      if (
        session.profile.accounts.length === 1 &&
        session.profile.is_sup_coach === false
      ) {
        window.location.pathname = '';
        window.history.pushState(
          null,
          '',
          window.location.href.split('?')[0]
        );
        window.localStorage.removeItem('apiKey');
      } else {
        window.location.pathname = '';
        window.history.pushState(
          null,
          '',
          window.location.href.split('?')[0]
        );
        window.localStorage.removeItem('accountID');
      }
    });
};
