import { getHeaders, getBase } from "lib/api";
import * as toasts from "lib/toasts";

const API_URL = getBase("admin");
const AUTH_URL = getBase("auth");

export const loadTeam = ( session, onSuccess = ()=>{}, onError = ()=>{} ) => {
    fetch(`${API_URL}/account/${session.accountId}/user`,
        {
            method: 'GET',
            headers: getHeaders()
        })
        .then(response => response.json())
        .then(data => {
            if(data.message) {
                onError(data.message);
            } else {
                onSuccess(data);
            }
        });
};

export const loadRoles = (session, onSuccess = ()=>{}, onError = ()=>{} ) => {
    fetch(API_URL+'/role',
        {
            method: 'GET',
            headers: getHeaders()
        })
        .then(response => response.json())
        .then(data => {
            if(data.message) {
                onError(data.message);
            } else {
                onSuccess(data);
            }
        });
};

export const updateRolesOfUser = ( session, userId, roleIds, onSuccess = ()=>{}, onError = ()=>{} ) => {
    toasts.makeLoadingToast("Berechtigungen werden aktualisiert", "toastUpdateRoles");
    fetch(API_URL+'/account/'+session.accountId+'/user/'+userId+'/roles',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({'role_ids': roleIds})
        })
        .then(response => response.json())
        .then(data => {
            if(data.message) {
                onError(data.message)
                toasts.makeErrorToast(JSON.stringify(data.message), "toastUpdateRoles");
            } else {
                toasts.makeSuccessToast("Berechtigungen wurden aktualisiert", "toastUpdateRoles");
                loadTeam(session, onSuccess, onError);
            }
        });
};

export const changeAccountOwner = ( session, userId, onSuccess = ()=>{}, onError = ()=>{} ) => {
    toasts.makeLoadingToast("Owner wird geändert...", "toastChangeOwner");
    fetch(`${API_URL}/account/${session.accountId}/owner/${userId}`,
        {
            method: 'POST',
            headers: getHeaders()
        })
        .then(response => response.json())
        .then(data => {
            if(data.message) {
                onError(data.message)
                toasts.makeErrorToast((JSON.stringify(data.message)), "toastChangeOwner");
            } else {
                loadTeam(session, onSuccess, onError);
                toasts.makeSuccessToast("Owner wurde geändert", "toastChangeOwner");
            }
        });
};

export const removeUserFromAccount = ( session, userId, onSuccess = ()=>{}, onError = ()=>{} ) => {
    toasts.makeLoadingToast("Benutzer aus dem Team entfernen...", "toastRemoveUser");
    fetch(API_URL+'/account/'+session.accountId+'/user/'+userId,
        {
            method: 'DELETE',
            headers: getHeaders()
        })
        .then(response => response.json())
        .then(data => {
            if(data && data.message) {
                onError(data.message)
                toasts.makeErrorToast((JSON.stringify(data.message)), "toastRemoveUser");
            } else {
                onSuccess(data)
                toasts.makeSuccessToast("Benutzer aus dem Team entfernt", "toastRemoveUser");
            }
        });
};

export const inviteUserToAccount = ( session, firstname, lastname, email, onSuccess = ()=>{}, onError = ()=>{} ) => {
    const base = window.location.href.split('?')[0];
    toasts.makeLoadingToast('Einladung senden...', "inviteToast");
    fetch(AUTH_URL+'/auth/magic/invite',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify( {
                firstname: firstname,
                lastname: lastname,
                email: email,
                account_id: session.accountId,
                redirect_url: `${base}?auth/link`
            } )
        })
        .then(response => response.json())
        .then(data => {
            if(data.message) {
                onError(data.message);
                toasts.makeErrorToast((JSON.stringify(data.message)), "inviteToast")
            } else {
                onSuccess(data);
                toasts.makeSuccessToast("Einladung gesendet", "inviteToast")
            }
        });
};