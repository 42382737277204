import { translate } from "lib/language"

import checkIcon from "styles/images/check.svg"
import eyeIcon from "styles/images/eye.svg"
import undoneIcon from "styles/images/undone.svg"

const renderStatus = (status) => {
    switch(status) {
        case "checked": return (
            <span className="su-icon su-icon-orange"><img src={ checkIcon } alt="Checked"/></span>
        )

        case "visited": return (
            <span className="su-icon su-icon-blue"><img src={ eyeIcon } alt="Visited"/></span>
        )

        default: return (
            <span className="su-icon su-icon-grey"><img src={ undoneIcon } alt="New"/></span>
        )
    }
}
const Nugget = ( nugget ) => (
    <div className="col-12 col-lg-3 mb-5 d-flex pl-2 pr-2" style={{ width: '18rem' }}>
        <a className="card su-nugget" href={ `?learning/nugget/${nugget.journeyId}/${nugget.id}` }>
            <img className="card-img-top" src={ nugget.thumbnail && nugget.thumbnail.url } alt="" />
            <div className="su-card-hover">
                <button className="btn btn-primary">Nugget öffnen</button>
            </div>
            <div className="card-header row align-items-center pl-3 pr-3 ml-0 mr-0">
                <div className="col-12 d-flex pl-0 pr-0">
                <div className="su-nugget-status su-nugget-status-mini align-self-center mr-2">
                    { renderStatus(nugget.status) }
                </div>
                <div className="w-100 align-self-center">
                    { translate(nugget, "title") }
                </div>
                </div>
            </div>
        </a>
    </div>
)
export default Nugget;