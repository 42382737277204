import { translate } from "lib/language"
import "./link.scss"
import Title from "./title";

import downloadIcon from "styles/images/download-orange.svg";

const Link = (entry) => (
    <div className="su-nugget-link box-shadow p-3 mb-5">
        <div className="d-flex align-items-top">
            <div className="flex-grow-1">
                <Title className="d-flex align-items-center">
                    <img src={ downloadIcon } className="mr-2" width="30" height="30" title="Download" alt="Download" />
                    <a href={ entry.url }>{ translate(entry, "title") }</a>
                </Title>
                <p>{ translate(entry, "description") }</p>
            </div>
            { entry.thumbnail && (
                <img src={ entry.thumbnail.url } style={{ maxWidth: "100px", maxHeight: "100px" }} alt={ translate(entry, "title") } />
            ) }
        </div>
    </div>
)
export default Link