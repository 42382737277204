import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as toasts from "lib/toasts";

import * as service from "./api";
import { useEffect, useState } from "react";
import { map } from "lodash";

const KpiAttachModal = ({ onClose, session, goalId }) => {
    const [kpis, setKpis] = useState(false);

    useEffect(() => {
        service.loadAvailableKpis(session, goalId, setKpis);
    }, [session, goalId]);

    const onAttach = (kpiId) => {
        service.attachKpi(session, goalId, kpiId, onClose, toasts.makeErrorToast);
    };

    return (
        <Modal show centered>
            <Modal.Header>
                <Modal.Title>KPI auswählen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {kpis === false && "Laden..."}
                {kpis.length === 0 && (
                    <span>
                        Es stehen keine weiteren KPIs zu verfügung. Weitere KPIs kannst du{" "}
                        <a href="?applying/kpis">hier</a> erstellen
                    </span>
                )}
                <ul className="su-navigation-list">
                    {map(kpis, (kpi) => (
                        <li
                            key={kpi.id}
                            className="card p-3 mb-3"
                            role="button"
                            onClick={(e) => onAttach(kpi.id)}
                        >
                            {kpi.title} ({kpi.name})
                        </li>
                    ))}
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Abbrechen
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default KpiAttachModal;
