// import * as service from "./api";
// import { useEffect, useState } from 'react';
import { MenuApplying } from "components/Menus";
import HeaderProfile from "components/HeaderProfile";
import SessionContext from 'lib/session';

import growthCanvas from "styles/images/growthCanvas.png";


const ToolsGrowthCanvas = ({ session }) => {
    console.log(session)
    /*
    const [tools, setTools] = useState([]);
    
    useEffect(() => {
        service.loadTools(props.session, setTools, console.error);
    }, [props.session]);
    */

    return (
        <div>
            <SessionContext.Consumer>
                {(session) => <HeaderProfile session={session} />}
            </SessionContext.Consumer>
            <MenuApplying />
            <a href={ `${session.accountId}?applying` } className="color-orange">Zurück zu den Tools</a>
            <div className="card-body">
                <h1>Growth Canvas Tool</h1>
                <p>Coming soon...</p>
                <img src={growthCanvas} alt="growth canvas"></img>
                
                {/*{ JSON.stringify(tools) }*/}
            </div>
        </div>
    )
}
export default ToolsGrowthCanvas;