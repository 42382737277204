import { useCallback, useEffect, useState } from "react";
import Submit from "./submit";
import logo from "styles/images/scaleup-logo.svg";
import EnsureLoggedIn from "pages/EnsureLoggedin";
import { Toaster } from "react-hot-toast";

import * as service from "./api";
import PollGroup from "./poll_group";
import { map } from "lodash";
import { formatDate } from "lib/language";
import { translate } from "lib/language";

const LoginContainer = ({ children }) => <EnsureLoggedIn>{children}</EnsureLoggedIn>;
const DefaultContainer = ({ children }) => <div>{children}</div>;

const ToolsPollForm = () => {
    const [poll, setPoll] = useState({});
    const [run, setRun] = useState({});
    const [identity, setIdentity] = useState({});
    const [answers, setAnswers] = useState({});
    const [submitState, setSubmitState] = useState("email");

    const path = window.location.href.split("?")[1].split("/");
    const pollRunId = path[2];
    const pollChecksum = path[3];
    const requiredAnswers = {};

    useEffect(() => {
        service.loadPoll(pollRunId, setPoll, console.error);
        service.loadPollRun(pollRunId, setRun, console.error);
    }, [pollRunId]);

    const onChange = useCallback(
        (key, value) => {
            setAnswers({
                ...answers,
                [key]: value,
            });
        },
        [answers]
    );

    if (!poll.id || !run.id) {
        return "Laden...";
    }

    // Enable login prompt
    const Container = poll.authentification === "login" ? LoginContainer : DefaultContainer;

    //check poll run begin date and end date vs now
    const now = new Date();
    const startDate = new Date(run.begin_at);
    const endDate = new Date(run.end_at);

    if (startDate > now) {
        return (
            <div className="pb-5 mx-auto container" style={{ maxWidth: "900px" }}>
                <div className="text-center mt-3 mb-5">
                    <img src={logo} width="191" alt="scale up" style={{ marginLeft: "45px" }} />
                </div>
                <div className="su-border su-with-border-headline">
                    <div className="card">
                        <div className="card-body text-center">
                            <h3>Die Umfrage hat noch nicht begonnen.</h3>
                            <p>Sie beginnt am {formatDate(startDate)}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (endDate < now) {
        return (
            <div className="pb-5 mx-auto container" style={{ maxWidth: "900px" }}>
                <div className="text-center mt-3 mb-5">
                    <img src={logo} width="191" alt="scale up" style={{ marginLeft: "45px" }} />
                </div>
                <div className="su-border su-with-border-headline">
                    <div className="card">
                        <div className="card-body text-center">
                            <h3>Die Umfrage ist bereits beendet.</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // if poll has required items add them to requiredAnswers Object
    poll.poll_groups.forEach((group) => {
        group.poll_items.forEach((item) => {
            if (item.required) {
                requiredAnswers[item.name] = item;
            }
        });
    });

    return (
        <Container>
            <Toaster />
            <div className="pb-5 mx-auto container" style={{ maxWidth: "900px" }}>
                <div className="text-center mt-3 mb-3 pb-4">
                    <img src={logo} width="191" alt="scale up" style={{ marginLeft: "34px" }} />
                    <h1 className="mt-3 ml-adjust-pseudo ">{run.title}</h1>
                </div>
                {map(poll.poll_groups, (group, index) => (
                    <PollGroup
                        {...group}
                        title={poll.poll_groups.length > 1 ? translate(group, "title") : ""}
                        key={index}
                        level={1}
                        answers={answers}
                        onChange={onChange}
                    />
                ))}
                <hr className="mt-5" />
                <span className="d-block text-muted text-center mb-5">
                    Mit Sternchen * markierte Fragen sind Pflichtfragen. Pflichtfragen müssen
                    beantwortet werden, bevor die Umfrage abgegeben werden kann.
                </span>
                <h2 className="text-center">Daten speichern</h2>
                <Submit
                    onSubmit={service.submitPoll(
                        pollChecksum,
                        poll,
                        pollRunId,
                        identity,
                        answers,
                        setSubmitState,
                        poll.authentification
                    )}
                    setSubmitState={setSubmitState}
                    submitState={submitState}
                    setIdentity={setIdentity}
                    pollChecksum={pollChecksum}
                    mode={poll.authentification}
                    allAnswers={answers}
                    requiredAnswers={requiredAnswers}
                    teamName={run.account.name}
                />
            </div>
        </Container>
    );
};
export default ToolsPollForm;
