import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import * as toasts from "lib/toasts";
import * as service from "./api";
import { useState } from "react";

const ApiKeyModal = ({ onClose, session, mode }) => {
    const [name, setName] = useState("");

    const onCreate = () => {
        const payload = {
            account_id: session.accountId,
            name: name,
            created_by: session.profile.id,
            created_at: new Date().toISOString().slice(0, 10),
        };

        if (mode === "edit") {
            // service.updateApiKey(session, payload, onClose, toasts.makeErrorToast);
            console.log("edit api key not implemented yet");
        } else {
            // toasts.makeLoadingToast("API-Key wird generiert...", "toastCreateApiKey");
            service.createApiKey(session, payload, onClose, toasts.makeErrorToast);
        }
    };

    return (
        <Modal show centered>
            <Modal.Header>
                <Modal.Title>Neuer API-Key</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group su-form">
                    <input
                        className=" form-control mb-4"
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        placeholder="Namen für deinen API-Key vergeben"
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                <Button variant="secondary" onClick={onClose}>
                    Abbrechen
                </Button>
                <Button variant="primary" onClick={onCreate}>
                    Fortfahren
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default ApiKeyModal;
