import { find, includes } from "lodash"

export const userCan = (session, permission) => {
    const { accountId, profile } = session
    const { rights } = profile

    const accountRights = find(rights, { account_id: parseInt(accountId) }) || {}
    const { actions } = accountRights
    const allowed = includes(actions, permission)
    // console.info(permission, allowed, actions)

    return allowed
}