import { getBase, getHelper } from "lib/api";
import { flatten, map, sortBy } from "lodash";

const API_URL = getBase("knowledge");

export const loadJourney = (session, journeyId, onSuccess = ()=>{}, onError = ()=>{} ) => {
    const url = `${API_URL}/journey/${journeyId}/user/${session.profile.id}`;

    getHelper(session, url, (journey) => {
        // Get all content and mark it
        const nuggets = map(journey.nuggets, (nugget) => ({ ...nugget, type: 'nugget' }));
        const paths = map(journey.paths, (path) => ({ ...path, type: 'path' }));

        // Sort it by position, which is saved in `pos`
        const content = sortBy(flatten([nuggets, paths]), "pos");

        // Sort content into rows. Example:
        // [
        //    [nugget, nugget, nugget],
        //    [path],
        //    [path],
        //    [nugget],
        // ]
        const rows = [];
        let lastType = "none";
        content.forEach((content) => {
            // Switched type -> Start a new row
            // Paths -> Always start a new row
            if (content.type !== lastType || content.type === "path") {
                rows.push([]);
            }

            rows.at(-1).push(content);
            lastType = content.type
        });

        // Return data
        onSuccess({
            ...journey,
            rows,
            content,
        });
    }, onError);
};