import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as toasts from "lib/toasts";

import * as service from "./api";
import { useState } from "react";
import InputUserSearch from "components/InputUserSearch";

const GoalModal = ({ onClose, session, defaults, goalId, mode }) => {
    const [title, setTitle] = useState(defaults.title || "");
    const [description, setDescription] = useState(defaults.description || "");
    const [start_at, setStartAt] = useState(defaults.start_at || "");
    const [end_at, setEndAt] = useState(defaults.end_at || "");
    const [active, setActive] = useState(defaults.active === undefined ? true : defaults.active);
    const [user_id, setUserId] = useState(defaults.user_id || "");
    console.info(active, defaults.active);

    const onCreate = () => {
        const payload = {
            account_id: session.accountId,
            title,
            description,
            start_at,
            end_at,
            active,
            user_id,
            type: defaults.type,
            goal_id: goalId,
        };

        if (mode === "edit") {
            service.updateGoal(session, goalId, payload, onClose, toasts.makeErrorToast);
        } else {
            service.createGoal(session, payload, onClose, toasts.makeErrorToast);
        }
    };

    return (
        <Modal show centered>
            <Modal.Header>
                <Modal.Title>Ziel</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group su-form">
                    <label>Titel</label>
                    <input
                        className="mb-4"
                        type="text"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                    />
                    <label>Beschreibung</label>
                    <textarea
                        className="mb-4"
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                    />
                    <label>Beginn</label>
                    <input
                        className="mb-4"
                        type="date"
                        onChange={(e) => setStartAt(e.target.value)}
                        value={start_at}
                        placeholder="Beginn"
                    />
                    <label>Ende</label>
                    <input
                        className="mb-4"
                        type="date"
                        onChange={(e) => setEndAt(e.target.value)}
                        value={end_at}
                        placeholder="Ende"
                    />
                    <div className="mb-4">
                        <label htmlFor="kpi-create-active" className="mr-2 font-weight-normal">
                            Aktiv
                        </label>
                        <input
                            type="checkbox"
                            id="kpi-create-active"
                            onChange={(e) => setActive(!active)}
                            checked={active}
                        />
                    </div>
                    <InputUserSearch
                        value={user_id}
                        onChange={setUserId}
                        session={session}
                        label="Owner"
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Abbrechen
                </Button>
                <Button variant="primary" onClick={onCreate}>
                    Fortfahren
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default GoalModal;
