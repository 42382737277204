const emptyText = "Ø";
const emptyLgText = "kein Wert";

export const emptyValue = 100000000000000000000;

const Pill = ({ children, color, className }) => (
    <span
        className={`mr-2 ml-2 py-1 px-3 bg-${color} border-dark rounded-pill text-white text-nowrap ${
            className || ""
        }`}
        style={{ fontSize: "0.8rem" }}
    >
        {children}
    </span>
);

export const KpiValue = ({ value: inputValue, unit, type, color }) => {
    // There is a value used internally for "not set" / "empty"; Convert this and undefined to null
    const value =
        Math.abs(inputValue) === emptyValue || inputValue === undefined ? null : inputValue;

    const text = value ? `${value} ${unit}` : type === "lg" ? emptyLgText : emptyText;

    return type === "pill" ? (
        <Pill color={!value ? "secondary" : color}>{text}</Pill>
    ) : type === "lg" ? (
        <span className={`font-weight-bold text-${color}`} style={{ fontSize: "1.4rem" }}>
            {text}
        </span>
    ) : (
        text
    );
};
