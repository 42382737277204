import { getHeaders, getBase, postHelper, deleteHelper, getHelper } from "lib/api";

const API_URL = getBase("toolset");

export const loadGoals = (session, type, onSuccess = () => {}, onError = () => {}) => {
    const url = `${API_URL}/goal/account/${session.accountId}/?type=${type}`;

    fetch(url, {
        method: "GET",
        headers: getHeaders(),
    })
        .then((response) => response.json())
        .then((data) => {
            if (data.message) {
                onError(data.message, "GET", url);
            } else {
                onSuccess(data);
            }
        });
};

export const createGoal = (session, goal, onSuccess, onError = console.error) => {
    const url = `${API_URL}/goal`;

    postHelper(session, url, goal, onSuccess, onError);
};
export const updateGoal = (session, goal_id, goal, onSuccess, onError = console.error) => {
    const url = `${API_URL}/goal/${goal_id}`;

    postHelper(session, url, goal, onSuccess, onError);
};
export const deleteGoal = (session, goal_id, onSuccess, onError = console.error) => {
    const url = `${API_URL}/goal/${goal_id}`;

    deleteHelper(session, url, onSuccess, onError);
};

export const attachKpi = (session, goalId, kpiId, onSuccess, onError = console.error) => {
    const url = `${API_URL}/goal/${goalId}/kpi/${kpiId}`;

    postHelper(session, url, {}, onSuccess, onError);
};
export const detachKpi = (session, goalId, kpiId, onSuccess, onError = console.error) => {
    const url = `${API_URL}/goal/${goalId}/kpi/${kpiId}`;

    deleteHelper(session, url, onSuccess, onError);
};
export const updateGoalKpi = (
    session,
    goalId,
    kpiId,
    payload,
    onSuccess,
    onError = console.error
) => {
    const url = `${API_URL}/goal/${goalId}/kpi/${kpiId}`;

    postHelper(session, url, payload, onSuccess, onError);
};

export const loadAvailableKpis = (session, goalId, onSuccess, onError = console.error) => {
    const url = `${API_URL}/goal/available/kpis/${goalId}`;

    getHelper(session, url, onSuccess, onError);
};

export const loadKpis = (session, goalId, onSuccess, onError = console.error) => {
    const url = `${API_URL}/goal/goal_kpis/${goalId}`;

    getHelper(session, url, onSuccess, onError);
};
