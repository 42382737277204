import React from "react";
import TableRow from "./table-row.js";

const Table = (props) => (
    <table className="table">
        <thead>
            <TableRow key="th" fields={props.fields} isHeading actionsCallback />
        </thead>
        <tbody>
            { (props.data || []).map( (entry, index) => (
                <TableRow
                    key={entry.id}
                    entry={entry}
                    fields={props.fields}
                    actionsCallback={props.actionsCallback}
                    />
            )) }
        </tbody>
    </table>
)
export default Table;