import React, { useState } from "react";

import * as service from "./api.js";

const Login = ({ errorMessage, toggleMode, setSession, style }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const onChangeEmail = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleLogin = (e) => {
        e.preventDefault();
        service.login(email, password, setSession);
    };
    const className = style === "inline" ? "d-flex justify-content-center" : "d-flex justify-content-center align-items-center"

    return (
        <div>
            <div className={ className } style={{minHeight: style !== "inline" && "60vh"}}>
                <div className="d-inline-block">
                    <div className="su-border">
                        <form action="/" onSubmit={handleLogin} className="card su-form su-login-form p-4 box-shadow">
                            { style !== "inline" && (
                                <h1 className="card-header">Login</h1>
                            ) }
                            <div className="card-body">
                                <div className="form-group mb-5">
                                    <label className="mb-2" htmlFor="email">E-Mail</label>
                                    <input type="text" name="email" onChange={onChangeEmail} value={email || ""} placeholder="Deine Email *" className="form-control"/>
                                </div>
                                <div className="form-group mb-5">
                                    <label className="mb-2" htmlFor="password">Passwort</label>
                                    <input type="password" name="password" onChange={onChangePassword} value={password || ""} placeholder="Dein Password *" className="form-control"/>
                                </div>
                                <div className="form-group text-center mb-3">
                                    <button type="submit" className="btn btn-primary">Login</button>
                                </div>
                                { style !== "inline" && (
                                    <div className="form-group text-center mb-0">
                                        <a href="/" onClick={(e) => {e.preventDefault(); toggleMode("forgot_password")} } className="color-light-grey font-size-1 cursor-pointer">
                                            Mit Magic-Link anstatt Passwort anmelden
                                        </a>
                                        <br />
                                        <a href="/" onClick={(e) => {e.preventDefault(); toggleMode("register")} } className="color-light-grey font-size-1 cursor-pointer">
                                            Neues Konto erstellen
                                        </a>
                                    </div>
                                ) }
                            </div>
                            {errorMessage && (
                            <p className="mt-3 text-danger">{errorMessage}</p>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            <div className="py-lg-5" />
        </div>
    );

};
export default Login