import { useEffect, useState } from 'react';
import { MenuLearning } from "components/Menus";
import { translate } from 'lib/language';
import arrowLeftIcon from "styles/images/arrow-left.2x.png";
import arrowRightIcon from "styles/images/arrow-right.2x.png";

import * as service from "./api";
import Status from './status';
import NavLink from './nav_link';
import Document from 'components/Document';


const LearningNugget = ({ session }) => {
    const [nugget, setNugget] = useState({})
    const [status, setStatus] = useState("loading")
    const [error, setError] = useState(null)

    const journeyId = window.location.href.split('?')[1].split('/')[2]
    const nuggetId = window.location.href.split('?')[1].split('/')[3]

    useEffect(() => {
        service.loadNugget(session, journeyId, nuggetId, setNugget, setError)
        service.loadNuggetStatus(session, nuggetId, (data) => setStatus(data ? data.status : "unknown"), console.error)
    }, [journeyId, session, nuggetId])

    const onSetStatus = (status) => {
        service.setNuggetStatus(session, nuggetId, status, journeyId, (data) => setStatus(data.status), console.error)
    }

    if (error) {
        console.error(error)
        return (
            <>
            <MenuLearning/>
            <span>Dieses Nugget steht für dich nicht zur Verfügung.</span>
            </>
        )
    }

    if (!nugget.id) {
        return (
            <div>
                <MenuLearning />
                <p>Laden...</p>
            </div>
        )
    }

    const previousNuggetLink = nugget.previousNugget ? `?learning/nugget/${journeyId}/${nugget.previousNugget.id}` : null;
    const nextNuggetLink = nugget.nextNugget ? `?learning/nugget/${journeyId}/${nugget.nextNugget.id}` : null;

    return (
        <div>
            <div className="d-flex align-items-start justify-content-center">
                <div className="d-none d-lg-block pr-4 pt-5">
                    {previousNuggetLink ? <NavLink img={ arrowLeftIcon } link={ previousNuggetLink } title="vorheriges Learning-Nugget öffnen" noLinkTitle="Dies ist das erste Nugget in der Journey" /> : null}
                </div>

                <div className="w-100">
                    <a href={ `?learning/journey/${journeyId}` } className="color-orange">Zurück zur Journey</a>
                    <h1 className="pt-4">{ translate(nugget, "title") }</h1>

                    <div className="mb-5 mt-3 d-flex align-items-center">
                        <Status status={ status } markAsVisitedAfter={ 10000 } onSetStatus={ onSetStatus } size="medium" />
                    </div>

                    <Document content={ nugget.content } />

                    <div className="row align-items-center">
                        <div className="col-3">
                            {previousNuggetLink? <NavLink link={ previousNuggetLink }>&laquo; Vorheriges Nugget</NavLink> : null}
                        </div>

                        <div className="col-6 text-center">
                            <Status status={ status } markAsVisitedAfter={ 10000 } onSetStatus={ onSetStatus } size="large" />
                        </div>

                        <div className="col-3 text-right">
                            {nextNuggetLink ? <NavLink link={ nextNuggetLink }>&raquo; Nächstes Nugget</NavLink> : null}
                        </div>
                    </div>
                </div>

                <div className="d-none d-lg-block pl-4 pt-5">
                    {nextNuggetLink ? <NavLink img={ arrowRightIcon } link={ nextNuggetLink } title="nächstes Learning-Nugget öffnen" noLinkTitle="Dies ist das letzte Nugget in der Journey" /> : null}
                </div>
            </div>
        </div>
    )
}
export default LearningNugget;