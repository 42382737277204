const Textinput = ({ name, value, currentValue, onChange }) => {
  return (
    <textarea
      className="mr-3 mt-2 default-w-poll-input focus-visible-orange"
      name={name}
      value={currentValue}
      onChange={(e) => onChange(name, e.target.value)}
    />
  );
};

export default Textinput;
