import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as toasts from "lib/toasts";
import * as service from "./api";
import { useRef } from "react";

const ImportModal = ({ onClose, session }) => {
    const hiddenFileInput = useRef(null);

    const reload = () => {
        window.location.reload();
    };

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const onChangeFile = (e) => {
        if (e.target.files[0] === "" || e.target.files[0] === undefined) {
            //do nothing when file has only been reset
            return;
        }
        const selectedCsv = e.target.files[0];
        if (selectedCsv.size > 1000000) {
            toasts.makeErrorToast(
                "Datei zu groß! Bitte eine Datei mit einer Größe von maximal 1 MB auswählen."
            );
        } else if (selectedCsv.type !== "text/csv") {
            toasts.makeErrorToast(
                "Format nicht unterstützt! Bitte eine Datei im Format .csv auswählen."
            );
        } else {
            const formData = new FormData();
            formData.append("csvfile", selectedCsv);
            formData.append("account_id", session.accountId);

            service.importKpisFromCsv(formData, reload, onClose);
            e.target.value = ""; //clearing the value of the input
        }
    };

    return (
        <Modal show centered>
            <Modal.Header>
                <Modal.Title>KPI-Werte-Importieren</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="">
                    <p>
                        Hier kannst du die Werte für deine existierenden KPIs mit Hilfe einer
                        CSV-Datei importieren.
                    </p>
                    <p>
                        Die CSV-Datei muss dafür zwingend die Spalten <strong>name</strong> und{" "}
                        <strong>value</strong> enthalten!
                    </p>
                    <div className="mt-3">
                        <span>Beispiel:</span>
                        <table className="su-table">
                            <thead>
                                <tr>
                                    <th>name</th>
                                    <th>value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="align-middle">ARR</td>
                                    <td className="align-middle d-none d-lg-table-cell">20000</td>
                                </tr>
                                <tr>
                                    <td className="align-middle">MRR</td>
                                    <td className="align-middle d-none d-lg-table-cell">1500</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p className="alert alert-warning">
                        Diese Aktion überschreibt die bisherigen Werte der KPIs!
                    </p>
                    <input
                        type="file"
                        name="file"
                        ref={hiddenFileInput}
                        onChange={onChangeFile}
                        multiple={false}
                        accept=".csv"
                        className="form-control"
                        style={{ display: "none" }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Abbrechen
                </Button>
                <Button variant="primary" onClick={handleClick}>
                    CSV-Import starten
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default ImportModal;