import { getBase, getHelper, postHelper, getHeaders } from 'lib/api';
import * as toasts from 'lib/toasts';

const API_URL = getBase('toolset');
const AUTH_URL = getBase('auth');
const ADMIN_URL = getBase('admin');

export const loadPoll = (
  pollId,
  onSuccess = () => {},
  onError = () => {}
) => {
  getHelper(
    null,
    `${API_URL}/poll_run/${pollId}`,
    (run) =>
      getHelper(
        null,
        `${API_URL}/poll/${run.poll_id}`,
        onSuccess,
        onError
      ),
    onError
  );
};

export const loadPollRun = (pollRunId, onSuccess = ()=>{}, onError = ()=>{} ) => {
  const url = `${API_URL}/poll_run/${pollRunId}`;

  getHelper(null, url, onSuccess, onError);
};

export const requestPin = (checksum, setIdentity) => (email) => {
  const url = `${AUTH_URL}/auth/pin?email=${encodeURI(
    email
  )}&context=${encodeURI(checksum)}`;

  getHelper(
    null,
    url,
    ({ identity }) => setIdentity(identity),
    console.error
  );
};

export const submitPoll =
  (
    pollChecksum,
    poll,
    pollRunId,
    identity,
    answers,
    setSubmitState,
    authentification
  ) =>
  (session) => {
    const defs = {};
    poll.poll_items.forEach((item) => {
      defs[item.name] = item;
    });

    const values = [];
    Object.keys(answers).forEach((name) => {
      let val = answers[name];
      let definition = defs[name];
      if (val !== ""){
        values.push({
        poll_item_id: definition.id,
        value: val,
        })
      };
    });

    const isPin = authentification === 'pin'
    const url =
      isPin
        ? `${API_URL}/poll_item_value/bulk_pin`
        : `${API_URL}/poll_item_value/bulk`;
    const body = {
      poll_run_id: pollRunId,
      poll_run_uuid: pollChecksum,
      poll_item_values: values,
    }
    if (isPin) {
      body.pin = session
      body.identity = identity
    }
    else {
      body.user_id = session.profile.id
    }

    fetch(url, {
      method: 'POST',
      headers: {
        ...getHeaders(
          isPin ? null : session.apiKey
        ),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data === null) {
          setSubmitState('valid');
          toasts.makeSuccessToast("Antworten gespeichert", "toastSubmit");
        } else if (data.message === 'Invalid identification information') {
          setSubmitState('invalid');
          toasts.makeErrorToast("Ungültiger PIN - Bitte kontrolliere deine Eingabe oder fordere einen neuen PIN an.", "toastSubmit");
        } else if (data.message){
          setSubmitState('invalid')
          toasts.makeErrorToast(JSON.stringify(data.message), "toastSubmit");
        } else {
          toasts.makeErrorToast("Fehler beim Speichern der Antworten", "toastSubmit");
          setSubmitState('invalid')
        }
      })
      .catch((data) => {
        if (
          data.code === 'ERROR_CODE_ACCESS_DENIED' ||
          data.code === 'ERROR_CODE_INPUT_ERROR'
        ) {
          setSubmitState('invalid');
          toasts.makeErrorToast("Ungültiger PIN - Bitte kontrolliere deine Eingabe oder fordere einen neuen PIN an.", "toastSubmit");
        }
      });
  };

export const loadUsersByRole = (
  accountId,
  role_ids,
  onSuccess = () => {},
  onError = console.error
) => {
  const url = `${ADMIN_URL}/account/${accountId}/role/user`;

  postHelper(
    accountId,
    url,
    { role_ids: role_ids },
    onSuccess,
    onError
  );
};