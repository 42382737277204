import React, { useEffect, useState } from "react";
import NewTeam from "./createTeam";


import * as service from "./api.js";
import _ from "lodash";
import toast from "react-hot-toast";


const AccountPicker = (props) => {
    const { profile } = props;
    const [teamName, setTeamName] = useState(null);
    const [newTeam, setNewTeam] = useState(null);
   
    const handleLogLink = async (e, accountId, url) => {
        if (e != null){
            e.preventDefault();
        }
        await service.logAccountChange(accountId);
        // do something else here after log completes
        //window.location.href = url;
        window.location = `${url}${window.location.search}`;
    }

    // if only one account and not sup_coach => directly to the account, else show all accounts for selection
    useEffect(() => {
        if (profile.accounts.length === 1 && profile.is_sup_coach === false ) {
            handleLogLink(null , profile.accounts[0].id, `/${profile.accounts[0].id}`)
        }
    }, [profile.accounts, profile.is_sup_coach]);

    //wait for useeffect to finish
    if (profile.accounts.length === 1 && profile.is_sup_coach === false ) {
        return "Laden...";
    }
    //if a new Team has been created -> wait for log then redirect to the new team
    if (newTeam !== null) {
        toast.dismiss(); //dismiss toasts when new Team is successfully created
        //Add newTeam to SessionStorage, so that we can access it in other components
        sessionStorage.setItem('newTeam', JSON.stringify(newTeam));
        handleLogLink(null, newTeam.id, `/${newTeam.id}`);
    }
    
    const onChangeTeamName = (e) => {
        const teamName = e.target.value;
        setTeamName(teamName);
    };

    const handleNewTeam = (e) => {
        e.preventDefault();
        if (teamName !== null) {
            service.createNewTeam(teamName, profile.id, setNewTeam);
        }
    };

    
    return (
        <div>
            <div className="d-flex justify-content-center align-items-center" style={{minHeight: "60vh"}}>
                <div className="d-inline-block">
                    <div className="su-border su-with-border-headline">
                        <div className="su-border-headline">Team wählen</div>
                            <ul className="su-navigation-list">
                                {profile.accounts.map((account, index) => (
                                <li key={account.id} className="box-shadow mb-3" 
                                    onClick={ (e) => handleLogLink(e, account.id, `/${account.id}`)}  
                                >
                                    <a href={'/' + account.id}>{_.find(profile.accounts, {'id': account.id}).name}</a>
                                </li>
                                ))}
                                
                                {profile.is_sup_coach ? (
                                    <div>
                                        <li className="box-shadow">
                                            <NewTeam>
                                                <div className="w-padding">
                                                    <div className="form-group su-form">
                                                        <input type="text" name="teamName" onChange={onChangeTeamName} value={teamName || ""} placeholder='Name für das neue Team'></input>
                                                    </div>
                                                    <button className='btn btn-primary' onClick={handleNewTeam}>Team erstellen</button>
                                                </div>
                                            </NewTeam>
                                        </li>

                                    </div>
                                    )
                                    :(null)}
                            </ul>
                        </div>
                    </div>
                </div>
            <div className="py-lg-5"></div>
        </div>
    );

};
export default AccountPicker