import { getHeaders, getBase } from "lib/api";

const API_URL = getBase("toolset");

export const loadTools = (session, usage, onSuccess = ()=>{}, onError = ()=>{} ) => {
    const url = `${API_URL}/tool/account/${session.accountId}/usage/${usage}`;

    fetch(url,
        {
            method: 'GET',
            headers: getHeaders()
        })
        .then(response => response.json())
        .then(data => {
            if(data.message) {
                onError(data.message, "GET", url);
            } else {
                onSuccess(data);
            }
        });
};
