import React, { useContext, useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import * as service from "./api.js";
import * as toasts from "lib/toasts";
import SessionContext from "lib/session.js";

export function EditPollRunModal({ run, pollName, onChange, onClose }) {
    const session = useContext(SessionContext);
    const [name, setName] = useState(run.title || pollName); //if no title in pollrun, use poll name
    const [startDate, setStartDate] = useState(run.begin_at);
    const [endDate, setEndDate] = useState(run.end_at);

    const handleSave = (e) => {
        e.preventDefault();

        if (name !== null && startDate !== null && endDate !== null) {
            toasts.makeLoadingToast("Änderungen speichern...", "toastEditPollRun");
            service.updatePollRun(
                session,
                run.id,
                name,
                startDate,
                endDate,
                () => {
                    toasts.makeSuccessToast("Änderungen gespeichert", "toastEditPollRun");
                    onChange();
                },
                (msg) => toasts.makeErrorToast(msg, "toastEditPollRun")
            );
        }
    };

    return (
        <Modal show centered>
            <Modal.Header>
                <Modal.Title>Umfrage bearbeiten</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form action="/" onSubmit={handleSave} className="su-form p-4">
                    <div className="form-group mb-4">
                        <label htmlFor="name">Name der Umfrage</label>
                        <input
                            type="text"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Neuen Namen für die Umfrage eingeben"
                            className="form-control"
                        />
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="date_start">Beginn</label>
                        <input
                            type="date"
                            name="date_start"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className="form-control"
                        />
                    </div>

                    <div className="form-group mb-4">
                        <label htmlFor="date_end">Ende</label>
                        <input
                            type="date"
                            name="date_end"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            className="form-control"
                        />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" variant="primary" onClick={handleSave}>
                    Änderungen speichern
                </Button>
                <Button variant="secondary" onClick={onClose}>
                    Abbrechen
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
