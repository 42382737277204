import React from 'react';
import { useState, useRef } from 'react';
import { FaRegPlusSquare } from 'react-icons/fa';

const NewTeam = (props) => {
  const [open, setOpen] = useState(false);

  const contentRef = useRef();

  const toggle = (e) => {
    e.preventDefault()
    setOpen(!open);
  };

  return (
    <div>
      <div>
        <a href="/" className={open ? "color-orange d-flex align-items-center": "color-light-grey d-flex align-items-center"} onClick={toggle}>
                  <FaRegPlusSquare className='icon-left' />
                 Neues Team hinzufügen
        </a>
      </div>
      <div
        className="content-parent"
        ref={contentRef}
        style={
          open
            ? { height: contentRef.current.scrollHeight + 'px' }
            : { height: '0px' }
        }
      >
        <div className="content">{props.children}</div>
      </div>
    </div>
  );
};
export default NewTeam;
