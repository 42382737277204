import LoginAuth from "./login_auth";
import PinAuth from "./pin_auth";

const modes = {
    "pin": PinAuth,
    "login": LoginAuth,
}
const Invalid = ({ mode }) => (
    <p className="alert alert-danger">Invalid submit type for form: { mode }</p>
)

const Submit = (props) => {
    const { mode } = props
    const Mode = modes[mode] || Invalid

    return <Mode { ...props } />
}
export default Submit