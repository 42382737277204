import { getHeaders, getBase, logHelper } from "lib/api";
import * as toasts from "lib/toasts";

const API_URL = getBase("auth");
const ADMIN_URL = getBase("admin");

export const register = (firstName, lastName, email, password, onSuccess = ()=>{}, onError = () => {}) => {
    fetch(API_URL+'/auth/signup',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify( { firstname: firstName, lastname: lastName, email: email, password: password} )
        })
        .then(response => response.json())
        .then(apiKey => {
            if(apiKey.message) {
                onError(apiKey.message);
            } else {
                me(apiKey, onSuccess, onError);
            }
        });
};

export const registerMagic = (firstName, lastName, email, password, makeSignUpInfoModal, onSuccess = ()=>{}, onError = () => {}) => {
    const base = window.location.href.split('?')[0];
    toasts.makeLoadingToast("Signing up...", "toastSignup");
    fetch(API_URL+'/auth/magic/signup',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify( { firstname: firstName, lastname: lastName, email: email, password: password, redirect_url: `${base}?auth/link`} )
        })
        .then(response => response.json())
        .then(apiKey => {
            if(apiKey.message) {
                onError(apiKey.message);
                toasts.makeErrorToast(apiKey.message, "toastSignup" );
            } else {
                makeSignUpInfoModal();
                toasts.dismiss("toastSignup");
                me(apiKey, onSuccess, onError);
            }
        });
};

export const getMagicLink = (email, makeInfoModal, onSuccess = ()=>{}, onError = ()=> {}) => {
    toasts.makeLoadingToast("Magic Link senden...", "toastMagicLink");
    const base = window.location.href.split('?')[0];
    fetch(`${API_URL}/auth/magic/link/`,
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify( { email: email, redirect_url: `${base}?auth/link`} )
        })
        .then(response => response.json())
        .then(data => {
            if(data.message) {
                onError(data.message);
                toasts.makeErrorToast("Ups, bitte versuche es noch einmal!", "toastMagicLink" );
            } else {
                onSuccess(data);
                toasts.makeSuccessToast("Magic Link erfolgreich gesendet!", "toastMagicLink" );
                makeInfoModal();
            }
        });
};

export const login = (email, password, onSuccess = () => {}, onError = () => {}) => {
    toasts.makeLoadingToast("Login...", "toastLogin");
    fetch(API_URL+'/auth/login',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify( { email: email, password: password } )
        })
        .then(response => response.json())
        .then(apiKey => {
            if(apiKey.message) {
                onError(apiKey.message);
                toasts.makeErrorToast((JSON.stringify(apiKey.message)), "toastLogin");
            } else {
                me(apiKey, onSuccess, onError);
                toasts.makeSuccessToast("Login erfolgreich", "toastLogin");
            }
        });
};

export const me = (apiKey, onSuccess, onError) => {
    fetch(API_URL+'/auth/me',
        {
            method: 'GET',
            headers: getHeaders(apiKey),
        })
        .then(response => response.json())
        .then(data => {
            if(data.message || data.code) {
                onError(`${data.code}: ${data.message}`);
            } else {
                onSuccess({
                    ...data,
                    apiKey,
                });
            }
        });
}

export const createNewTeam = (name, user_id, onSuccess = ()=>{}, onError = ()=> {}) => {
    toasts.makeLoadingToast("Team erstellen...", "toastCreateTeam");
    fetch(ADMIN_URL+'/account',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify( { name: name, user_id: user_id } )
        })
        .then(response => response.json())
        .then(data => {
            if(data.message) {
                onError(data.message);
                toasts.makeErrorToast(JSON.stringify(data.message), "toastCreateTeam");
            } else {
            console.info("In createnewteam")
                onSuccess(data);
            }
        });
};

export const deleteTeam = (team_id, onSuccess = ()=>{}) => {
    toasts.makeLoadingToast("Team erstellen...", "toastCreateTeam");
    fetch(`${ADMIN_URL}/account/${team_id}`,
        {
            method: 'DELETE',
            headers: getHeaders(),
        })
        .then(response => response.json())
        .then(data => {
            onSuccess(data);
        });
};

export const logAccountChange = async (accountId, onSuccess = ()=>{}, onError = ()=> {}) => {
    const url = `${ADMIN_URL}/account/change/${accountId}`;

    await logHelper(null, url, null, onSuccess, onError);
};

export const logout = () => {
    window.sessionStorage.clear();
    window.location.reload();
};