import { translate } from "lib/language";
import Nugget from "./nugget";

import checkIcon from "styles/images/check.svg"
import { map } from "lodash";

const Path = (path) => (
    <div className="bg-light-grey pt-3 px-3 pt-lg-4 px-lg-4 pb-0 mb-3 mb-lg-4">
        <h2 className="mb-lg-4">
            <span className="font-weight-normal float-right color-light-grey">
                { path.num_nuggets_checked === path.num_nuggets ? (
                    <img src={ checkIcon } className="ml-2" width="30" height="30" alt=""/>
                ) : (
                    `${path.num_nuggets_checked}/${ path.num_nuggets}`
                ) }
            </span>
            { translate(path, "title") }
        </h2>
        <div className="row ml-0 mr-0">
            { map(path.nuggets, (nugget, index) => (
                <Nugget { ...nugget } key={ index } journeyId={ path.journeyId } />
            )) }
        </div>
    </div>
)
export default Path;