import React, { useCallback, useEffect } from "react";

import toggleOnIcon from "styles/images/large-toggle-on.png"
import toggleOffIcon from "styles/images/large-toggle-off.png"


const Status = ({status, markAsVisitedAfter, onSetStatus, size}) => {
    const markAsVisited = useCallback(() => {
        // console.info("Visited timer activated, status was", status)
        if (status === "unknown") {
            onSetStatus("visited");
        }
    }, [status, onSetStatus])
    const toggleStatus = () => {
        const newStatus = status === "checked" ? "visited" : "checked"

        onSetStatus(newStatus);
    }

    // Called at mount
    useEffect(() => {
        let timer;
        if(markAsVisitedAfter > 0) {
            // console.info("Visited timer set for", markAsVisitedAfter)
            timer = window.setTimeout(markAsVisited, markAsVisitedAfter)
        }

        // Called after unmount
        return () => {
            window.clearTimeout(timer)
            // console.info("Visited timer cleared")
        }
    }, [markAsVisited, markAsVisitedAfter])

    if (status === "loading") {
        return "Laden..."
    }

    const isChecked = status === "checked"
    const label = "Learning-Nugget abgeschlossen"
    const icon = isChecked ? toggleOnIcon : toggleOffIcon;

    return (
        <div className={ `su-nugget-status su-size-${ size || "medium" }` }>
            <img
                src={ icon }
                alt={ label }
                className="su-clickable mr-2"
                onClick={ toggleStatus }
            />
            <span className="label">{ label }</span>
        </div>
    )
}
export default Status;