import * as service from "./api";
import { useEffect, useState} from "react";
import { MenuApplying } from "components/Menus";
import HeaderProfile from "components/HeaderProfile";
import { map } from "lodash";
import KpiModal from "./kpi_modal";
import ImportModal from "./import_modal";
import Kpi from "./kpi";
import { userCan } from "lib/permissions";

const ToolsKPIs = ({ session }) => {
    const [kpis, setKpis] = useState([]);
    const [createModalActive, setCreateModal] = useState(false);
    const [importModalActive, setImportModal] = useState(false);

    useEffect(() => {
        service.loadKpis(session, setKpis, console.error);
    }, [session]);

    const onClose = () => {
        service.loadKpis(session, setKpis, console.error);
        setCreateModal(false);
        setImportModal(false);
    };

    return (
        <div>
            <HeaderProfile session={session} />
            <MenuApplying />
            <a href={`${session.accountId}?applying`} className="color-orange">
                Zurück zu den Tools
            </a>
            <div className="card-body">
                {userCan(session, "TOOLS_KPI_EDIT") && (
                    <div className="float-right">
                        <a
                            href="/"
                            className="btn btn-primary mb-2"
                            onClick={(e) => {
                                e.preventDefault();
                                setImportModal(true);
                            }}
                        >
                            KPI-Werte importieren
                        </a>
                    </div>
                )}
                {userCan(session, "TOOLS_KPI_ADD") && (
                    <div className="float-right">
                        <a
                            href="/"
                            className="btn btn-primary mb-2 mr-2"
                            onClick={(e) => e.preventDefault() || setCreateModal(true)}
                        >
                            KPI hinzufügen
                        </a>
                    </div>
                )}

                <h1>KPIs</h1>

                {kpis.length === 0 && <p>Es sind noch keine KPIs vorhanden.</p>}

                {createModalActive && (
                    <KpiModal onClose={onClose} session={session} defaults={{}} />
                )}

                {importModalActive && (
                    <ImportModal onClose={onClose} session={session} />
                )}

                {map(kpis, (kpi) => (
                    <Kpi {...kpi} key={kpi.id} session={session} onDelete={onClose} />
                ))}
            </div>
        </div>
    );
};
export default ToolsKPIs;
