import { ResponsivePie } from '@nivo/pie'

const CenteredMetric = (myProps) => (layerProps) => {

    /* Tutorial: https://codesandbox.io/s/kind-morning-z3ygm?file=/src/App.js:152-196 */

    const { centerX, centerY } = layerProps;
    const { score } = myProps;

    if(score === "") {
        return "";
    }

    let fontSize = '24px';
    if(score.length > 3) {
        fontSize = '18px';
    }
    return (
        <text
            x={centerX}
            y={centerY*0.9}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
                    color:"#FFFFFF",
                    fontSize: fontSize
                }}
            >
            {score}
        </text>
    )
}

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const GaugeChart = ({ data, score /* see data tab */ }) => (
    <ResponsivePie
        colors={{ datum: 'data.color' }}
        data={data}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        startAngle={-90}
        endAngle={90}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        borderWidth={1}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.2
                ]
            ]
        }}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    2
                ]
            ]
        }}
        activeOuterRadiusOffset={0}
        valueFormat=">.0p"
        layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric({score: score})]}
    />
);

export default GaugeChart;