import HeaderProfile from "components/HeaderProfile";
import React from "react";
import { useState, useEffect } from "react";
import * as service from "./api";
import ApiKey from "./apikey";
import ApiKeyModal from "./apikey_modal";
import { userCan } from "lib/permissions";

const ApiKeyEditor = ({ session }) => {
    const [apiKeys, setApiKeys] = useState([]);
    const [createModalActive, setCreateModal] = useState(false);

    useEffect(() => {
        service.loadApiKeys(session, setApiKeys, console.error);
    }, [session]);

    const onClose = () => {
        service.loadApiKeys(session, setApiKeys, console.error);
        setCreateModal(false);
    };

    if (!userCan(session, "ACCOUNT_APIKEY_ACCESS")) {
        return <div>Du hast keine Zugriffsrechte für diese Seite.</div>;
    }

    return (
        <div>
            <HeaderProfile session={session} />
            <div className="container">
                <ul className="nav nav-tabs mb-3">
                    <li className="nav-item">
                        <a className="nav-link" href={session.accountId + "?account-editor"}>
                            Account editieren
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link active" href={session.accountId + "?apikey-editor"}>
                            API-Keys verwalten
                        </a>
                    </li>
                </ul>

                <div className="d-flex align-items-center justify-content-between my-5">
                    <h3 className="">Verfügbare API-Keys</h3>
                    {userCan(session, "ACCOUNT_APIKEY_ADD") && (
                        <a
                            href="/"
                            className="btn btn-primary"
                            onClick={(e) => e.preventDefault() || setCreateModal(true)}
                        >
                            + API-Key erzeugen
                        </a>
                    )}
                </div>

                {apiKeys.length === 0 && <p>Es sind noch keine API-Keys vorhanden.</p>}

                {createModalActive && <ApiKeyModal onClose={onClose} session={session} />}
                <table className="su-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Erstellt am</th>
                            <th>Erstellt von</th>
                            <th>API-Key</th>
                            <th>Aktionen</th>
                        </tr>
                    </thead>
                    <tbody>
                        {apiKeys.map((apikey) => (
                            <ApiKey
                                apikey={apikey}
                                key={apikey.id}
                                session={session}
                                onDelete={onClose}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ApiKeyEditor;
