import EnsureLoggedin from "pages/EnsureLoggedin";
import AuthMagicLink from "pages/AuthMagicLink";
import TeamEditor from "pages/TeamEditor";
import LearningDashboard from "pages/LearningDashboard";
import LearningJourney from "pages/LearningJourney";
import LearningNugget from "pages/LearningNugget";
import ProfileEditor from "pages/ProfileEditor";
import AccountEditor from "pages/AccountEditor";
import ApiKeyEditor from "pages/ApiKeyEditor";

import { ApplyingDashboard, CoachingDashboard } from "pages/ToolsDashboard";
import { CoachingPolls, ApplyingPolls } from "pages/ToolsPoll";
import { CoachingPollRun, ApplyingPollRun } from "pages/ToolsPollRun";
import { CoachingPollEval, ApplyingPollEval } from "pages/ToolsPollEval";
import ToolsPollForm from "pages/ToolsPollForm";
import { ToolsBhag } from "pages/ToolsGoals";
import ToolsGrowthCanvas from "pages/ToolsGrowthCanvas";
import ToolsKPIs from "pages/ToolsKPI";

import SessionContext from "lib/session";
import Header from "components/Header";
import ChartDummy from "pages/ChartDummy";
import { Toaster } from "react-hot-toast";

const NotFound = ({ path, reason }) => (
    <div className="py-3 py-lg-4">
        <div className="container">
            <Header />
            <h1>Not Found</h1>
            <p>
                You tried to access the path {path}, but the {reason} was not found.
            </p>
        </div>
    </div>
);

function App() {
    // null = root / index
    // First item: needs auth?
    // Second item: Module to render
    const modules = {
        // Root
        "": {
            null: [true, ApplyingDashboard],
        },
        auth: {
            link: [false, AuthMagicLink],
        },
        "chart-dummy": {
            null: [true, ChartDummy],
        },
        applying: {
            null: [true, ApplyingDashboard],
            bhag: [true, ToolsBhag],
            growth_canvas: [true, ToolsGrowthCanvas],
            kpis: [true, ToolsKPIs],
            standardpolls: [true, ApplyingPolls],
            poll_form: [false, ToolsPollForm],
            poll_run: [true, ApplyingPollRun],
            poll_eval: [true, ApplyingPollEval],
        },
        coaching: {
            null: [true, CoachingDashboard],
            coachingpolls: [true, CoachingPolls],
            poll_run: [true, CoachingPollRun],
            poll_eval: [true, CoachingPollEval],
        },
        learning: {
            null: [true, LearningDashboard],
            journey: [true, LearningJourney],
            nugget: [true, LearningNugget],
        },
        "team-editor": {
            null: [true, TeamEditor],
        },
        "profile-editor": {
            null: [true, ProfileEditor],
        },
        "account-editor": {
            null: [true, AccountEditor],
        },
        "apikey-editor": {
            null: [true, ApiKeyEditor],
        },
    };
    const path = window.location.search.substring(1);
    const parts = path.split("/");

    // if hubspot tracking link -> redirect to /
    if (path.startsWith("utm")) {
        window.location.href = "/";
    }

    const routeGroup = modules[parts[0]]; // Selected group or root
    if (!routeGroup) return <NotFound path={path} reason="routeGroup" />;

    // Selected route or root
    const route = routeGroup[parts[1] || null];
    if (!route) return <NotFound path={path} reason="route" />;

    const [needsAuth, Module] = route;

    if (needsAuth) {
        return (
            <div className="py-3 py-lg-4">
                <div className="container">
                    <Header />
                    <Toaster />
                    <EnsureLoggedin>
                        <SessionContext.Consumer>
                            {(session) => <Module session={session} />}
                        </SessionContext.Consumer>
                    </EnsureLoggedin>
                </div>
            </div>
        );
    }

    return <Module />;
}

export default App;
