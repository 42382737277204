import { getHeaders, getBase, getFormDataHeaders } from 'lib/api';
import * as toasts from 'lib/toasts';

const API_URL = getBase('admin');
const AUTH_URL = getBase('auth');

export const loadUser = (
  session,
  onSuccess = () => {},
  onError = () => {}
) => {
  const url = `${API_URL}/user/${session.profile.id}`;

  fetch(url, {
    method: 'GET',
    headers: getHeaders(),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.message) {
        onError(data.message, 'GET', url);
      } else {
        onSuccess(data);
      }
    });
};

export const updateProfileData = (
  session,
  firstname,
  lastname,
  email,
  setUser,
  setMessage,
  onSuccess = () => {},
  onError = () => {}
) => {
  toasts.makeLoadingToast('Daten speichern...', 'toastUpdate');

  fetch(`${API_URL}/user/${session.profile.id}`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      user_id: session.profile.id,
      firstname: firstname,
      lastname: lastname,
      email: email,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.message) {
        onError(
          //setMessage(JSON.stringify(data.message)),
          toasts.makeErrorToast(data.message),
          'toastUpdate'
        );
      } else {
        //setMessage("success");
        toasts.makeSuccessToast(
          'Daten erfolgreich gespeichert',
          'toastUpdate'
        );
        loadUser(session, setUser, onSuccess, onError); //Profil Daten neu laden
      }
    });
};

export const uploadProfilePicture = (
  session,
  formData,
  setUser,
  setMessage,
  onSuccess = () => {},
  onError = () => {}
) => {
  toasts.makeLoadingToast(
    'Profilbild hochladen...',
    'toastUploadProfilePicture'
  );
  fetch(`${API_URL}/user/${session.profile.id}/image`, {
    method: 'POST',
    headers: getFormDataHeaders(),
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.message) {
        onError(
          //setMessage(data.message),
          toasts.makeErrorToast(
            JSON.stringify(data.message),
            'toastUploadProfilePicture'
          )
        );
      } else {
        //setMessage('success');
        onSuccess(
          toasts.makeSuccessToast(
            'Profilbild geändert',
            'toastUploadProfilePicture'
          )
        );
        loadUser(session, setUser, onSuccess, onError); //Profil Daten neu laden
      }
    });
};

export const changePassword = (
  session,
  password,
  confirmationPassword,
  setUser,
  setMessage,
  onSuccess = () => {},
  onError = () => {}
) => {
  toasts.makeLoadingToast(
    'Passwort ändern...',
    'toastChangePassword'
  );
  fetch(`${AUTH_URL}/password/update`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      password: password,
      confirm_password: confirmationPassword,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.message) {
        onError(
          JSON.stringify(data.message).includes('success')
            ? toasts.makeSuccessToast(
                'Passwort geändert',
                'toastChangePassword'
              )
            : toasts.makeErrorToast(
                JSON.stringify(data.message),
                'toastChangePassword'
              )
        );
      } else {
        //setMessage('success');
        toasts.makeSuccessToast(
          'Passwort geändert',
          'toastChangePassword'
        );
        loadUser(session, setUser, onSuccess, onError); //Profil Daten neu laden
      }
    });
};

export const deleteUser = (
  session,
  onSuccess = () => {},
  onError = () => {}
) => {
  fetch(`${API_URL}/user/${session.profile.id}`, {
    method: 'DELETE',
    headers: getHeaders(),
    body: JSON.stringify({
      user_id: session.profile.id,
    }),
  }).then(() => {
    //logout after delete
    window.localStorage.removeItem('apiKey');
    window.location.reload();
  });
};
