import React from 'react';
const { translate } = require("lib/language");

const Button = ({label, value, name, currentValue, onChange}) => (
    <button
        type="button"
        className={"btn font-size-2 px-3 py-2 "+ (value === currentValue ? 'btn-primary' : 'btn-secondary') }
        onClick={(e) => {
            e.preventDefault();
            onChange(name, value);
        }}
    >
        { label }
    </button>
);

const ToggleButton = (definition) => {
    const { range, type, name, currentValue, onChange } = definition
    const options = [];
    const labels = translate( definition, 'range' )
    const caption = translate( definition, 'caption' )

    if(type === "textlist" || type === "numericlist") {
        range.forEach((option, optIndex) => {
            options.push(<Button label={ labels[optIndex] } key={ optIndex } value={ type === "numericlist" ? parseInt(option) : option } name={ name } currentValue={ currentValue } onChange={ onChange } />);
        });
    } else if(type === "boolean") {
        options.push(<Button label={ labels[0] } key={ 1 } value={ 1 } name={ name } currentValue={ currentValue } onChange={ onChange } />);
        options.push(<Button label={ labels[1] } key={ 0 } value={ 0 } name={ name } currentValue={ currentValue } onChange={ onChange } />);
    } else if(type === "nps" || type === "integer") {
        for(let i = parseInt(range[0]); i <= parseInt(range[1]); i++) {
            options.push(<Button label={ i } key={ i } value={ i } name={ name } currentValue={ currentValue } onChange={ onChange } />);
        }
    }

    return (
        <div className="d-inline-block" style={(type === "nps" || type === "integer") ? {minWidth: '300px'} : null}>
            <div key={name} className="btn-group su-btn-group-bw" role="group" aria-label={caption}>
                <div className={"d-flex flex-md-row hover-orange  "+ ((type === "nps" || type === "integer")  ? 'mt-0' : '')}> { options }</div>
            </div>
            { (type === "nps" || type === "integer") && (
                <div className="d-flex justify-content-between font-size-1 color-light-grey mt-2">
                    <span>{labels[0]}</span>
                    <span>{labels[1]}</span>
                </div>
                
            )}
        </div>
    )
}
export default ToggleButton
