import { useEffect } from "react";

/* 
const ExampleComponent = () => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setActive(false));

    return (
        <div ref={wrapperRef}>
            Component
        </div>
    )
}
*/

export const useOutsideAlerter = (ref, callback) =>
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, callback]);
