import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as toasts from "lib/toasts";

import InputWithIcons from "components/InputWithIcons";

import * as service from "./api";
import { useState } from "react";
import InputUserSearch from "components/InputUserSearch";
import { kpiIntervals, kpiMonotonies, kpiScopes } from "./kpi";

const KpiModal = ({ onClose, session, defaults, kpiId, mode }) => {
    const [title, setTitle] = useState(defaults.title || "");
    const [name, setName] = useState(defaults.name || "");
    const [interval, setInterval] = useState(defaults.interval || "none");
    const [active, setActive] = useState(defaults.active === undefined ? true : defaults.active);
    const [monotony, setMonotony] = useState(defaults.monotony || "increasing");
    const [unit, setUnit] = useState(defaults.unit || "");
    const [scope, setScope] = useState(defaults.scope || "account");
    const [owner, setOwner] = useState(defaults.user_id || "");

    const onCreate = () => {
        const payload = {
            account_id: session.accountId,
            title,
            name,
            interval,
            active,
            monotony,
            unit,
            scope,
            user_id: owner,
        };

        if (mode === "edit") {
            service.updateKpi(session, kpiId, payload, onClose, toasts.makeErrorToast);
        } else {
            service.createKpi(session, payload, onClose, toasts.makeErrorToast);
        }
    };

    return (
        <Modal show centered>
            <Modal.Header>
                <Modal.Title>KPI</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group su-form">
                    <input
                        className="mb-4"
                        type="text"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        placeholder="Titel"
                    />
                    <input
                        className="mb-4"
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        placeholder="Name"
                    />
                    <InputWithIcons
                        className="mb-4"
                        label="Intervall"
                        value={interval}
                        onChange={setInterval}
                        options={kpiIntervals}
                    />
                    <div className="mb-4">
                        <label htmlFor="kpi-create-active" className="mr-2 font-weight-normal">
                            Aktiv
                        </label>
                        <input
                            type="checkbox"
                            id="kpi-create-active"
                            onChange={(e) => setActive(!active)}
                            checked={active}
                        />
                    </div>
                    <InputWithIcons
                        className="mb-4"
                        label="Es ist gut wenn der Wert"
                        value={monotony}
                        onChange={setMonotony}
                        options={kpiMonotonies}
                    />
                    <input
                        className="mb-4"
                        type="text"
                        onChange={(e) => setUnit(e.target.value)}
                        value={unit}
                        placeholder="Einheit"
                    />
                    <InputWithIcons
                        className="mb-4 d-none"
                        label="Bezug"
                        value={scope}
                        onChange={setScope}
                        options={kpiScopes}
                    />
                    <InputUserSearch
                        value={owner}
                        onChange={setOwner}
                        session={session}
                        label="Owner"
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Abbrechen
                </Button>
                <Button variant="primary" onClick={onCreate}>
                    Fortfahren
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default KpiModal;
