import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { useEffect, useState } from "react";
import { MenuLearning } from "components/Menus";
import { translate } from "lib/language";

import * as service from "./api";
import Nugget from "./nugget";
import Path from "./path";
import { map } from "lodash";

const LearningJourney = ({ session }) => {
    const [journey, setJourney] = useState({});
    const [error, setError] = useState(null);

    const journeyId = window.location.href.split("?")[1].split("/")[2];

    useEffect(() => {
        service.loadJourney(session, journeyId, setJourney, setError);
    }, [journeyId, session]);

    if (error) {
        console.error(error);
        return (
            <>
                <MenuLearning />
                <span>
                    Diese Journey steht in diesem Team nicht zur Verfügung.
                    <br />
                    Bitte wähle ein anderes Team.
                </span>
            </>
        );
    }

    if (!journey.id) {
        return (
            <div>
                <MenuLearning />
                <p>Laden...</p>
            </div>
        );
    }

    const percentDone = Math.ceil((journey.num_nuggets_checked / journey.num_nuggets) * 100);

    return (
        <div>
            <MenuLearning />
            <a href={`${session.accountId}?learning`} className="color-orange">
                Zurück zu den Journeys
            </a>
            <h1 className="mb-4 mt-3">
                <span className="font-weight-normal float-right">{percentDone}%</span>
                {translate(journey, "title")}
            </h1>

            <div className="mb-4 d-inline-block">
                <h3 className="font-weight-normal">
                    {journey.num_nuggets_checked}/{journey.num_nuggets} Nuggets abgeschlossen
                </h3>
                <div className="su-progress-bar">
                    <div className="su-progress-bar-bg" style={{ width: `${percentDone}%` }}></div>
                </div>
            </div>

            <ReactMarkdown remarkPlugins={[remarkGfm]} className="su-markdown mb-4">
                {translate(journey, "description")}
            </ReactMarkdown>

            {map(journey.rows, (content, index) =>
                content[0].type === "path" ? (
                    <Path {...content[0]} journeyId={journeyId} key={index} />
                ) : (
                    <div className="adjustm">
                        <div className="row ml-0 mr-0" key={index}>
                            {map(content, (content, index) => (
                                <Nugget {...content} journeyId={journeyId} key={index} />
                            ))}
                        </div>
                    </div>
                )
            )}
        </div>
    );
};
export default LearningJourney;
