import React, { useState, useEffect, useContext } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import QRCode from "qrcode";
import * as htmlToImage from "html-to-image";
import { find } from "lodash";
import * as toasts from "lib/toasts";
import SessionContext from "lib/session";

export function QrCodeModal({ link, run, pollName, onClose }) {
    const session = useContext(SessionContext);
    const [qrCode, setQrCode] = useState("");

    useEffect(() => {
        QRCode.toDataURL(link).then(setQrCode);
    }, [link]);

    async function writeClipboardImg() {
        try {
            // turn DOM element into image blob
            const qrBlob = htmlToImage.toBlob(document.getElementById("qr-complete"));
            // write blob to clipboard
            await navigator.clipboard.write([
                // eslint-disable-next-line no-undef
                new ClipboardItem({
                    "image/png": qrBlob,
                }),
            ]);
        } catch (err) {
            errorCallback(err);
        }
        successCallback();
    }

    // On Success: Close Modal & Show Success Toast: On Error: Show Error Toast
    const successCallback = () => {
        toasts.makeClipboardToast("QR-Code in die Zwischenablage kopiert", "toastQrCode");
        onClose();
    };
    const errorCallback = (msg) => {
        toasts.makeErrorToast(msg, "toastQrCode");
    };

    return (
        <Modal show centered>
            <Modal.Header />
            <Modal.Body>
                <div id="qr-complete" className="d-flex flex-column align-items-center">
                    <span className="font-weight-bold">
                        {
                            find(session.profile.accounts, {
                                id: parseInt(session.accountId),
                            }).name
                        }
                    </span>

                    <img className="qr-code" src={qrCode} alt="qr-code of poll" />

                    <span className="font-weight-bold">{run.title || pollName}</span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary btn-width75" onClick={writeClipboardImg}>
                    QR-Code kopieren
                </Button>
                <Button variant="secondary btn-width75" onClick={onClose}>
                    Abbrechen
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
