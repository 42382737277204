import { useEffect, useState } from "react";
import { MenuGeneric } from "components/Menus";

import * as service from "./api";
import Document from "components/Document";

const selectAll = (linkId) => () => {
    /* Get the text field */
    var copyText = document.getElementById(linkId);

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 999); /* For mobile devices */
};

export const ApplyingPollEval = (props) => <ToolsPollRun {...props} usage="applying" />;
export const CoachingPollEval = (props) => <ToolsPollRun {...props} usage="coaching" />;

const ToolsPollRun = ({ usage, session }) => {
    const [run, setRun] = useState({});
    const [runEval, setRunEval] = useState([]);

    const runId = window.location.href.split("?")[1].split("/")[2];
    const selectedEvalId = window.location.href.split("?")[1].split("/")[3];
    const base = window.location.href.split("?")[0];
    const pollLink = `${base}?${usage}/poll_form/${run.id}/${run.uuid}`;

    useEffect(() => {
        service.loadPollRun(session, runId, setRun, console.error);
    }, [runId, session]);
    useEffect(() => {
        if (!selectedEvalId || !run.poll_id || run.num_identities < 3) {
            return;
        }

        service.loadPollEval(session, selectedEvalId, runId, setRunEval, console.error);
    }, [run.num_identities, run.poll_id, runId, session, selectedEvalId]);

    const header = (
        <div>
            <MenuGeneric active={usage} />
            <a href={usage === "applying" ? `?applying/standardpolls` : `?coaching/coachingpolls`}>
                Zurück zu allen Umfragen
            </a>
        </div>
    );

    if (run.id && run.num_identities < 3) {
        return (
            <div>
                {header}
                <h2>Noch nicht genug Teilnehmer</h2>
                <p>Bitte lade mehr Kolleg:innen dazu ein, an der Umfrage teilzunehmen.</p>
                <div className="input-group mb-3">
                    <input
                        className="form-control"
                        onClick={selectAll(`poll_run_${run.id}`)}
                        id={`poll_run_${run.id}`}
                        type="text"
                        readOnly
                        value={pollLink}
                    />
                </div>
            </div>
        );
    }

    if (!selectedEvalId) {
        return (
            <div>
                {header}
                <div className="pt-3" />
                Keine Auswertung ausgewählt!
            </div>
        );
    }

    if (!run.id || runEval.length === 0) {
        return (
            <div>
                {header}
                <p>Laden...</p>
            </div>
        );
    }

    return (
        <div>
            {header}
            <div className="mb-5">
                <Document content={runEval} />
            </div>
        </div>
    );
};
export default ToolsPollRun;
