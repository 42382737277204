export const locale = "de-DE";

export const translate = (obj, attrName) => {
    let languages = [];

    if (!attrName) {
        attrName = "title";
    }

    if (!obj) {
        return false;
    }

    if (obj.hasOwnProperty("languages")) {
        languages = obj.languages;
    } else if (Array.isArray(obj)) {
        languages = obj;
    } else {
        return false;
    }

    let translation = "";
    // let fallback = "";
    languages.forEach((lang, index) => {
        if (lang.code === "DE") {
            translation = lang[attrName];
        } else if (lang.code === "EN") {
            // fallback = lang[attrName];
        }
    });

    return translation ? translation : "";
};

const isInvalidDate = (date) => new Date(date).toString() === "Invalid Date";

export const formatDate = (date, fallback = "n/a") => {
    const formatter = new Intl.DateTimeFormat(locale, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
    });

    if (isInvalidDate(date)) {
        return fallback;
    }

    const parsed = new Date(date);
    if (parsed.toISOString() === "1970-01-01T00:00:00.000Z") {
        return fallback;
    }

    return formatter.format(parsed);
};

export const formatTimestamp = (timestamp) => {
    const formatter = new Intl.DateTimeFormat(locale, {
        dateStyle: "short",
    });

    if (isInvalidDate(timestamp)) {
        return "n/a";
    }
    return formatter.format(new Date(timestamp));
};
