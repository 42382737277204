import { flatten, map, sortBy } from "lodash";

export const collectContent = (document) => {
    const content = [];

    // Get all content and mark it
    content.push(map(document.content, (item) => ({ ...item, source: "content" })));
    content.push(map(document.files, (item) => ({ ...item, source: "file" })));
    content.push(map(document.videos, (item) => ({ ...item, source: "video" })));
    content.push(map(document.images, (item) => ({ ...item, source: "image" })));
    content.push(map(document.texts, (item) => ({ ...item, source: "text" })));
    content.push(map(document.links, (item) => ({ ...item, source: "link" })));
    content.push(
        map(document.blocks, (item) => ({
            ...item,
            content: collectContent(item),
            source: "block",
        }))
    );
    content.push(map(document.charts, (item) => ({ ...item, source: "chart" })));

    // Sort it by position, which is saved in `pos`
    const sortedContent = sortBy(flatten(content), "pos");

    return sortedContent;
};
