import * as service from "./api";
import { useEffect, useState } from "react";
import { MenuApplying } from "components/Menus";
import HeaderProfile from "components/HeaderProfile";
import GoalModal from "./goal_modal";
import Goal from "./goal";
import { map } from "lodash";
import { userCan } from "lib/permissions";

const ToolsGoals = ({ session, type, title }) => {
    const [goals, setGoals] = useState([]);
    const [createModalActive, setCreateModal] = useState(false);

    useEffect(() => {
        service.loadGoals(session, type, setGoals, console.error);
    }, [session, type]);

    const onChange = () => {
        service.loadGoals(session, type, setGoals, console.error);
        setCreateModal(false);
    };

    return (
        <div>
            <HeaderProfile session={session} />
            <MenuApplying />
            <a href={`${session.accountId}?applying`} className="color-orange">
                Zurück zu den Tools
            </a>

            <div className="card-body">
                {userCan(session, "TOOLS_GOAL_ADD") && (
                    <div className="float-right">
                        <a
                            href="/"
                            className="btn btn-primary mb-2"
                            onClick={(e) => e.preventDefault() || setCreateModal(true)}
                        >
                            {title} hinzufügen
                        </a>
                    </div>
                )}

                <h1>{title}</h1>

                {goals.length === 0 && <p>Es sind noch keine {title} vorhanden.</p>}

                {createModalActive && (
                    <GoalModal onClose={onChange} session={session} defaults={{ type }} />
                )}

                {map(goals, (goal) => (
                    <Goal
                        {...goal}
                        key={goal.id}
                        session={session}
                        onChange={onChange}
                        type={type}
                    />
                ))}
            </div>
        </div>
    );
};

export const ToolsBhag = (props) => <ToolsGoals type="bhag" title="BHAG" {...props} />;
