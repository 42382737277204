import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

/* Example:

    <DeleteConfirmModal
        title="KPI löschen"
        text={ `die KPI ${kpi.name}` }
        onClose={ () => setDeleteModal(false) }
        onDelete={ () => service.deleteKpi(session, kpi.id, onDelete, toasts.makeErrorToast) }
    />
*/
const DeleteConfirmModal = ({ onClose, onDelete, title, text, altText }) => {
    const defaultText = `Möchtest du ${text} wirklich löschen? Alle zugehörigen Daten werden dann unwiderruflich
            ebenfalls gelöscht.`;
    return (
        <Modal show centered>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{altText ? altText : defaultText}</p>
                <p className="alert alert-warning">
                    Diese Aktion kann nicht rückgängig gemacht werden!
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Abbrechen
                </Button>
                <Button variant="primary" onClick={onDelete}>
                    Fortfahren
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default DeleteConfirmModal;
