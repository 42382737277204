import { translate } from "lib/language";
import SessionContext from "lib/session";
import { map } from "lodash";
import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import * as service from "./api";

const EvaluationModal = ({ run, base, usage, onClose }) => {
    const session = useContext(SessionContext);
    const [evals, setEvals] = useState([]);
    useEffect(() => {
        service.loadEvals(session, run.id, setEvals, console.error);
    }, [run.id, session]);

    return (
        <Modal show centered>
            <Modal.Header>
                <Modal.Title>Auswertung wählen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {evals.length === 0 && (
                    <div className="card p-3">Keine Auswertungen vorhanden.</div>
                )}

                <ul className="su-navigation-list">
                    {map(evals, (e) => (
                        <li key={e.id} className="card mb-3" role="button">
                            <a href={`${base}?${usage}/poll_eval/${run.id}/${e.id}`}>
                                {translate(e, "title") || "(kein Name)"}
                            </a>
                        </li>
                    ))}
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary btn-width75" onClick={onClose}>
                    Abbrechen
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default EvaluationModal;
