import React from "react";
import { translate } from "lib/language";
import personWalkingIcon from "styles/images/person-walking-colored.svg";
import personStandingIcon from "styles/images/person-standing-colored.svg";
import personHappyIcon from "styles/images/person-happy-colored.svg";

const calculateBenchmark = (runValue, benchmarkValue) => {
    //inputs are in percent
    let color = "badge badge-pill badge-light";
    let label = "Ihr seid im Durchschnitt";
    let value = 0;

    if (benchmarkValue > runValue) {
        value = Math.round((benchmarkValue / runValue) * 100 - 100);
        label = value + "% unter Durchschnitt";
        color = "font-size-1 badge badge-pill badge-warning";
        if (value > 10) {
            color = "font-size-1 badge badge-pill badge-danger";
        }
    } else if (benchmarkValue < runValue) {
        value = Math.round((runValue / benchmarkValue) * 100 - 100);
        label = value + "% über Durchschnitt";
        color = "font-size-1 badge badge-pill badge-success";
    }

    return { color: color, label: label };
};

const NpsPeople = (chart) => {
    const maxPeople = 10;
    const iconSize = 50;
    const decimals = chart.decimals;

    //Run Values
    const { values: runValues } = chart.data[0];

    const percent_detractors = runValues[0].toFixed(decimals);
    const percent_neutral = runValues[1].toFixed(decimals);
    const percent_promotors = runValues[2].toFixed(decimals);
    const percent_nps = runValues[3].toFixed(decimals);
    const count_detractors = (percent_detractors * maxPeople) / 100;
    const count_promotors = (percent_promotors * maxPeople) / 100;
    const count_neutral = maxPeople - count_promotors - count_detractors;

    //Benchmark Values
    const { values: benchmarkValues } = chart.data[1];

    const benchmark_score = benchmarkValues[3].toFixed(decimals);
    let benchmark_ux = calculateBenchmark(percent_nps, benchmark_score);

    //push images according to count
    let people = [];

    for (let i = 1; i <= parseInt(count_promotors); i++) {
        people.push(
            <img
                key={`promotor ${i}`}
                src={personHappyIcon}
                height={iconSize}
                width={iconSize}
                alt="Promotor"
            />
        );
    }

    for (let i = 1; i <= parseInt(count_neutral); i++) {
        people.push(
            <img
                key={`neutral ${i}`}
                src={personStandingIcon}
                height={iconSize}
                width={iconSize}
                alt="Neutral"
            />
        );
    }

    for (let i = 1; i <= parseInt(count_detractors); i++) {
        people.push(
            <img
                key={`detractor ${i}`}
                src={personWalkingIcon}
                height={iconSize}
                width={iconSize}
                alt="Detractor"
            />
        );
    }

    return (
        <div className="my-3 text-center">
            <h3 className="text-center">{translate(chart, "title")}</h3>
            <div className="my-4">{people}</div>
            <span className="text-center">
                {translate(chart, "caption") !== translate(chart, "title")
                    ? translate(chart, "caption")
                    : null}
            </span>
            <div>
                {percent_promotors}% der Mitarbeiter geben an zufrieden zu sein, {percent_neutral}%
                sind unschlüßig und {percent_detractors}% sind unzufrieden.{" "}
            </div>
            <div className={benchmark_ux.color}>{benchmark_ux.label}</div>
        </div>
    );
};

export default NpsPeople;
