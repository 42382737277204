export function getHeaders(apiKey) {
    const sessionKey = apiKey || window.localStorage.apiKey;

    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    if (sessionKey) {
        headers['Authorization'] = 'Bearer '+sessionKey;
    }

    return headers;
}

export function getFormDataHeaders(apiKey) {
    const sessionKey = apiKey || window.localStorage.apiKey;

    const headers = {
        
    };

    if (sessionKey) {
        headers['Authorization'] = 'Bearer '+sessionKey;
    }

    return headers;
}

const modules = {
    "auth": "https://api.scaleup.de/api:nNrRBg3S:v1.1",
    "toolset": "https://api.scaleup.de/api:sVzRHGGB:v1.1",
    "knowledge": "https://api.scaleup.de/api:Tt_iPEcw:v1.1",
    "admin": "https://api.scaleup.de/api:XMPlHB4n:v1.1",
};

export const getBase = (module_name) => {
    return modules[module_name];
}

/*
Example:

export const loadKpis = (session, onSuccess, onError=console.error ) => {
    const url = `${API_URL}/kpi/account/${session.accountId}`;
    getHelper(session, url, onSuccess, onError);
}
 */
export const getHelper = (_session, url, onSuccess, onError) => {
    fetch(url,
        {
            method: 'GET',
            headers: getHeaders()
        })
        .then(response => response.json())
        .then(data => {
            if(data && data.message) {
                onError(data.message, "GET", url);
            } else {
                onSuccess(data);
            }
        });
}

/*
Example:

export const setNuggetStatus = (session, nuggetId, onSuccess, onError=console.error) => {
    const url = `${API_URL}/nugget/${nuggetId}`;

    postHelper(session, url, {journey_id: journeyId, account_id: session.accountId}, onSuccess, onError);
};
*/
export const postHelper = (_session, url, body, onSuccess, onError) => {
    fetch(url,
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(body),
        })
        .then(response => response.json())
        .then(data => {
            if(data && data.message) {
                onError(data.message, "POST", url);
            } else {
                onSuccess(data);
            }
        });
}

/*
Example:

export const deleteKpi = (session, kpi_id, onSuccess, onError=console.error) => {
    const url = `${API_URL}/kpi/${kpi_id}`;

    deleteHelper(session, url, kpi, onSuccess, onError);
};
*/
export const deleteHelper = (_session, url, onSuccess, onError) => {
    fetch(url,
        {
            method: 'DELETE',
            headers: getHeaders(),
        })
        .then(response => response.json())
        .then(data => {
            if(data && data.message) {
                onError(data.message, "POST", url);
            } else {
                onSuccess(data);
            }
        });
}

export const logHelper = async (_session, url, body) => {
    const response = await fetch(url,
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(body),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data;
}