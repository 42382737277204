import React, { useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import {Button} from "react-bootstrap";

import * as service from "./api.js";
import * as toasts from "lib/toasts";

// Tut: https://www.bezkoder.com/react-hooks-redux-login-registration-example/

const Register = (props) => {
    const form = useRef();
    const errorMessage = props.errorMessage;
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState(props.email || "");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [modal, setModal] = useState(null);

    const onChangeFirstname = (e) => {
        const firstName = e.target.value;
        setFirstName(firstName);
    };

    const onChangeLastname = (e) => {
        const lastName = e.target.value;
        setLastName(lastName);
    };

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const onChangeConfirmPassword = (e) => {
        const confirmPassword = e.target.value;
        setConfirmPassword(confirmPassword);
    };


    const handleSignup = (e) => {
        e.preventDefault();
        
        if (password === confirmPassword) {
            service.registerMagic(firstName, lastName, email, password, makeSignUpInfoModal);
        }
        else toasts.makeErrorToast("Passwörter stimmen nicht überein!", "toastSignup" );
    };

    const makeSignUpInfoModal = () => {
        setModal(
          <Modal show={true} centered>
            <Modal.Header>
              <Modal.Title>Sign-up erfolgreich!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>Herzlich willkommen in der scale up Community - wir freuen uns riesig, dass du dabei bist!</p>
                <p>Damit wir dir den Zugang zu unserem Log-In Bereich freischalten können, benötigen wir noch die Bestätigung deiner E-Mail-Adresse.</p>
                <p>Wir haben dir einen Bestätigungs-Link an {email} gesendet.</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => props.toggleMode("login")}>
                Du kannst dieses Fenster jetzt schließen.
              </Button>
            </Modal.Footer>
          </Modal>
        )
      };

    return (
        <div>
            <div className="d-flex justify-content-center align-items-center" style={{minHeight: "60vh"}}>
                <div className="d-inline-block">
                    <div className="su-border">
                        <form action="/" onSubmit={handleSignup} ref={form} className="card su-form su-login-form p-4 box-shadow">
                            <h1 className="card-header">Sign up</h1>
                            <div className="card-body">
                                <div className="form-group mb-5">
                                    <label htmlFor="first_name">Vorname</label>
                                    <input type="text" name="first_name" onChange={onChangeFirstname} value={firstName} placeholder="Vorname" className="form-control"/>
                                </div>
                                <div className="form-group mb-5">
                                    <label htmlFor="last_name">Nachname</label>
                                    <input type="text" name="last_name" onChange={onChangeLastname} value={lastName} placeholder="Nachname" className="form-control"/>
                                </div>
                                <div className="form-group mb-5">
                                    <label htmlFor="email">E-Mail</label>
                                    <input type="text" name="email" onChange={onChangeEmail} value={email} placeholder="Email" className="form-control"/>
                                </div>    
                                <div className="form-group mb-5">
                                    <label htmlFor="password">Passwort</label>
                                    <input type="password" name="password" onChange={onChangePassword} value={password} placeholder="Passwort eingeben" className="form-control"/>
                                    
                                </div>
                                <div className="form-group mb-5">
                                    <label htmlFor="confirm_password">Passwort bestätigen</label>
                                    <input type="password" name="confirm_password" onChange={onChangeConfirmPassword} value={confirmPassword} placeholder="Passwort bestätigen" className="form-control"/>
                                    <p className="text-muted">Das Passwort sollte mind. 8 Zeichen lang sein und dabei <br/> mind. einen Großbuchstaben, eine Zahl und ein <br/> Sonderzeichen beinhalten.</p>
                                </div>
                                <div className="form-group text-center">
                                    <button type="submit" className="btn btn-primary">Anmelden</button>
                                </div>
                                <div className="form-group text-center">
                                    <a href="/" onClick={(e) => { e.preventDefault(); props.toggleMode("login")}} className="color-light-grey font-size-1 mr-3 cursor-pointer">
                                    Zum Login Formular
                                    </a>
                                </div>
                            </div>
                            {errorMessage && (
                            <p className="mt-3 text-danger">{errorMessage}</p>
                            )}
                        </form>
                        {modal}
                    </div>
                </div>
            </div>
            <div className="py-lg-5"></div>
        </div>
    );
};
export default Register