import { getHeaders, getBase, postHelper, getHelper } from "lib/api";
import * as toasts from "lib/toasts";

const API_URL = getBase("toolset");
const ADMIN_URL = getBase("admin");

export const loadPolls = (session, usage, onSuccess = () => {}, onError = () => {}) => {
    const url = `${API_URL}/poll/account/${session.accountId}/usage/${usage}`;

    fetch(url, {
        method: "GET",
        headers: getHeaders(),
    })
        .then((response) => response.json())
        .then((data) => {
            if (data.message) {
                onError(data.message, "GET", url);
            } else {
                onSuccess(data);
            }
        });
};

export const loadPollRuns = (session, usage, onSuccess = () => {}, onError = () => {}) => {
    const url = `${API_URL}/poll_run/account/${session.accountId}/usage/${usage}`;

    fetch(url, {
        method: "GET",
        headers: getHeaders(),
    })
        .then((response) => response.json())
        .then((data) => {
            if (data.message) {
                onError(data.message, "GET", url);
            } else {
                onSuccess(data);
            }
        });
};

export const loadTeam = (session, onSuccess = () => {}, onError = () => {}) => {
    fetch(`${ADMIN_URL}/account/${session.accountId}/user`, {
        method: "GET",
        headers: getHeaders(),
    })
        .then((response) => response.json())
        .then((data) => {
            if (data.message) {
                onError(data.message);
            } else {
                onSuccess(data);
            }
        });
};

export const createPollRun =
    (session, usage, pollId, pollName, setPollRuns, newPollRun) => async (e) => {
        e.preventDefault();
        toasts.makeLoadingToast("Neue Umfrage starten...", "toastCreatePollRun");
        const response = await fetch(`${API_URL}/poll_run`, {
            method: "POST",
            headers: getHeaders(),
            body: JSON.stringify({
                poll_id: pollId,
                account_id: session.accountId,
                created_by: session.profile.id,
                begin_at: new Date().toISOString().slice(0, 10),
                title: pollName,
            }),
        });
        const data = await response.json();

        if (data.message) {
            //return window.alert(data.message);
            toasts.makeErrorToast(JSON.stringify(data.message), "toastCreatePollRun");
        }
        toasts.makeSuccessToast("Neue Umfrage gestartet", "toastCreatePollRun");
        newPollRun.current = true;
        loadPollRuns(session, usage, setPollRuns, console.error);
    };

export const updatePollRun = (
    session,
    pollRunId,
    name,
    startDate,
    endDate,
    onSuccess = () => {},
    onError = () => {}
) => {
    const url = `${API_URL}/poll_run/${pollRunId}`;

    postHelper(
        session,
        url,
        { title: name, begin_at: startDate, end_at: endDate },
        onSuccess,
        onError
    );
};

export const deletePollRun = (session, pollRunId, onSuccess = () => {}, onError = () => {}) => {
    toasts.makeLoadingToast("Umfrage löschen...", "toastDeletePollRun");
    fetch(`${API_URL}/poll_run/${pollRunId}`, {
        method: "DELETE",
        headers: getHeaders(),
    })
        .then((response) => response.json())
        .then((data) => {
            if (data) {
                onError(data);
                toasts.makeErrorToast(JSON.stringify(data), "toastDeletePollRun");
            } else {
                onSuccess(data);
                toasts.makeSuccessToast("Umfrage gelöscht", "toastDeletePollRun");
            }
        });
};

export const loadEvals = (session, pollRunId, onSuccess = () => {}, onError = () => {}) => {
    const url = `${API_URL}/poll_run/evals/${pollRunId}`;

    getHelper(session, url, onSuccess, onError);
};
