import { userCan } from 'lib/permissions';
import SessionContext from 'lib/session';

export const MenuGeneric = ({ active }) => (
    <SessionContext.Consumer>{session => (
        <ul className="nav nav-tabs mb-4">
            { userCan(session, "LEARNING_ACCESS") && (
                <li className="nav-item">
                    <a className={ `nav-link${ active === "learning" ? " active" : ""}` } href="?learning">
                        Lernen
                    </a>
                </li>
            ) }
            { userCan(session, "APPLYING_ACCESS") && (
                <li className="nav-item">
                    <a className={ `nav-link${ active === "applying" ? " active" : ""}` } href="?applying">
                        Anwenden
                    </a>
                </li>
            ) }
            { userCan(session, "COACHING_ACCESS") && (
                <li className="nav-item">
                    <a className={ `nav-link${ active === "coaching" ? " active" : ""}` } href="?coaching">
                        Coachen
                    </a>
                </li>
            ) }
        </ul>
    )}</SessionContext.Consumer>
);
export const MenuApplying = () => (
    <MenuGeneric active="applying" />
)
export const MenuCoaching = () => (
    <MenuGeneric active="coaching" />
)
export const MenuLearning = () => (
    <MenuGeneric active="learning" />
)