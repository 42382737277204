import * as service from "./api";
import { useEffect, useState } from 'react';
import { translate } from "lib/language";
import { MenuLearning } from "components/Menus";
import HeaderProfile from 'components/HeaderProfile';

const LearningDashboard = ({ session }) => {
    const [journeys, setJourneys] = useState();

    useEffect(() => {
        service.loadJourneys(session, setJourneys, console.error);
    }, [session]);
    
    if (journeys === undefined) {
        return <>Laden...</>;
      }

    const handleLog = async (e, session, journeyId) => {
        e.preventDefault();
        await service.logJourneyOpened(session, journeyId)
        // do something else here after log completes
        window.location.href = `?learning/journey/${journeyId}`;
    }

    return (
        <div>
            <HeaderProfile session={session} />
            <MenuLearning />
            <div className="row d-flex mt-4 ml-0 mr-0 pt-3">
                { journeys.map(journey => (
                    <div className="col-12 col-lg-4 col-md-6 d-flex mb-5" key={ journey.id }><a
                        className="card su-journey su-journey-list"
                        style={{ width: '18rem' }}
                        href={`?learning/journey/${journey.id}`}
                        onClick={(e) => handleLog(e, session, journey.id)}
                         
                    >
                        <img className="card-img-top" src={ journey.thumbnail && journey.thumbnail.url } alt="" />
                        <div className="su-card-hover">
                            <button className="btn btn-primary">Journey öffnen</button>
                        </div>
                        <div className="card-header">
                            <div className="su-header-meta">Learning Journey</div>
                            { translate(journey, "title") }
                        </div>
                        { journey.num_nuggets && (
                            <div className="card-body pt-0 row align-items-end ml-0 mr-0">
                                <div className="su-progress-bar col-12 pl-0 pr-0">
                                    <div className="su-progress-bar-bg" style={{ width: `${journey.num_nuggets_checked / journey.num_nuggets * 100}%` }}></div>
                                    <div className="su-progress-bar-data">
                                        <div>{ journey.num_nuggets_checked }/{ journey.num_nuggets } Nuggets</div>
                                        <div>{ Math.ceil(journey.num_nuggets_checked / journey.num_nuggets * 100) }%</div>
                                    </div>
                                </div>
                            </div>
                        ) }
                    </a></div>
                ))}
                { journeys.length === 0 && (
                    <div className="col-12">
                        <div className="alert alert-dark text-center">
                            { `Aktuell stehen keine Learning Journeys zur Verfügung.` }
                        </div>
                    </div>
                ) }
            </div>
        </div>
    )
}
export default LearningDashboard;