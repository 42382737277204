import * as service from "./api";
import { useEffect, useState } from 'react';
import { translate } from "lib/language";
import { MenuGeneric } from "components/Menus";
import HeaderProfile from 'components/HeaderProfile';
import { Modal, Button } from "react-bootstrap";


export const ApplyingDashboard = (props) => (
    <ToolsDashboard
        { ...props }
        usage="applying"
    />
)
export const CoachingDashboard = (props) => (
    <ToolsDashboard
        { ...props }
        usage="coaching"
    />
)

const ToolsDashboard = ({ usage, session }) => {
    const [tools, setTools] = useState([]);
    //const [newTeam, setNewTeam] = useState([]);
    const [modal, setModal] = useState("");

    useEffect(() => {
        service.loadTools(session, usage, setTools, console.error);

        //setNewTeam(JSON.parse(sessionStorage.getItem('newTeam')));
        if (sessionStorage.getItem('newTeam') !== null) {
            setModal(
                <Modal show={true} centered>
                    <Modal.Header>
                    <Modal.Title>Neues Team erstellt</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <p>Das neue Team <span className="color-orange">{JSON.parse(sessionStorage.getItem('newTeam')).name}</span> wurde erfolgreich erstellt.</p>
                            <p>Du bist der Team Owner.</p>
                            <p>Im Team-Editor kannst du Teammitglieder einladen und Rollen vergeben.</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            sessionStorage.removeItem('newTeam');
                            setModal()}}>
                        Schließen
                    </Button>
                    <Button
                        href={session.accountId + '?team-editor'}
                        variant="primary"
                        onClick={() => {
                            sessionStorage.removeItem('newTeam');
                            setModal()}}>
                        Team verwalten
                    </Button>
                    </Modal.Footer>
                </Modal>
            );
        };
    }, [session, usage]);
    
    return (
        <div>
            <HeaderProfile session={session} />
            <MenuGeneric active={ usage } />

            <div className="row d-flex">
                { tools.map(tool => (
                    <div className={tools.length <= 2 ? "col-12 col-md-6 col-lg-4 mb-3 d-flex" : tools.length === 3 ? "col-12 col-md-4 mb-3 d-flex" : "col-12 col-md-3 mb-3 d-flex"} key={ tool.name }>
                        <a className="card" href={`?${usage}/${tool.name}`}>
                            <div className="card-body">
                                <h4 className="card-title text-truncate d-block">{ translate(tool, "title") }</h4>
                                <p className="card-text">{ translate(tool, "description") }</p>
                            </div>
                        </a>
                    </div>
                ))}
            </div>
            {modal}
        </div>
    )
}
export default ToolsDashboard