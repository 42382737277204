import { formatTimestamp } from "lib/language";
import { FaTrashAlt } from "react-icons/fa";
import * as service from "./api";
import { useEffect, useState, useRef } from "react";
import HeaderProfile from "components/HeaderProfile";
import { userCan } from "lib/permissions";
import * as toasts from "lib/toasts";
import DeleteConfirmModal from "components/DeleteConfirmModal";

const AccountEditor = ({ session }) => {
    const [accountData, setAccountData] = useState({});
    const [accountName, setAccountName] = useState("");
    const [domains, setDomains] = useState("");
    const [numEmployees, setNumEmployees] = useState("");
    const [modal, setModal] = useState(null);
    const formAccountEdit = useRef();

    useEffect(() => {
        service.loadAccountData(session, setAccountData, console.error);
    }, [session]);

    if (!accountData.id) {
        return "Laden...";
    }

    const handleSave = (e) => {
        e.preventDefault();

        //if name not null => save new user data
        if (accountData.name !== "") {
            const payload = {
                name: accountName,
                domains: domains,
                num_empolyees: numEmployees,
            };
            service.updateAccountData(session, payload, setAccountData, console.error);
        } else {
            toasts.makeErrorToast("Eingabe prüfen", "toastUpdate");
        }
    };

    const makeDeleteAccountModal = () => {
        setModal(
            <DeleteConfirmModal
                title="Account löschen"
                text={`den Account ${accountData.name}`}
                onClose={() => setModal()}
                onDelete={() => {
                    service.deleteAccount(session, setAccountData, console.error);
                    setModal();
                }}
            />
        );
    };

    const readOnly = !userCan(session, "ACCOUNT_EDIT");

    return (
        <div>
            <HeaderProfile session={session} newTeamData={accountData} />
            <div className="container">
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a className="nav-link active" href="?account-editor">
                            Account editieren
                        </a>
                    </li>
                    {userCan(session, "ACCOUNT_APIKEY_ACCESS") && (
                        <li className="nav-item">
                            <a className="nav-link" href={session.accountId + "?apikey-editor"}>
                                API-Keys verwalten
                            </a>
                        </li>
                    )}
                </ul>
            </div>
            <div className="row" id="su-account-editor">
                <form
                    action="/"
                    onSubmit={handleSave}
                    ref={formAccountEdit}
                    className="su-form p-4"
                >
                    <div className="card-body">
                        <div className="form-group mb-4">
                            <label>Account erstellt am</label>
                            <p>{formatTimestamp(accountData.created_at)}</p>
                        </div>

                        <div className="form-group mb-4">
                            <label htmlFor="name">Account Name</label>
                            <input
                                readOnly={readOnly}
                                type="text"
                                name="name"
                                onChange={(e) => setAccountName(e.target.value)}
                                value={accountName || accountData.name}
                                placeholder="Neuen Account Namen eingeben"
                                className="form-control"
                            />
                        </div>

                        <div className="form-group mb-4">
                            <label>Account Owner</label>
                            <p>{`${accountData.owner.firstname} ${accountData.owner.lastname}`}</p>
                        </div>

                        <div className="form-group mb-4">
                            <label htmlFor="domains">Domains</label>
                            <input
                                readOnly={readOnly}
                                type="text"
                                name="domains[]"
                                onChange={(e) => setDomains(e.target.value)}
                                value={domains || accountData.domains}
                                placeholder="Neue Domain eingeben"
                                className="form-control"
                            />
                        </div>

                        <div className="form-group mb-4">
                            <label htmlFor="domains">Anzahl Mitarbeitende</label>
                            <input
                                readOnly={readOnly}
                                type="number"
                                name="num_empolyees"
                                value={numEmployees || accountData.num_empolyees}
                                onChange={(e) => setNumEmployees(e.target.value)}
                                placeholder="Anzahl Mitarbeitende eingeben"
                                className="form-control"
                            />
                        </div>
                        {!readOnly && (
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary w-100">
                                    Änderungen speichern
                                </button>
                            </div>
                        )}

                        {userCan(session, "ACCOUNT_DELETE") && (
                            <div className="form-group">
                                <button
                                    type="button"
                                    className="btn btn-delete btn-w-icon w-100"
                                    onClick={makeDeleteAccountModal}
                                >
                                    Account löschen
                                    <FaTrashAlt className="icon-right" />
                                </button>
                            </div>
                        )}
                    </div>
                </form>

                {modal}
            </div>
        </div>
    );
};

export default AccountEditor;
