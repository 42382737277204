import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { translate } from 'lib/language';
import helpIcon from 'styles/images/help.svg';
import personWalkingIcon from 'styles/images/person-walking-colored.svg';
import personStandingIcon from 'styles/images/person-standing-colored.svg';
import personHappyIcon from 'styles/images/person-happy-colored.svg';

import GaugeChart from "../charts/gauge";
import RadarChart from "../charts/radar";

const EmployeeHappiness = ({ runStats, pollStats }) => {
    const poll_items = runStats.poll_items;
    const poll_subgroups = runStats.poll_subgroups;
    const benchmark_items = pollStats.poll_items;
    const benchmark_subgroups = pollStats.poll_subgroups;
    
    const helpText = ( text ) => {
        const renderTooltip = (props) => (
            <Tooltip id="button-tooltip" {...props}>
                {text}
            </Tooltip>
        );

        return (
            <OverlayTrigger
                placement="auto"
                delay={{ show: 150, hide: 400 }}
                overlay={renderTooltip}
                >
                <img src={ helpIcon } width="14" height="14" alt=""/>
            </OverlayTrigger>
        );
    }

    const calculateBenchmark = ( teamValue, benchmarkValue ) => {
        let color = "badge badge-pill badge-light";
        let label = "Ihr seid im Durchschnitt";
        let value = 0;

        if(benchmarkValue > teamValue) {
            value = Math.round( (benchmarkValue/teamValue*100) - 100);
            label = value + "% unter Durchschnitt";
            color = "font-size-1 badge badge-pill badge-warning";
            if(value > 10) {
                color = "font-size-1 badge badge-pill badge-danger";
            }
        } else if(benchmarkValue < teamValue) {
            value = Math.round( (teamValue/benchmarkValue*100) - 100 );
            label = value + "% über Durchschnitt";
            color = "font-size-1 badge badge-pill badge-success";
        }

        return { color: color, label: label };
    }

    const prepareNPS = ( attributeName, iconSize ) => {
        const maxPeople = parseInt(poll_items[attributeName].poll_item_count) > 10 ? 10 : parseInt(poll_items[attributeName].poll_item_count);

        const average_score = poll_items[attributeName].poll_item_nps;
        const benchmark_score = benchmark_items[attributeName] ? benchmark_items[attributeName].poll_item_nps : 0;
        const percent_promotors = poll_items[attributeName].poll_item_promotors_percent;
        const percent_detractors = poll_items[attributeName].poll_item_detractors_percent;
        const percent_neutral = 100 - percent_promotors - percent_detractors;
        const count_promotors = percent_promotors * maxPeople / 100;
        const count_detractors = percent_detractors * maxPeople / 100;
        const count_neutral = maxPeople - count_promotors - count_detractors;

        let icons = [];

        for(let i = 1; i <= parseInt(count_promotors); i++) {
            icons.push( <img src={ personHappyIcon } height={iconSize} width={iconSize} alt="Promotor" /> );
        }

        for(let i = 1; i <= parseInt(count_neutral); i++) {
            icons.push( <img src={ personStandingIcon } height={iconSize} width={iconSize} alt="Neutral" /> );
        }

        for(let i = 1; i <= parseInt(count_detractors); i++) {
            icons.push( <img src={ personWalkingIcon } height={iconSize} width={iconSize} alt="Detractor" /> );
        }

        return {
            percent_promotors: percent_promotors,
            percent_neutral: percent_neutral,
            percent_detractors: percent_detractors,
            icons: icons,
            average_score: average_score,
            benchmark_score: benchmark_score
        }
    }

    const renderNPS = ( attributeName ) => {
        if(!poll_items[attributeName]) { return "" }

        let data = prepareNPS( attributeName, 50 );
        let benchmark_ux = calculateBenchmark( data.average_score, data.benchmark_score);

        return <div className="mb-5 text-center">
            <div className="my-3">{data.icons}</div>
            <div>{data.percent_promotors.toFixed(2)}% der Mitarbeiter geben an zufrieden zu sein, {data.percent_neutral.toFixed(2)}% sind unschlüßig und {data.percent_detractors.toFixed(2)}% sind unzufrieden <a href="https://en.wikipedia.org/wiki/Net_promoter_score" target="_blank" rel="noreferrer" title="Kategorisierung gemäß Net-Promotor-Score (NPS) Methodik"><img src={ helpIcon } width="14" height="14" alt="Help" /></a></div>
            <div className={benchmark_ux.color}>{benchmark_ux.label}</div>
        </div>;
    }

    const renderSubgroupGauges = () => {
        let subgroups = Object.keys(poll_subgroups);

        if(subgroups.length === 0) { return "" }
        
        const renderSubgroupGauge = (subgroupKey) => {
            let subgroup = poll_subgroups ? poll_subgroups[subgroupKey] : {};
            let benchmark = benchmark_subgroups ? benchmark_subgroups[subgroupKey] : {};
            let score = ((subgroup.poll_subgroup_average) / 5);
            let data = [
                {id: 'Score', value: score, color: 'orange'},
                {id: 'Rest', value: 1-score, color: '#f3f3f3'}
            ];
            let benchmark_ux = calculateBenchmark( subgroup.poll_subgroup_average, benchmark.poll_subgroup_average);

            return (
                <div className="col-12 col-lg-3 text-center mb-5">
                    <div style={{display: "inline-block", width: "220px", height: "150px"}}>
                        <GaugeChart data={data} score={((subgroup.poll_subgroup_average/5)*100).toFixed(2)+"%"} />
                    </div><br/>
                    <b>{translate(subgroup) || subgroupKey}</b><br/>
                    <span className={benchmark_ux.color}>{benchmark_ux.label}</span>
                </div>
            )
        }

        return (
            <div className="row">{ subgroups.map(renderSubgroupGauge) }</div>
        )
    }

    const renderNpsGauges = () => {

        let nps = ['leadershipsatisfaction', 'effectiveness', 'headhunter'];
        let gauges = nps.map((attributeName, index) => {
        let average_score = poll_items[attributeName].poll_item_nps;
        let benchmark_score = poll_items[attributeName] ? poll_items[attributeName].poll_item_nps : 0;
        let benchmark_ux = calculateBenchmark( average_score, benchmark_score);
        let percent_promotors = poll_items[attributeName].poll_item_promotors_percent / 100;
        let percent_detractors = poll_items[attributeName].poll_item_detractors_percent / 100;
        let percent_neutral = 1 - percent_promotors - percent_detractors;
        let data = [
            {id: 'Detraktoren', value: percent_detractors, color: 'red'},
            {id: 'Indifferente', value: percent_neutral, color: '#f3f3f3'},
            {id: 'Promoter', value: percent_promotors, color: 'green'}
        ];
        return <div className="col-12 col-lg-4">
                <div style={{display: "inline-block", width: "220px", height: "150px"}}>
                    <GaugeChart data={data} score={"NPS: "+average_score.toFixed(2)} />
                </div><br/>
                <b>{translate(poll_items[attributeName]) || attributeName}</b> {helpText(translate(poll_items[attributeName], "description"))}<br/>
                <span className={benchmark_ux.color}>{benchmark_ux.label}</span>
                </div>;
        });

        return <div className="row">{gauges}</div>
    }

    const renderRadar = () => {
        let data = [];

        Object.keys(poll_items).forEach( (poll_item_key, index) => {
            let poll_item = poll_items[poll_item_key];
            let benchmark = benchmark_items ? benchmark_items[poll_item_key] : {};
            let question = translate(poll_item, "caption");
            if(poll_item.poll_subgroup && poll_item.poll_subgroup.name) {
                data.push( {
                    name: question,
                    Ihr: poll_item.poll_item_average,
                    Benchmark: benchmark.poll_item_average
                } );
            }
        } );

        return <div className="row">
            <div className="col-12 text-center mb-5">
                <div style={{display: "inline-block", width: "1100px", height: "600px"}}>
                    <RadarChart data={ data } keys={ ["Benchmark", "Ihr"] } />
                </div>
            </div>
        </div>;
    }

    const renderStar = (index, selectedValue) => {
        let color = "black";

        if(selectedValue >= index) {
            color = "orange";
        }

        return <span className="mr-3" style={{color: color, border:0, backgroundColor:'transparent'}}>
            <svg height="20" width="20">
                <circle cx="10" cy="10" r="5" stroke={color} strokeWidth="2" fill={color === "orange" ? "orange" : "white"} />
            </svg>
        </span>;
    }

    const renderStars = (averageValue, maxValue) => {
        let stars = [];
        for(let i = 1; i <= parseInt(maxValue); i++) {
            stars.push( renderStar(i, averageValue) );
        }

        return <div>{stars}</div>;
    }

    const renderRatingsDefaultView = () => {
        let stars = [];

        Object.keys(poll_items).forEach( (poll_item_key, index) => {
            let poll_item = poll_items[poll_item_key];
            if(poll_item.poll_subgroup && poll_item.poll_subgroup.name) {
                stars.push( <div className="row mb-4 align-items-center">
                        <div className="col-12 col-lg-6 text-lg-left">{translate(poll_item, "caption")}</div>
                        <div className="col-12 col-lg-6">{renderStars(poll_item.poll_item_average, parseInt(poll_item.range[1]) )}</div>
                    </div>
                );
            }
        } );

        return <div className="mb-5">
                {stars}
        </div>;
    }


    if(!poll_items) {
        return (
            <div>Loading...</div>
        );
    }

    return <div className="text-center">

        <h2>Mitarbeiter Zufriedenheit</h2>
        {renderNPS("jobsatisfaction")}

        <hr className="my-5" />

        <h3 className="mb-3">Stimmung</h3>
        {renderNpsGauges()}

        <hr className="my-5" />

        <h3 className="mb-3">Unternehmens-Bewertung</h3>
        <p>Als Benchmark verwenden wir die Antworten aller teilnehmender Teams</p>
        {renderSubgroupGauges()}

        <hr className="my-5" />

        <h3 className="mb-3">Detailauswertung</h3>
        <div className="d-none d-lg-block">
            {renderRadar()}
        </div>
        <div className="d-lg-none">
            {renderRatingsDefaultView()}
        </div>
    </div>;
}
export default EmployeeHappiness;