import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import Table from "components/Table";
import { translate } from "lib/language";
import HeaderProfile from 'components/HeaderProfile';

import { InviteUserModal } from "./modal-invite-user";
import { RenewInviteUserModal } from "./modal-renew-invite-user";

import * as service from "./api";
import { useEffect, useState } from 'react';
import SessionContext from 'lib/session';
import { userCan } from 'lib/permissions';
import { find } from 'lodash';
import DeleteConfirmModal from 'components/DeleteConfirmModal';


const TeamEditor = ({ session }) => {
    const [modal, setModal] = useState("");
    const [team, setTeam] = useState([]);
    const [roles, setRoles] = useState([]);

    const canRolesEdit = userCan(session, "TEAM_ROLES_EDIT")
    const canOwnerEdit = userCan(session, "TEAM_OWNER_EDIT")
    const canUsersAdd = userCan(session, "TEAM_USER_ADD")
    const canUsersDelete = userCan(session, "TEAM_USER_DELETE")

    useEffect(() => {
        service.loadTeam(session, setTeam, console.error);
        service.loadRoles(session, setRoles, console.error);
    }, [session]);

    const getUser = (userId) => {
        return find(team, {id: userId})
    }

    const getNameOfUser = (userId) => {
        let name;
        const user = getUser(userId);
        if(user) {
            name = user.firstname+" "+user.lastname;
        }
        else {
            name = "n/a"
        }
        return name;
    }

    const getRolesOfUser = (userId) => {
        const roles = [];
        team.forEach((member, index) => {
            if(member.id === userId) {
                member.roles.forEach((role, roleIndex) => {
                    roles.push( role.role_id );
                });
            }
        });
        return roles;
    }

    const closeModal = () => {
        setModal("");
    }


    const addUserModal = (e) => {
        e.preventDefault()
        const successCallback = () => {
            service.loadTeam(session, setTeam, console.error);
            closeModal();
        };

        setModal(
            <InviteUserModal accountId={session.accountId} cancelCallback={() => { closeModal(); }} successCallback={successCallback} />
        );
    }

    const makeOwnerModal = (userId) => {

        const name = getNameOfUser(userId);

        const modal = <Modal show={true} centered>
            <Modal.Header>
                <Modal.Title>Ownership übertragen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Möchtest du {name} wirklich den Account übertragen? Du verlierst mit dieser Aktion die Owner-Berechtigungen.</p>
                <div className="alert alert-warning">Diese Aktion kann nicht rückgängig gemacht werden</div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setModal()}>
                    Abbrechen
                </Button>
                <SessionContext.Consumer>{session => (
                    <Button variant="primary" onClick={(e) => { service.changeAccountOwner(session, userId, setTeam); setModal("")}}>
                        Fortfahren
                    </Button>
                ) }</SessionContext.Consumer>
            </Modal.Footer>
        </Modal>;

        setModal(modal);
    }

    const makeRoleModal = (userId) => {
        const name = getNameOfUser(userId);

        const userRoles = getRolesOfUser(userId);

        const modal = <Modal show={true} centered>
            <Modal.Header>
                <Modal.Title>Rollen verwalten</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Bitte wähle die Zugriffsrechte von {name} für dieses Team.</p>
                {roles.map((role, index) => {
                    return <div key={"role_form_"+role.id} className="d-flex align-items-center mb-2">
                            <input className="mr-2" type="checkbox" name="role_ids[]" defaultChecked={userRoles.includes(role.id)} value={role.id} id={"role_"+role.id} onChange={(e) => {
                                if(e.target.checked) {
                                    userRoles.push(parseInt(e.target.value)); 
                                    //Ids in Integer umwandeln und dem Array hinzufügen
                                } else {
                                    const position = userRoles.indexOf(role.id)
                                    userRoles.splice(position, 1);
                                    //jeweils den Index der geänderten Checkbox suchen und dann Wert aus dem Array entfernen

                                    /*userRoles.filter(function(value, index, arr){
                                        return e.target.checked === false;
                                    });*/
                                }
                            }} />
                            <label htmlFor={"role_"+role.id} className="m-0">{translate(role)}</label>
                        </div>;
                })}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={(e) => setModal("")}>
                    Abbrechen
                </Button>
                <SessionContext.Consumer>{session => (
                    <Button variant="primary" onClick={(e) => {
                        service.updateRolesOfUser(session, userId, userRoles, setTeam);
                        setModal("");
                    }}>
                        Fortfahren
                    </Button>
                ) }</SessionContext.Consumer>
            </Modal.Footer>
        </Modal>;

        setModal(modal);
    }

    const renderRowActions = ( session ) => ( userId ) => {
        const renewInviteModal = (userId) => {
            const user = getUser(userId);
            setModal(<RenewInviteUserModal user={user} session={ session } accountId={session.accountId} cancelCallback={() => { closeModal(); }} />);
        }

        const deleteUserModal = (userId) => {
            const user = getUser(userId);
            const onDeleteUser = () => {
                service.loadTeam(session, setTeam, console.error);
                closeModal();
            };

            setModal((
                <DeleteConfirmModal
                    title="Teammitglied entfernen"
                    text={ `${user.firstname} ${user.lastname}` }
                    onClose={ onDeleteUser }
                    onDelete={ () => {
                    service.removeUserFromAccount(session, user.id, onDeleteUser)
                    setModal()
                    } }
                />
            ));
        }

        const actions = [];
        const member = find(team, { id: userId })

        if(canRolesEdit) {
            actions.push( { icon: 'admin', title: 'Benutzer-Rechte editieren', callback: makeRoleModal } );
        }
        if(canOwnerEdit) {
            actions.push( { icon: 'owner', title: 'Benutzer zum Owner machen', callback: makeOwnerModal } );
        }
        if(!member.verified) {
            actions.push( { icon: 'mail', title: 'Invite-Mail erneut schicken', callback: renewInviteModal } );
        }
        if(canUsersDelete) {
            actions.push( { icon: 'delete', title: 'Benutzer löschen', callback: deleteUserModal } );
        }

        return actions;
    }

    const fields = ['firstname', 'lastname', 'email', 'roles', 'created_at']
    if (!canRolesEdit) {
        delete fields[3]
    }

    return (
        <div>
            <HeaderProfile session={session} />
            <ul className="nav nav-tabs mb-3">
                <li className="nav-item">
                    <a className="nav-link active" href="?team-editor">
                        Team verwalten
                    </a>
                </li>
            </ul>
            <div id="su-team-editor">
                { canUsersAdd && (
                    <div className='d-flex justify-content-end'>
                    <a href="/" className="btn btn-primary mb-2" onClick={addUserModal}>Neues Teammitglied hinzufügen</a>
                    </div>
                ) }
                <Table data={team} fields={ fields } actionsCallback={renderRowActions(session)} />
                
                {modal}
            </div>
        </div>
    );

}
export default TeamEditor;