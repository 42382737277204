import Header from 'components/Header';
import { useState, useEffect } from 'react';

import * as service from './api';

const AuthMagicLink = () => {
    const [error, setError] = useState(null);

    // Read invite API key from URL: http://localhost:3000/225?auth/link#eyJh...
    const hasMagicToken = window.location.hash && window.location.hash.length > 1;

    useEffect(() => {
        if (hasMagicToken) {
            const magicKey = window.location.hash.substring(1);

            service.magicLinkLogin(magicKey, (data) => {
                // persist api key
                window.localStorage.setItem("apiKey", data.authToken);

                // Reload page
                window.location.href = "/";
            }, (error) => {
                console.error("Received error", error);
                setError(`Magic link login error: ${error}`);
            })
        }
    }, [hasMagicToken]);

    return (
      <div className="py-3 py-lg-4"><div className="container">
        <Header />
        { error ? error : (
            <p>Redeeming link..</p>
        )}
      </div></div>
    );
}
export default AuthMagicLink;