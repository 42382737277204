import { filter, map } from "lodash";
import { useRef, useState } from "react";

import IconEdit from "styles/images/edit.svg";
import IconDelete from "styles/images/delete.svg";
import IconNone from "styles/images/interval_none.svg";
import IconDots from "styles/images/settings.svg";
import { useOutsideAlerter } from "lib/outside_alerter";
const icons = {
    edit: IconEdit,
    delete: IconDelete,
    none: IconNone,
};

const ActionsMenu = ({ actions, className, width }) => {
    const [active, setActive] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => setActive(false));

    return (
        <div className={className} ref={wrapperRef} style={{ zIndex: 999 }}>
            <img
                width="30"
                height="30"
                role="button"
                alt="Bearbeiten"
                src={IconDots}
                onClick={() => setActive(!active)}
            />

            {active && (
                <div
                    className="position-absolute border rounded bg-light px-3 pt-2"
                    style={{ top: 0, right: 40, width: width || "150px" }}
                >
                    {filter(actions).length === 0 && (
                        <span className="d-block mb-2">
                            Es stehen keine Aktionen zur Verfügung.
                        </span>
                    )}
                    {map(filter(actions), (action) => (
                        <span
                            role="button"
                            className="d-block mb-2"
                            onClick={action.onClick}
                            key={action.text}
                        >
                            <img
                                width="18"
                                height="18"
                                alt={action.text}
                                src={icons[action.icon]}
                                className="mr-2 align-text-top"
                            />
                            {action.text}
                        </span>
                    ))}
                </div>
            )}
        </div>
    );
};
export default ActionsMenu;
