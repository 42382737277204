import { toast } from 'react-hot-toast';
/*
always use an ID for a toast to prevent duplicates and to be able to update it
toast.dismiss(toastId); -> dismisses toast with ID
toast.dismiss() -> dismisses all toasts

Boilerplate for update toasts (API-Requests):

toasts.makeLoadingToast('Daten speichern...', "toastUpdate");
toasts.makeErrorToast((JSON.stringify(data.message)), "toastUpdate");
toasts.makeSuccessToast("Änderungen erfolgreich gespeichert", "toastUpdate");
*/
export const dismiss = (toastId) => {
    toastId ? toast.dismiss(toastId) : toast.dismiss();
}

//define toast types
export const makeSuccessToast = (text, toastId) => {
  toast.success(text, {
    id: toastId,
    position: 'top-right',
    duration: 4000,
  });
};

export const makeErrorToast = (text, toastId) => {
  toast.error(text, {
    id: toastId,
    position: 'top-right',
    duration: 6000,
  });
};

export const makeLoadingToast = (text, toastId) => {
  toast.loading(text, {
    id: toastId,
    position: 'top-right',
  });
};

export const makeClipboardToast = (text, toastId) => {
  toast(text, {
    id: toastId,
    position: 'top-right',
    duration: 3000,
    icon: '📋',
  });
};

//custom toast with dismiss button, to delete default styles use toast.custom
/*toast(
    (t) => (
      <span>
        Custom and <b>bold</b>
        <button onClick={() => toast.dismiss(t.id)}>Dismiss</button>
      </span>
    ),
    {
      icon: <Icon />,
    }
  );*/
