import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import * as toasts from 'lib/toasts';

import * as service from "./api";
import { useState } from 'react';

export const GoalModal = ({ kpiId, kpiUnit, onClose, session, defaults, goalId, mode }) => {
    // const [title, setTitle] = useState("")
    // const [description, setDescription] = useState("")
    const [start_at, setStartAt] = useState(defaults.start_at || "")
    const [end_at, setEndAt] = useState(defaults.end_at || "")
    const [least_value, setLeastValue] = useState(defaults.least_value || "")
    const [value, setValue] = useState(defaults.value || "")
    const [best_value, setBestValue] = useState(defaults.best_value || "")

    const onSave = () => {
        const payload = {
            account_id: session.accountId,
            kpi_id: kpiId,
            // title,
            // description,
            start_at: start_at,
            end_at: end_at,
            least_value,
            value,
            best_value,
        }

        if (mode === "edit") {
            service.updateKpiGoal(session, goalId, payload, onClose, toasts.makeErrorToast)
        }
        else {
            service.createKpiGoal(session, payload, onClose, toasts.makeErrorToast)
        }
    }

    return (
        <Modal show centered>
            <Modal.Header>
                <Modal.Title>Zielwert</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <div className="form-group su-form">
                        { /*<input className="mb-4" type="text" onChange={ (e) => setTitle(e.target.value) } value={ title } placeholder="Titel" />*/ }
                        { /*<input className="mb-4" type="text" onChange={ (e) => setDescription(e.target.value) } value={ description } placeholder="Name" />*/ }
                        <label>Beginn</label>
                        <input className="mb-4" type="date" onChange={ (e) => setStartAt(e.target.value) } value={ start_at } placeholder="Beginn" />
                        <label>Ende</label>
                        <input className="mb-4" type="date" onChange={ (e) => setEndAt(e.target.value) } value={ end_at } placeholder="Ende" />
                        <label>Minimaler Wert</label>
                        <input className="mb-4" type="number" onChange={ (e) => setLeastValue(e.target.value) } value={ least_value } placeholder={ `in ${kpiUnit}` } />
                        <label>Zielwert</label>
                        <input className="mb-4" type="number" onChange={ (e) => setValue(e.target.value) } value={ value } placeholder={ `in ${kpiUnit}` } />
                        <label>Optimaler Wert</label>
                        <input className="mb-4" type="number" onChange={ (e) => setBestValue(e.target.value) } value={ best_value } placeholder={ `in ${kpiUnit}` } />
                    </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ onClose }>
                    Abbrechen
                </Button>
                <Button variant="primary" onClick={ onSave }>
                    { mode === "edit" ? "Speichern" : "Erstellen" }
                </Button>
            </Modal.Footer>
        </Modal>
    )
}