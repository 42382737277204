import { getBase, getHelper } from "lib/api";

const API_URL = getBase("toolset");

export const loadPollRun = (session, pollRunId, onSuccess = () => {}, onError = () => {}) => {
    const url = `${API_URL}/poll_run/${pollRunId}`;

    getHelper(session, url, onSuccess, onError);
};

export const loadPollEval = (
    session,
    pollEvalId,
    pollRunId,
    onSuccess = () => {},
    onError = () => {}
) => {
    const url = `${API_URL}/poll_eval/${pollEvalId}/poll_run/${pollRunId}`;

    getHelper(session, url, onSuccess, onError);
};
