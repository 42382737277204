import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

/*
Example Usage:
<SuTooltip key={action.title} placement={"bottom"} tooltipId={action.icon} text={action.title}>
    <span
    key={ action.title }
    className="mr-2 su-table-action-icon action-icon-big"
    onClick={(e) => { e.preventDefault(); action.callback(run, pollName, session, link); }}
    >
        <img src={ iconSvgs[action.icon] } alt={action.title} />
    </span>
</SuTooltip>
*/
const SuTooltip = ({ placement, tooltipId, text, children }) => (
  <OverlayTrigger
    placement={placement}
    delay={{ show: 250, hide: 250 }}
    overlay={
      <Tooltip
        className="su-tooltip font-size-1"
        id={`tooltip-${tooltipId}`}
      >
        {text}
      </Tooltip>
    }
  >
    {children}
  </OverlayTrigger>
);
export default SuTooltip;
