import homeIcon from "styles/images/home.svg";
import editIcon from "styles/images/edit.svg";
import accountIcon from "styles/images/user.svg";
import teamIcon from "styles/images/team.svg";
import teamsIcon from "styles/images/teams.svg";
import exitIcon from "styles/images/exit.svg";
import { userCan } from "lib/permissions";
import { find } from "lodash";

const HeaderProfile = ({ session, newUserData, newTeamData }) => {
    const numAccounts = session.profile.accounts.length;
    const handleTeamChange = () => {
        //account picker
        window.location.pathname = "";
        window.localStorage.removeItem("accountID");
    };

    const handleLogout = () => {
        window.localStorage.removeItem("apiKey");
        window.location.reload();
        window.location.pathname = "/";
    };

    return (
        <div>
            <div className="container">
                <div className="d-flex align-items-lg-center align-items-top mb-4">
                    <a
                        href={session.accountId + "?profile-editor"}
                        className="order-2 order-lg-1 mr-3"
                    >
                        {session.profile.image ? (
                            <img
                                src={
                                    newUserData ? newUserData.image.url : session.profile.image.url
                                }
                                className="su-avatar su-avatar-lg"
                                alt=""
                            />
                        ) : (
                            <span className="su-avatar su-avatar-lg su-avatar-placeholder"></span>
                        )}
                    </a>
                    <div className="order-1 order-lg-2 header-profile">
                        <h1 className="color-orange mb-0">
                            Hi {newUserData ? newUserData.firstname : session.profile.firstname}!
                            {session.profile.is_sup_coach ? (
                                <span className="badge badge-info bg-orange font-size-1">
                                    scale up Coach
                                </span>
                            ) : null}
                        </h1>

                        <p>
                            {/*display owner if owner of current account*/}
                            {session.profile.owner_of_accounts.some(
                                (e) => e.id === parseInt(session.accountId)
                            ) ? (
                                <span className="color-orange">Owner, </span>
                            ) : null}
                            {/* filter roles for roles of current account,
                            if roles exist -> map over roles and display all roles of current account
                            if no role assigned -> display Mitglied
                            */}
                            {session.profile.roles.filter((element) => {
                                return element.account_id === parseInt(session.accountId);
                            }).length ? (
                                session.profile.roles
                                    .filter((element) => {
                                        return element.account_id === parseInt(session.accountId);
                                    })
                                    .map((role, index) => (
                                        <span key={role.role_id} className="color-orange">
                                            {(index ? ", " : "") + role.role_languages[0].title}
                                        </span>
                                    ))
                            ) : (
                                <span className="color-orange">Mitglied</span>
                            )}
                            <nobr className="color-light-grey">
                                {newTeamData?.name
                                    ? newTeamData.name
                                    : find(session.profile.accounts, {
                                          id: parseInt(session.accountId),
                                      }).name || ""}
                            </nobr>
                        </p>
                        <p className="mb-0">
                            <a
                                href={session.accountId + "?applying"}
                                className="color-light-grey mr-3 font-size-1 d-inline-flex align-items-center"
                            >
                                <img
                                    src={homeIcon}
                                    width="12"
                                    height="12"
                                    align="center"
                                    alt=""
                                    loading="lazy"
                                    className="mr-1"
                                />
                                <span>Dashboard</span>
                            </a>

                            <a
                                href={session.accountId + "?profile-editor"}
                                className="color-light-grey mr-3 font-size-1 d-inline-flex align-items-center"
                            >
                                <img
                                    src={editIcon}
                                    width="12"
                                    height="12"
                                    align="center"
                                    alt=""
                                    loading="lazy"
                                    className="mr-1"
                                />
                                <span>Profil editieren</span>
                            </a>

                            {userCan(session, "ACCOUNT_ACCESS") && (
                                <a
                                    href={session.accountId + "?account-editor"}
                                    className="color-light-grey mr-3 font-size-1 d-inline-flex align-items-center"
                                >
                                    <img
                                        src={accountIcon}
                                        width="12"
                                        height="12"
                                        align="center"
                                        alt=""
                                        loading="lazy"
                                        className="mr-1"
                                    />
                                    <span>Account editieren</span>
                                </a>
                            )}

                            {userCan(session, "TEAM_ACCESS") && (
                                <a
                                    href={session.accountId + "?team-editor"}
                                    className="color-light-grey mr-3 font-size-1 d-inline-flex align-items-center"
                                >
                                    <img
                                        src={teamIcon}
                                        width="12"
                                        height="12"
                                        align="center"
                                        alt=""
                                        loading="lazy"
                                        className="mr-1"
                                    />
                                    <span>Team verwalten</span>
                                </a>
                            )}

                            {userCan(session, "TEAM_SWITCH") &&
                                (numAccounts > 1 || session.profile.is_sup_coach === true) && (
                                    <a
                                        href="/"
                                        onClick={handleTeamChange}
                                        className="color-light-grey mr-3 font-size-1 d-inline-flex align-items-center"
                                    >
                                        <img
                                            src={teamsIcon}
                                            width="12"
                                            height="12"
                                            align="center"
                                            alt=""
                                            loading="lazy"
                                            className="mr-1"
                                        />
                                        <span>Team wechseln</span>
                                    </a>
                                )}

                            <a
                                href="/"
                                onClick={handleLogout}
                                className="color-light-grey mr-3 font-size-1 d-inline-flex align-items-center"
                            >
                                <img
                                    src={exitIcon}
                                    width="12"
                                    height="12"
                                    align="center"
                                    alt=""
                                    loading="lazy"
                                    className="mr-1"
                                />
                                <span>Ausloggen</span>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default HeaderProfile;
