import React, { useState } from 'react';

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import SessionContext from 'lib/session';

import * as authService from "./api.js";

export function InviteUserModal( props ) {
    const [ firstname, setFirstname ] = useState("");
    const [ lastname, setLastname ] = useState("");
    const [ email, setEmail ] = useState("");

    return (<Modal show={true} centered>
        <Modal.Header>
            <Modal.Title>Benutzer hinzufügen</Modal.Title>
        </Modal.Header>
        <Modal.Body className="su-form">
            <div className="form-group mb-4">
                <label htmlFor="firstname">Vorname</label>
                <input id="firstname" type="text" name="firstname" required className="form-control" placeholder="Vorname eingeben" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
            </div>
            <div className="form-group mb-4">
                <label htmlFor="lastname">Nachname</label>
                <input id="lastname" type="text" name="lastname" required className="form-control" placeholder="Nachname eingeben" value={lastname} onChange={(e) => setLastname(e.target.value)} />
            </div>
            <div className="form-group mb-4">
                <label htmlFor="email">E-Mail</label>
                <input id="email" type="email" name="email" required className="form-control" placeholder="Email eingeben" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.cancelCallback}>
                Abbrechen
            </Button>

            <SessionContext.Consumer>{session => (
                <Button variant="primary" onClick={
                    (e) => authService.inviteUserToAccount(session, firstname, lastname, email, props.successCallback)
                } >
                    Fortfahren
                </Button>
            ) }</SessionContext.Consumer>
        </Modal.Footer>
    </Modal>);
}