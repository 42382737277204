import { useContext, useEffect, useRef, useState } from "react";

import IconCheck from "styles/images/check.svg";
import IconEdit from "styles/images/edit.svg";
import SessionContext from "lib/session";
import * as service from "pages/ToolsKPI/api";
import * as toasts from "lib/toasts";
import { emptyValue, KpiValue } from "components/KpiValue";
import { useOutsideAlerter } from "lib/outside_alerter";

const KpiStatus = ({ id, onSave, focus = "goal" }) => {
    const session = useContext(SessionContext);
    const [newValue, setNewValue] = useState(null);
    const [kpi, setKpi] = useState(false);
    const [activeForm, setActiveForm] = useState(false);
    const wrapperRef = useRef(null);
    const resetForm = () => {
        setActiveForm("");
        setNewValue(null);
    };
    useOutsideAlerter(wrapperRef, () => resetForm());

    useEffect(() => {
        service.loadKpi(session, id, setKpi, console.error);
    }, [session, id]);

    const onInputKeyDown = (event) => {
        if (event.key === "Enter") {
            onSaveValue();
        }
        if (event.key === "Escape") {
            resetForm();
        }
    };
    const onSaveValue = () => {
        service.updateKpi(session, id, { value: newValue }, onClose, toasts.makeErrorToast);
    };
    const onClose = () => {
        service.loadKpi(session, id, setKpi, console.error);
        resetForm();
    };

    const { unit, kpi_goals, value, evaluation } = kpi;
    const { least_value, value: target_value, best_value } = (kpi_goals || [])[0] || {};
    const evaluationColor = {
        meets_least_value: "warning",
        meets_value: "success",
        meets_best_value: "green-dark",
    }[evaluation];
    const editable = !!onSave;

    if (kpi === false) {
        return "Laden...";
    }

    const renderedGoals = (
        <div>
            <KpiValue value={least_value} unit={unit} type="pill" color="warning" />
            <KpiValue value={target_value} unit={unit} type="pill" color="success" />
            <KpiValue value={best_value} unit={unit} type="pill" color="green-dark" />
        </div>
    );
    const renderedValue =
        activeForm === "value-edit" ? (
            <div className="px-3 text-nowrap">
                <input
                    type="text"
                    value={
                        newValue === null ? (Math.abs(value) === emptyValue ? "" : value) : newValue
                    }
                    onChange={(e) => setNewValue(e.target.value)}
                    onKeyDown={onInputKeyDown}
                    placeholder="Neuen Wert eingeben"
                    autoFocus
                />
                <span
                    className="btn btn-primary ml-1"
                    role="button"
                    onClick={onSaveValue}
                    style={{
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        paddingTop: "4px",
                        paddingBottom: "9px",
                    }}
                >
                    <img alt="Speichern" src={IconCheck} width="20" className="filter-white" />
                </span>
            </div>
        ) : (
            <span
                className=""
                role={editable ? "button" : null}
                onClick={editable ? () => setActiveForm("value-edit") : null}
            >
                <KpiValue value={value} unit={unit} color={evaluationColor} type="lg" />
                {editable && (
                    <img
                        alt="Bearbeiten"
                        src={IconEdit}
                        width="15"
                        className="ml-2 align-baseline"
                    />
                )}
            </span>
        );

    return focus === "goal" ? (
        <div className="text-center" ref={wrapperRef}>
            {renderedGoals}
            <div className="pt-3" />
            {renderedValue}
        </div>
    ) : (
        <div className="text-center" ref={wrapperRef}>
            {renderedValue}
            <div className="pt-3" />
            {renderedGoals}
        </div>
    );
};
export default KpiStatus;
