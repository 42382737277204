import { getBase, getHelper, postHelper } from "lib/api";
import { findIndex, flatten, map } from "lodash";
import { collectContent } from "components/Document/lib";
import { loadJourney } from "pages/LearningJourney/api";

const API_URL = getBase("knowledge");

export const loadNugget = (session, journeyId, nuggetId, onSuccess = ()=>{}, onError = ()=>{} ) => {
    const url = `${API_URL}/nugget/${nuggetId}/journey/${journeyId}/account/${session.accountId}`;

    getHelper(session, url, (nugget) => {
        const sortedContent = collectContent(nugget)

        // We need the previous and next nugget from the journey
        loadJourney(session, journeyId, (journey) => {
            const allNuggets = flatten(map(journey.content, (content) => (
                content.type === "path" ? content.nuggets : content
            )));
            const nuggetIndex = findIndex(allNuggets, {"id": nugget.id})
            const previousNugget = allNuggets[nuggetIndex - 1] || null
            const nextNugget = allNuggets[nuggetIndex + 1] || null

            // Return all data
            onSuccess({
                ...nugget,
                content: sortedContent,
                previousNugget,
                nextNugget,
            });
        }, onError)

    }, onError);
}

export const setNuggetStatus = (session, nuggetId, status, journeyId, onSuccess, onError = console.error) => {
    const url = `${API_URL}/nugget/${nuggetId}/user/${session.profile.id}/status/${status}`;

    postHelper(session, url, {journey_id: journeyId, account_id: session.accountId}, onSuccess, onError);
};

export const loadNuggetStatus = (session, nuggetId, onSuccess = ()=>{}, onError = console.error ) => {
    const url = `${API_URL}/nugget/${nuggetId}/user/${session.profile.id}/status`;

    getHelper(session, url, onSuccess, onError);
}