import { useState } from "react";
import SessionContext from 'lib/session';
import * as toasts from 'lib/toasts';

const LoginAuth = ({ onSubmit, submitState, allAnswers, requiredAnswers }) => (
    <div className="d-flex justify-content-center">
        <div className="d-inline-block">
            <SessionContext.Consumer>{session => (
                <SubmitForm session={ session } onSubmit={ onSubmit } submitState={ submitState } allAnswers={allAnswers} requiredAnswers={requiredAnswers} />
            )}</SessionContext.Consumer>
        </div>
    </div>
)
export default LoginAuth

const SubmitForm = ({ onSubmit, session, submitState, allAnswers, requiredAnswers }) => {
    const [disabled, setDisabled] = useState(false)

    const onSubmitButton = (e) => {
        e.preventDefault()

        if (Object.keys(allAnswers).length === 0){
            //no answers given -> no submit, show error toast
            toasts.makeErrorToast("Bitte mindestens alle Pflichtfragen beantworten", "toastSubmit");

        } else if (Object.keys(requiredAnswers).length === 0){
            //no required answers but answers given -> submit
            setDisabled(true)
            onSubmit(session)

        } else if (Object.keys(requiredAnswers).every(key => allAnswers[key] !== undefined)) {
            //required answers exist and all required answers are included in allAnswers -> submit
            setDisabled(true)
            onSubmit(session)

        } else {
            //not all required answers have an answer -> no submit, show error toast
            toasts.makeErrorToast("Bitte zuerst alle Pflichtfragen (*) beantworten", "toastSubmit");
        }
    }

    return (
        <div className="row mb-3">
            { submitState === "valid" ? (
                <div className="col-12">
                    <div className="alert alert-success">Danke für deine Teilname - du kannst dieses Fenster nun schließen. Wir wünschen dir noch einen tollen Tag!</div>
                </div>
            ) : (
                <form className="col-8" action="#" onSubmit={ onSubmitButton }>
                    <p className="mt-2">
                        <button className={"btn btn-primary "+(disabled ? "disabled" : "")} type="submit">Antworten abschicken</button>
                    </p>
                </form>
            ) }
        </div>
    )
}
