import { translate } from "lib/language";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Tooltip } from "react-bootstrap";
import ToggleButton from "./items/togglebutton"
import Stars from "./items/stars"
import helpIcon from "styles/images/help.svg"
import Input from "./items/input";
import Textinput from "./items/textinput";
import Dropdown from "./items/dropdown";
import Slider from "./items/slider";

import SuTooltip from "components/Tooltip";

import { useState, useEffect } from "react";
import { debounce } from 'lib/debounce';

const modules = {
    "togglebutton": ToggleButton,
    "stars": Stars,
    "input" : Input,
    "textinput" : Textinput,
    "dropdown" : Dropdown,
    "slider" : Slider,
}
const DefaultEntry = (props) => (
    // <pre>Could not render: { JSON.stringify(props) }</pre>
    <pre>Could not render: { props.input_type } { props.type }</pre>
)
const renderEntry = (entry, currentWindowWidth) => {
    // if on sm and md size device and input_type_mobile is set -> use input_type_mobile
    // otherwise use input_type or show Default Entry if no input_type is set
    const Entry = (currentWindowWidth <= 820 || navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i))
        && entry.input_type_mobile !== "" ?
        modules[entry.input_type_mobile] :
        modules[entry.input_type] || DefaultEntry;

    return <Entry { ...entry } currentValue={ entry.answers[entry.name] } />;
}

const renderHelpText = ( text ) => {
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    );

    return (
        <OverlayTrigger
            placement="auto"
            delay={{ show: 150, hide: 400 }}
            overlay={renderTooltip}
            >
            <img src={ helpIcon } width="14" height="14" alt="Help" />
        </OverlayTrigger>
    );
}

export const PollItem = (definition) => {
    let fieldName = definition.name;
    let formElement = "";
    let requiredSymbol = definition.required ? 
        <SuTooltip key={fieldName} placement={"right"} tooltipId={fieldName} text="Pflichtfrage!">
         <span className="p-1 color-orange">{'\u00A0'}*</span> 
        </SuTooltip>
        : '';
    let caption = translate( definition, 'caption' );
    let title = translate( definition, 'title' );
    let helpText = translate( definition, 'description' ); 
    const deviceIsMobile= (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i)) ? true : false;
    const [currentWindowWidth, setDimensions] = useState(window.innerWidth || document.documentElement.clientWidth );

    /*rerender if window gets resized (only every 0.2s),
    use debounce function to prevent rerendering on every pixel change */
    const handleResize = () => {setDimensions(window.innerWidth || document.documentElement.clientWidth)};

    useEffect(() => {
        const debouncedHandleResize = debounce(handleResize, 200)    
        window.addEventListener('resize', debouncedHandleResize);
        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    })

    if(title === "" && deviceIsMobile === false) {
        return (
            <div key={fieldName+"row"} data-attribute={fieldName} className="row mb-5 align-items-center">
                <div className="col-6 text-right">
                    {caption}{requiredSymbol} {(helpText && renderHelpText(helpText) )}
                </div>
                <div className="col-6">
                    {formElement}
                    { renderEntry(definition, currentWindowWidth) }
                </div>
            </div>
        )

    }

    return (
        <div key={fieldName+"row"} data-attribute={fieldName} className="mb-5 d-flex flex-column align-items-center justify-content-center">
            <div className="text-center font-size-4 font-italic mb-5">
                {title || caption}{requiredSymbol} {(helpText && renderHelpText(helpText) )}
            </div>
            <div>
                { renderEntry(definition, currentWindowWidth) }
            </div>
        </div>
    )
}