import { useEffect, useState } from "react";

import DeleteConfirmModal from "components/DeleteConfirmModal";
import ActionsMenu from "components/ActionsMenu";
import * as toasts from "lib/toasts";

import * as service from "./api";
import GoalModal from "./goal_modal";
import { map } from "lodash";
import { formatDate } from "lib/language";
import KpiAttachModal from "./kpi_attach_modal";
import GoalKpi from "./goal_kpi";
import { userCan } from "lib/permissions";

const Goal = ({
    id,
    session,
    onChange,
    title,
    description,
    start_at,
    end_at,
    active,
    user_id,
    type,
}) => {
    const [activeForm, setActiveForm] = useState(null);
    const [kpis, setKpis] = useState(false);

    useEffect(() => {
        service.loadKpis(session, id, setKpis, console.error);
    }, [session, id]);

    const onDelete = () => {
        setActiveForm(null);
    };
    const onSelfChanged = () => {
        setActiveForm(null);
        service.loadKpis(session, id, setKpis, console.error);
    };
    const onAllChanged = () => {
        setActiveForm(null);
        onChange();
    };

    const renderForm = () => {
        switch (activeForm) {
            case "goal-delete":
                return (
                    <DeleteConfirmModal
                        title="Ziel löschen"
                        text={`das Ziel ${title}`}
                        onClose={onDelete}
                        onDelete={() =>
                            service.deleteGoal(session, id, onChange, toasts.makeErrorToast)
                        }
                    />
                );

            case "goal-edit":
                return (
                    <GoalModal
                        onClose={onAllChanged}
                        session={session}
                        goalId={id}
                        mode="edit"
                        defaults={{
                            title,
                            description,
                            start_at,
                            end_at,
                            active,
                            user_id,
                            type: type,
                        }}
                    />
                );

            case "kpi-attach":
                return <KpiAttachModal goalId={id} onClose={onSelfChanged} session={session} />;

            default:
                return;
        }
    };

    return (
        <div className={`${active ? "card" : "card card--inactive"} mb-4 p-2 py-3 px-3`}>
            {renderForm()}

            {/* Action Menu */}
            <div className="position-absolute mt-1" style={{ right: 0 }}>
                {userCan(session, "TOOLS_GOAL_EDIT") && (
                    <a
                        href="/"
                        className="btn btn-primary border d-inline-block px-3 py-1 mr-3"
                        onClick={(e) => e.preventDefault() || setActiveForm("kpi-attach")}
                    >
                        KPI +
                    </a>
                )}
                <ActionsMenu
                    className="mr-3 position-relative d-inline"
                    actions={[
                        userCan(session, "TOOLS_GOAL_EDIT") && {
                            icon: "edit",
                            text: "Bearbeiten",
                            onClick: () => setActiveForm("goal-edit"),
                        },
                        userCan(session, "TOOLS_GOAL_DELETE") && {
                            icon: "delete",
                            text: "Löschen",
                            onClick: () => setActiveForm("goal-delete"),
                        },
                    ]}
                />
            </div>

            {/* Title */}
            <h2>
                {title}
                <span className="d-inline" style={{ fontSize: "1.5rem", fontWeight: "normal" }}>
                    {" "}
                    {(start_at || end_at) && (
                        <span>
                            {formatDate(start_at, "∞")} bis {formatDate(end_at, "∞")}
                        </span>
                    )}
                </span>
            </h2>
            <p>{description}</p>

            {kpis === false && "Laden..."}
            {kpis.length === 0 && "Noch keine KPIs hinzugefügt."}

            <div className="row d-flex">
                {map(kpis, (kpi) => (
                    <GoalKpi {...kpi} key={kpi.id} goalId={id} onChange={onSelfChanged} />
                ))}
            </div>
        </div>
    );
};
export default Goal;
