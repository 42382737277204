import { ResponsiveRadar } from "@nivo/radar";
import { TableTooltip } from "@nivo/tooltip";
import { translate } from "lib/language";
import { fromPairs, map } from "lodash";

const Spider = (chart) => {
    /*
    const example = {
        name: "detaield status mit benchmark",
        type: "spider",
        data_names: ["average"],
        languages: [
            {
                code: "DE",
                data_titles: ["", "", "", "", "", "", "", "", "", "", "", "", ""],
                data_labels: [
                    "Fühlst du dich in deinem Commitment gesehen?",
                    "Kommst du gerne ins Büro?",
                    "Habt ihr einen achtsamen und wertschätzenden Umgang miteinander?",
                    "Fühlst du einen Team Spirit mit deinen Kolleg:innen?",
                    "Fragt deine Führungskraft nach Feedback?",
                    "Hast du Freude an deiner Arbeit?",
                    "Geht es bei euch fair zu?",
                    "Seid ihr alle ein Team?",
                    "Hast du den Eindruck im 21. Jahrhundert angekommen zu sein?",
                    "Wird bei euch auch an die Umwelt gedacht?",
                    "Hast du ein hinreichend faires Gehalt?",
                    "Arbeitest du gerne für diese Firma?",
                    "Wirst du genügend gefordert und hast den Eindruck genug zu lernen?",
                ],
                title: "Detailauswertung mit Benchmark",
            },
        ],
        data: [
            {
                type: "decimal",
                values: [
                    3.8461538461538463, 4.384615384615385, 4.642857142857143, 4.142857142857143, 4.166666666666667,
                    4.428571428571429, 4.538461538461538, 4.3076923076923075, 4.615384615384615, 4.076923076923077,
                    3.9285714285714284, 4.785714285714286, 4.642857142857143,
                ],
                unit: [],
                languages: [{ code: "DE", title: "Wir" }],
            },
            {
                type: "decimal",
                decimals: 2,
                values: [
                    3.8032786885245904, 4.080645161290323, 4.161290322580645, 4.145161290322581, 3.9833333333333334,
                    4.096774193548387, 4.19672131147541, 4.05, 4.311475409836065, 3.9344262295081966,
                    3.8524590163934427, 4.327868852459017, 4.163934426229508,
                ],
                unit: [],
                languages: [{ code: "DE", title: "Benchmark" }],
            },
        ],
        pos: 0,
    };
    */
    const input = chart.data;
    const decimals = chart.decimals || 3;

    // These are the N corners of our spider/radar diagram
    // ["Kommst du gerne ins Büro?", "Habt ihr einen achtsa", ...]
    const data_labels_raw = translate(chart, "data_labels");
    const data_titles_raw = translate(chart, "data_titles");

    // Often times labels or titles are missing from one side or the other. To
    // ensure charts always render, we backfill them on both ends
    const data_labels = map(data_labels_raw, (label, index) => label || data_titles_raw[index]);
    const data_titles = map(data_titles_raw, (label, index) => label || data_labels_raw[index]);

    // Transform into:
    // [
    //     {
    //         label: "Gehst du gerne ins Büro?"
    //         Benchmark: 4.17
    //         Wir: 3.8
    //     }
    // ]
    const data = map(data_labels, (label, index) => ({
        ...fromPairs(
            map(input, (entry) => [translate(entry, "title") || index, entry.values[index]])
        ),
        label: label,
    }));
    const keys = map(input, (entry) => translate(entry, "title"));
    //if no title exists, keys resolves to [false] and the chart does not render correctly

    return (
        <div>
            <h3 className="text-center">{translate(chart, "title")}</h3>
            <div style={{ height: `${chart.height || 800}px`, width: `${chart.width || 800}px` }}>
                <ResponsiveRadar
                    data={data}
                    keys={keys}
                    indexBy="label"
                    valueFormat={`>-.${decimals}f`}
                    margin={{ top: 150, right: 150, bottom: 150, left: 150 }}
                    borderColor={{ from: "color" }}
                    gridLabelOffset={24}
                    dotSize={10}
                    dotColor={{ theme: "background" }}
                    dotBorderWidth={2}
                    colors={{ scheme: "nivo" }}
                    blendMode="multiply"
                    motionConfig="wobbly"
                    sliceTooltip={({ index, data }) => (
                        <TableTooltip
                            title={data_titles[data_labels.indexOf(index)]}
                            rows={map(data, (row) => [row.id, row.formattedValue])}
                        />
                    )}
                />
            </div>
        </div>
    );
};
export default Spider;
