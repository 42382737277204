import ActionsMenu from "components/ActionsMenu";
import { formatDate } from "lib/language";
import { useState } from "react";
import { KpiValue } from "../../components/KpiValue";

import * as toasts from "lib/toasts";
import * as service from "./api";
import DeleteConfirmModal from "components/DeleteConfirmModal";
import { GoalModal } from "./kpi_goal_modal";
import { userCan } from "lib/permissions";

export const KpiGoal = ({
    kpiId,
    id,
    least_value,
    value,
    best_value,
    start_at,
    end_at,
    unit,
    onChange,
    session,
}) => {
    const [activeForm, setActiveForm] = useState(null);

    const onClose = () => {
        setActiveForm(null);
        onChange();
    };
    const renderForm = () => {
        switch (activeForm) {
            case "goal-delete":
                return (
                    <DeleteConfirmModal
                        title="KPI Ziel löschen"
                        text="das KPI Ziel"
                        onClose={onClose}
                        onDelete={() =>
                            service.deleteKpiGoal(session, id, onClose, toasts.makeErrorToast)
                        }
                    />
                );

            case "goal-edit":
                return (
                    <GoalModal
                        kpiId={kpiId}
                        goalId={id}
                        kpiUnit={unit}
                        onClose={onClose}
                        session={session}
                        defaults={{
                            least_value,
                            value,
                            best_value,
                            start_at,
                            end_at,
                        }}
                        mode="edit"
                    />
                );

            default:
                return;
        }
    };

    return (
        <div className="pb-3">
            {renderForm()}
            {userCan(session, "TOOLS_KPI_EDIT") && (
                <ActionsMenu
                    className="mr-3 position-relative d-inline float-right"
                    actions={[
                        {
                            icon: "edit",
                            text: "Bearbeiten",
                            onClick: () => setActiveForm("goal-edit"),
                        },
                        {
                            icon: "delete",
                            text: "Löschen",
                            onClick: () => setActiveForm("goal-delete"),
                        },
                    ]}
                />
            )}
            <p>
                {formatDate(start_at)} bis {formatDate(end_at)}
            </p>
            <KpiValue value={least_value} unit={unit} type="pill" color="warning" />
            <KpiValue value={value} unit={unit} type="pill" color="success" />
            <KpiValue value={best_value} unit={unit} type="pill" color="green-dark" />
        </div>
    );
};
