import { map } from "lodash";
import { useState, useEffect } from "react";

import * as service from "./api";

const InputUserSearch = ({ session, label, value, onChange }) => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        service.loadUsers(session, setUsers);
    }, [session]);

    return (
        <div>
            <select onChange={(e) => onChange(parseInt(e.target.value))} value={value}>
                <option value="" name="" key="">
                    {label} auswählen...
                </option>
                {map(users, (user) => (
                    <option value={user.id} name={user.id} key={user.id}>
                        {`${user.firstname} ${user.lastname}`}
                    </option>
                ))}
            </select>
        </div>
    );
};
export default InputUserSearch;
