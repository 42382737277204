import { getBase, getHelper, postHelper, deleteHelper } from "lib/api";

const ADMIN_URL = getBase("admin");

export const loadApiKeys = (session, onSuccess = () => {}, onError = () => {}) => {
    const url = `${ADMIN_URL}/account_apikey/${session.accountId}`;

    getHelper(session, url, onSuccess, onError);
};

export const createApiKey = (session, apikey, onSuccess = () => {}, onError = () => {}) => {
    const url = `${ADMIN_URL}/account_apikey`;
    postHelper(session, url, apikey, onSuccess, onError);
};

export const deleteApiKey = (session, account_apikey_id, onSuccess, onError = console.error) => {
    const url = `${ADMIN_URL}/account_apikey/${account_apikey_id}`;

    deleteHelper(session, url, onSuccess, onError);
};
