import { ResponsiveRadar } from '@nivo/radar'

const RadarChart = ({ data, keys /* see data tab */ }) => (
    <ResponsiveRadar
        data={data}
        keys={keys}
        indexBy="name"
        valueFormat=">-.2f"
        margin={{ top: 20, right: 150, bottom: 20, left: 150 }}
        borderColor={{ from: 'color' }}
        gridLabelOffset={24}
        dotSize={10}
        dotColor={{ theme: 'background' }}
        dotBorderWidth={2}
        colors={{ scheme: 'nivo' }}
        blendMode="multiply"
        motionConfig="wobbly"
        />
);
export default RadarChart;