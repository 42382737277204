import { translate } from "lib/language";
import { map } from "lodash";
import { PollItem } from "./poll_item";

const PollGroup = (group) => {
    const title = group.title //passed group.title is "" if only one group exists for this poll
    const description = translate( group, "description" );

    const subGroups = map(group.poll_subgroups || [], (subgroup, index) => (
        <PollGroup { ...subgroup } level={ group.level + 1 } key={ index } answers={ group.answers } onChange={ group.onChange } />
    ))
    if (group.level > 1) {
        return (
            <div key={"group-"+group.name} data-group={group.name}>
                {title ? <h3 className="text-center pb-4 pl-4 pr-4 font-size-4">{title}</h3> : null}
                {subGroups}
                { map(group.poll_items, (item, index) => (
                    <PollItem { ...item } key={ index } answers={ group.answers } onChange={ group.onChange } />
                )) }
            </div>
        )
    }

    const icon = group.thumbnail ? (
        <div className="text-center ml-adjust-pseudo my-5"><img src={group.thumbnail.url} width={group.thumbnail.meta.width/2} height={group.thumbnail.meta.height/2} alt="" /></div>
    ) : null

    return (
        <div key={"group-"+group.name} data-group={group.name}>
            {icon} {!icon && group.pos !== 1  ? <div className="text-center my-3" style={{minHeight: "50px"}}></div> : null}

            <div className="su-border su-with-border-headline">
                <div className="su-border-headline"><h2>{title}</h2></div>
                <div className="card">
                    <div className="card-body">
                        {description && (
                            <p className="mb-4">{ description }</p>
                        ) }
                        { subGroups }
                        { map(group.poll_items, (item, index) => (
                            <PollItem { ...item } key={ index } answers={ group.answers } onChange={ group.onChange } />
                        )) }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PollGroup