import { useEffect, useState } from 'react';
import { MenuLearning } from "components/Menus";
import Document from 'components/Document';

import * as service from "./api";

const ChartDummy = ({ session }) => {
    const [charts, setCharts] = useState([])

    useEffect(() => {
        service.loadChart(session, setCharts, console.error)
    }, [session])

    if (charts.length === 0) {
        return (
            <div>
                <MenuLearning />
                <p>Laden...</p>
            </div>
        )
    }

    return (
        <div>
            <MenuLearning />

            <Document content={ charts } />
        </div>
    )
}
export default ChartDummy;