import { translate } from "lib/language";
import { map, sum } from "lodash";
import { renderContent } from "..";
import { collectContent } from "../lib";

const Block = (block) => {
    const { column_schema } = block;
    const content = collectContent(block);

    // Convert to base-12 grid
    const all_contents = sum(column_schema);
    const factor = 12 / all_contents;
    const columnClass = (index) =>
        all_contents === 0
            ? // Column should be natural size
              "col-auto"
            : // Loop through column_schema
              `col-${column_schema[index % column_schema.length] * factor}`;

    return (
        <div>
            <h2 className="mb-lg-4">{translate(block, "title")}</h2>
            <div className="row justify-content-center">
                {map(content, (item, index) => (
                    <div className={columnClass(index)} key={index}>
                        {renderContent({
                            ...item,

                            // Increase depth to recalculate title sizes etc
                            depth: (block.depth || 1) + 1,
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
};
export default Block;
