import { getHeaders, getBase } from "lib/api";

const API_URL = getBase("auth");

export const magicLinkLogin = (magic_token, onSuccess = ()=>{}, onError = ()=> {}) => {
    fetch(API_URL+'/auth/magic/login',
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify( { magic_token: magic_token } )
        })
        .then(response => response.json())
        .then(data => {
            if(data.message) {
                onError(data.message);
            } else {
                onSuccess(data);
            }
        });
};
