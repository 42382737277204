import { formatTimestamp } from "lib/language";
import { FaTrashAlt } from "react-icons/fa";
import * as service from "./api";
import { useEffect, useState, useRef } from "react";
import HeaderProfile from "components/HeaderProfile";
import DeleteConfirmModal from "components/DeleteConfirmModal";

import * as toasts from "lib/toasts";

const ProfileEditor = ({ session }) => {
    const [user, setUser] = useState({});
    const [selectedFile, setFile] = useState(null);
    const hiddenFileInput = useRef(null);
    const [password, setPassword] = useState(null);
    const [confirmationPassword, setConfirmationPassword] = useState(null);
    const [email, setEmail] = useState(session.profile.email);
    const [firstname, setFirstname] = useState(session.profile.firstname);
    const [lastname, setLastname] = useState(session.profile.lastname);
    const [modal, setModal] = useState("");
    const form = useRef();

    useEffect(() => {
        service.loadUser(session, setUser, console.error);
    }, [session]);

    if (!user.id) {
        return "Laden...";
    }

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const onChangeFile = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile.size > 2000000) {
            toasts.makeErrorToast(
                "Datei zu groß! Bitte eine Datei mit einer Größe von maximal 2 MB auswählen."
            );
        } else if (
            selectedFile.type !== "image/jpeg" &&
            selectedFile.type !== "image/jpg" &&
            selectedFile.type !== "image/png"
        ) {
            toasts.makeErrorToast(
                "Format nicht unterstützt! Bitte eine Datei im Format .jpg, .jpeg oder .png auswählen."
            );
        } else setFile(selectedFile);
    };

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };

    const onChangeFirstname = (e) => {
        const firstname = e.target.value;
        setFirstname(firstname);
    };
    const onChangeLastname = (e) => {
        const lastname = e.target.value;
        setLastname(lastname);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };
    const onChangeConfirmationPassword = (e) => {
        const confirmationPassword = e.target.value;
        setConfirmationPassword(confirmationPassword);
    };

    const handleSave = (e) => {
        e.preventDefault();

        //if values changed, save new user data
        if (firstname !== user.firstname || lastname !== user.lastname || email !== user.email) {
            service.updateProfileData(session, firstname, lastname, email, setUser, console.error);
        }

        //if new password input, change password
        if (password !== null && confirmationPassword !== null) {
            service.changePassword(session, password, confirmationPassword, setUser, console.error);
        }
        //if file is selected, upload picture
        if (selectedFile !== null) {
            const formData = new FormData();
            formData.append("image_file", selectedFile);
            formData.append("user_id", user.id);

            service.uploadProfilePicture(session, formData, setUser, console.error);
        }
    };

    const makeDeleteProfileModal = () => {
        setModal(
            <DeleteConfirmModal
                title="Profil löschen"
                text="dein Profil"
                onClose={() => setModal()}
                onDelete={() => {
                    service.deleteUser(session, setUser);
                    setModal();
                }}
            />
        );
    };

    return (
        <div>
            <HeaderProfile session={session} newUserData={user} />

            <div className="container">
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a className="nav-link active" href="?profile-editor">
                            Profil editieren
                        </a>
                    </li>
                </ul>
            </div>
            <div className="row" id="su-profile-editor">
                <form action="/" onSubmit={handleSave} ref={form} className="su-form p-4">
                    <div className="card-body">
                        <div className="form-group mb-4">
                            <label>Profil erstellt am</label>
                            <p>{formatTimestamp(user.created_at)}</p>
                        </div>

                        <div className="form-group mb-4">
                            <label htmlFor="email">E-Mail</label>
                            <input
                                type="text"
                                name="email"
                                onChange={onChangeEmail}
                                value={email}
                                placeholder="E-Mail Adresse eingeben"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="firstname">Vorname</label>
                            <input
                                type="text"
                                name="firstname"
                                onChange={onChangeFirstname}
                                value={firstname}
                                placeholder="Vorname eingeben"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="lastname">Nachname</label>
                            <input
                                type="text"
                                name="lastname"
                                onChange={onChangeLastname}
                                value={lastname}
                                placeholder="Nachname eingeben"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="password">Passwort</label>
                            <input
                                type="password"
                                name="password"
                                onChange={onChangePassword}
                                value={password || ""}
                                placeholder="neues Passwort eingeben"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group mb-4">
                            <label htmlFor="confirmPassword">Passwort bestätigen</label>
                            <input
                                type="password"
                                name="confirmPassword"
                                onChange={onChangeConfirmationPassword}
                                value={confirmationPassword || ""}
                                placeholder="neues Passwort erneut eingeben"
                                className="form-control"
                            />
                        </div>
                        <div className="d-flex flex-column form-group mb-4">
                            <label htmlFor="file">Profilbild hochladen</label>
                            <input
                                type="file"
                                name="file"
                                ref={hiddenFileInput}
                                onChange={onChangeFile}
                                multiple={false}
                                accept=".jpg, .jpeg, .png"
                                className="form-control"
                                style={{ display: "none" }}
                            />
                            <button
                                type="button"
                                className="btn btn-primary w-100"
                                onClick={handleClick}
                            >
                                Datei auswählen
                                <span></span>
                            </button>
                            {selectedFile ? (
                                <>
                                    <span className="file-info">{selectedFile.name}</span>
                                    <span className="file-info ml-3">
                                        {Number((selectedFile.size / 1024 ** 2).toFixed(3)) + " MB"}
                                    </span>
                                </>
                            ) : (
                                <span className="file-info">{".jpg, .jpeg oder .png < 2 MB"}</span>
                            )}
                        </div>

                        <div className="form-group mb-4">
                            <button type="submit" className="btn btn-primary w-100">
                                Änderungen speichern
                            </button>
                        </div>

                        <div className="form-group">
                            <button
                                type="button"
                                className="btn btn-delete btn-w-icon w-100"
                                onClick={makeDeleteProfileModal}
                            >
                                Benutzer löschen
                                <FaTrashAlt className="icon-right" />
                            </button>
                        </div>
                    </div>
                </form>

                {modal}
            </div>
        </div>
    );
};

export default ProfileEditor;
