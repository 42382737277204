import { ResponsivePie } from "@nivo/pie";
import { translate } from "lib/language";
import { map } from "lodash";

const Gauge = (chart) => {
    // ['Promotoren', 'Detraktoren', 'Indifferente', 'NPS']
    const data_labels = translate(chart, "data_labels");

    // Input data:
    // [
    //     {
    //         type: "decimal"
    //         unit: ['%', '%', '%', '']
    //         values: [60.123, 30.123, 9.754, 30]
    //     }
    // ]
    const { values, unit } = chart.data[0];

    const data = map(data_labels, (label, index) => ({
        id: label,
        value: values[index] / (unit[index] === "%" ? 100 : 1),
    }));

    // Formatting: https://nivo.rocks/pie/
    const format =
        {
            "%": ">.0p",
        }[unit] || ">-";

    return (
        <div>
            <h3 className="text-center">{translate(chart, "title")}</h3>
            <div
                className="m-auto"
                style={{
                    width: `${chart.width || 220}px`,
                    height: `${chart.height || 150}px`,
                }}
            >
                <ResponsivePie
                    data={data}
                    margin={{ top: 0, right: 5, bottom: 0, left: 5 }}
                    startAngle={-90}
                    endAngle={90}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    borderWidth={1}
                    enableArcLabels={false}
                    enableArcLinkLabels={false}
                    arcLinkLabelsThickness={2}
                    arcLabelsSkipAngle={10}
                    activeOuterRadiusOffset={0}
                    valueFormat={format}
                />
            </div>
        </div>
    );
};

export default Gauge;
