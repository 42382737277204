import React, { useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import {Button} from "react-bootstrap";

import * as service from "./api.js";

const ForgotPassword = (props) => {
    const form = useRef();
    const errorMessage = props.errorMessage;
    const [email, setEmail] = useState(props.email || "");
    const [modal, setModal] = useState(null);

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setEmail(email);
    };

    const handleSignup = (e) => {
        e.preventDefault();
        service.getMagicLink(email, makeInfoModal);
    };

    const makeInfoModal = () => {
        const modal = (
          <Modal show={true} centered>
            <Modal.Header>
              <Modal.Title>Magic Login ohne Passwort</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>Wir haben dir einen Magic-Link an {email} gesendet.</p>
                <p>Logge dich mit deinem Link ein, danach kannst du im Profil Editor dein Passwort ändern.</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => props.toggleMode("login")}>
                Zum Login Formular
              </Button>
            </Modal.Footer>
          </Modal>
        );
    
        setModal(modal);
      };

    return (
        <div>
            <div className="d-flex justify-content-center align-items-center" style={{minHeight: "60vh"}}>
                <div className="d-inline-block">
                    <div className="su-border">
                        <form action="/" onSubmit={handleSignup} ref={form} className="card su-form su-login-form p-4 box-shadow">
                            <h1 className="card-header">Login ohne Passwort</h1>
                            <div className="card-body">
                                <div className="form-group mb-5">
                                    <label htmlFor="email">E-Mail deines Accounts:</label>
                                    <input type="text" name="email" onChange={onChangeEmail} value={email} placeholder="Deine Email" className="form-control"/>
                                </div>    
                                <div className="form-group text-center">
                                    <button type="submit" className="btn btn-primary">Login-Link anfordern</button>
                                </div>
                                <div className="form-group text-center">
                                    <a href="/" onClick={(e) => { props.toggleMode("login")}} className="color-light-grey font-size-1 mr-3 cursor-pointer">
                                    Zurück zum Login
                                    </a>
                                </div>
                            </div>
                            {errorMessage && (
                            <p className="mt-3 text-danger">{errorMessage}</p>
                            )}
                        </form>
                        {modal}
                    </div>
                </div>
            </div>
            <div className="py-lg-5"></div>
        </div>
    );
};
export default ForgotPassword