import { getBase, getHelper } from "lib/api";

const API_URL = getBase("toolset");

export const loadPollRun = (session, pollRunId, onSuccess = ()=>{}, onError = ()=>{} ) => {
    const url = `${API_URL}/poll_run/${pollRunId}`;

    getHelper(session, url, onSuccess, onError);
};

const remapStats = (onSuccess) => (data) => {
    let poll_items = {};
    let poll_groups = {};
    let poll_subgroups = {};

    if(data.hasOwnProperty('poll_items_stats')) {
        data.poll_items_stats.forEach((pi) => poll_items[pi.name] = pi);
        // The naming is slightly inconsistent on these for poll_run and poll
        (data.poll_group_stats || data.poll_groups_stats).forEach((pi) => poll_groups[pi.name] = pi);
        (data.poll_subgroup_stats || data.poll_subgroups_stats).forEach((pi) => poll_subgroups[pi.name] = pi);
    }

    onSuccess({
        poll_items,
        poll_groups,
        poll_subgroups,
    });
}

export const loadPollRunStats = (session, pollRunId, onSuccess = ()=>{}, onError = ()=>{} ) => {
    const url = `${API_URL}/poll_run/stats/${pollRunId}`;

    getHelper(session, url, remapStats(onSuccess), onError);
};

export const loadPollStats = (session, pollId, onSuccess = ()=>{}, onError = ()=>{} ) => {
    const url = `${API_URL}/poll/stats/${pollId}`;

    getHelper(session, url, remapStats(onSuccess), onError);
};