import { formatDate, translate } from "lib/language";
import { find } from "lodash";
import { useContext, useState } from "react";
import { copyToClipboard } from "lib/clipboard";
import SessionContext from "lib/session";
import copy from "styles/images/copy.svg";

import PollRunAction from "./pollrun_actions";
import { QrCodeModal } from "./modal-qr-code";
import DeleteConfirmModal from "components/DeleteConfirmModal";
import { EditPollRunModal } from "./modal-edit-pollrun";
import * as service from "./api";
import EvaluationModal from "./modal-evaluation";

const PollRun = ({ run, poll, team, onChange, usage }) => {
    const session = useContext(SessionContext);
    const [modal, setModal] = useState(null);
    const title = run.title || translate(poll, "title");
    const {
        id,
        uuid,
        created_by,
        begin_at,
        end_at,
        num_identities,
        has_participated,
        is_anonymous,
    } = run;
    const base = window.location.href.split("?")[0];
    const link = `${base}?applying/poll_form/${id}/${uuid}`;

    const closeModal = () => setModal(null);
    const changeCloseModal = () => {
        closeModal();
        onChange();
    };

    const renderModal = () => {
        switch (modal) {
            case "qrcode":
                return (
                    <QrCodeModal
                        session={session}
                        run={run}
                        link={link}
                        pollName={title}
                        onClose={closeModal}
                    />
                );

            case "evaluation":
                return (
                    <EvaluationModal
                        session={session}
                        run={run}
                        base={base}
                        onClose={closeModal}
                        usage={usage}
                    />
                );

            case "delete":
                return (
                    <DeleteConfirmModal
                        title="Umfrage löschen"
                        text="diese Umfrage"
                        onClose={closeModal}
                        onDelete={() => {
                            service.deletePollRun(session, run.id, () => {
                                onChange();
                            });
                        }}
                    />
                );

            case "edit":
                return (
                    <EditPollRunModal
                        session={session}
                        pollName={title}
                        run={run}
                        onChange={changeCloseModal}
                        onClose={closeModal}
                    />
                );

            default:
                return;
        }
    };

    return (
        <tr key={id}>
            <td className="align-middle">
                <span>{title}</span>
            </td>
            <td className="align-middle">
                {created_by === 0 || created_by === undefined
                    ? "unbekannt"
                    : `${find(team, { id: created_by }).firstname} ${
                          find(team, { id: created_by }).lastname
                      }`}
            </td>
            <td className="align-middle d-none d-lg-table-cell">{formatDate(begin_at)}</td>
            <td className="align-middle">{formatDate(end_at)}</td>
            <td className="align-middle text-center">{num_identities || 0}</td>
            <td className="align-middle text-center">
                {is_anonymous ? "anonym" : has_participated ? "ja" : "nein"}
            </td>
            <td className="align-middle">
                <a
                    href={link}
                    onClick={copyToClipboard(link, "Link")}
                    className="d-iblock pr-2 su-table-action-icon"
                >
                    <img className="icon-left" src={copy} width="14" height="14" alt="Kopieren" />
                    Link kopieren
                </a>
            </td>
            <td>
                {renderModal()}
                <PollRunAction
                    icon="open"
                    title="An Umfrage teilnehmen"
                    callback={() => window.open(link, "_blank")}
                />
                <PollRunAction
                    permission="TOOLS_POLLRUN_EVALUATION_ACCESS"
                    icon="eval"
                    title="Auswertung"
                    callback={() => setModal("evaluation")}
                />
                <PollRunAction
                    permission="TOOLS_POLLRUN_EDIT"
                    icon="edit"
                    title="Umfrage bearbeiten"
                    callback={() => setModal("edit")}
                />
                <PollRunAction
                    permission="TOOLS_POLLRUN_DELETE"
                    icon="delete"
                    title="Umfrage löschen"
                    callback={() => setModal("delete")}
                />
                <PollRunAction
                    icon="qr"
                    title="QR-Code erzeugen"
                    callback={() => setModal("qrcode")}
                />
            </td>
        </tr>
    );
};
export default PollRun;
