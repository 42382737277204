import { useEffect, useState } from "react";

import IconIntervalNone from "styles/images/interval_none.svg";
import IconIntervalYearly from "styles/images/interval_yearly.svg";
import IconIntervalQuarterly from "styles/images/interval_quarterly.svg";
import IconIntervalWeekly from "styles/images/interval_weekly.svg";
import IconIntervalMonthly from "styles/images/interval_monthly.svg";
import IconIntervalDaily from "styles/images/interval_daily.svg";
import IconMonotonyAscending from "styles/images/monotony_ascending.svg";
import IconMonotonyDescending from "styles/images/monotony_descending.svg";
import IconScopeAccount from "styles/images/scope_account.svg";
import IconScopeUser from "styles/images/scope_user.svg";

import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import ActionsMenu from "components/ActionsMenu";
import * as toasts from "lib/toasts";

import * as service from "./api";
import { GoalModal } from "./kpi_goal_modal";
import { map } from "lodash";
import { KpiGoal } from "./kpi_goal";
import KpiModal from "./kpi_modal";
import KpiStatus from "../../components/KpiStatus";
import { userCan } from "lib/permissions";

import placeholderHistoricValues from "styles/images/placeholder_historicKpiValues.png";

export const kpiIntervals = {
    none: {
        text: "keins",
        description: "fixer Termin",
        icon: IconIntervalNone,
    },
    day: {
        text: "täglich",
        description: "Täglicher Rhytmus",
        icon: IconIntervalDaily,
    },
    week: {
        text: "wöchentlich",
        description: "Wöchentlicher Rhytmus",
        icon: IconIntervalWeekly,
    },
    month: {
        text: "monatlich",
        description: "Monatlicher Rhytmus",
        icon: IconIntervalMonthly,
    },
    quarter: {
        text: "quartärlich",
        description: "Quartärlicher Rhytmus",
        icon: IconIntervalQuarterly,
    },
    year: {
        text: "jährlich",
        description: "Jährlicher Rhytmus",
        icon: IconIntervalYearly,
    },
};
export const kpiMonotonies = {
    increasing: {
        text: "steigt",
        description: "Es ist gut, wenn der Wert steigt",
        icon: IconMonotonyAscending,
    },
    decreasing: {
        text: "sinkt",
        description: "Es ist gut, wenn der Wert sinkt",
        icon: IconMonotonyDescending,
    },
};
export const kpiScopes = {
    account: {
        text: "Team",
        description: "Team KPI",
        icon: IconScopeAccount,
    },
    user: {
        text: "Person",
        description: "Persönlicher KPI",
        icon: IconScopeUser,
    },
};

const Kpi = ({ id, session, onDelete }) => {
    const [kpi, setKpi] = useState(null);
    const [activeForm, setActiveForm] = useState(null);

    useEffect(() => {
        service.loadKpi(session, id, setKpi, console.error);
    }, [session, id]);

    const onClose = () => {
        service.loadKpi(session, id, setKpi, console.error);
        setActiveForm(null);
    };

    if (kpi === null) {
        return <div className="card mb-4">Laden...</div>;
    }

    const { title, active, unit, name, kpi_goals: kpiGoals, user_id, user } = kpi;
    const interval = kpiIntervals[kpi.interval];
    const monotony = kpiMonotonies[kpi.monotony] || { text: kpi.monotony, src: null };
    const scope = kpiScopes[kpi.scope] || kpiScopes.account;

    const renderForm = () => {
        switch (activeForm) {
            case "kpi-delete":
                return (
                    <DeleteConfirmModal
                        title="KPI löschen"
                        text={`die KPI ${kpi.name}`}
                        onClose={onClose}
                        onDelete={() =>
                            service.deleteKpi(session, kpi.id, onDelete, toasts.makeErrorToast)
                        }
                    />
                );

            case "kpi-edit":
                return (
                    <KpiModal
                        onClose={onClose}
                        session={session}
                        kpiId={kpi.id}
                        mode="edit"
                        defaults={{
                            title,
                            unit,
                            name,
                            interval: kpi.interval,
                            monotony: kpi.monotony,
                            scope: kpi.scope,
                            user_id,
                            active,
                        }}
                    />
                );

            case "goal-create":
                return (
                    <GoalModal
                        kpiId={kpi.id}
                        kpiUnit={kpi.unit}
                        onClose={onClose}
                        session={session}
                        defaults={{}}
                    />
                );

            default:
                return;
        }
    };

    return (
        <div className={`${active ? "card" : "card card--inactive"} mb-4 p-2 py-3 px-3`}>
            {renderForm()}

            {/* Action Menu */}
            <div className="position-absolute mt-1" style={{ right: 0 }}>
                <img
                    width="40"
                    height="40"
                    alt={scope.description}
                    title={scope.description}
                    className="mr-3"
                    src={scope.icon}
                />
                <img
                    width="40"
                    height="40"
                    alt={interval.description}
                    title={interval.description}
                    className="mr-3"
                    src={interval.icon}
                />
                <img
                    width="40"
                    height="40"
                    alt={monotony.description}
                    title={monotony.description}
                    className="mr-3"
                    src={monotony.icon}
                />
                <ActionsMenu
                    className="mr-3 position-relative d-inline"
                    actions={[
                        userCan(session, "TOOLS_KPI_EDIT") && {
                            icon: "edit",
                            text: "Bearbeiten",
                            onClick: () => setActiveForm("kpi-edit"),
                        },
                        userCan(session, "TOOLS_KPI_DELETE") && {
                            icon: "delete",
                            text: "Löschen",
                            onClick: () => setActiveForm("kpi-delete"),
                        },
                    ]}
                />
            </div>

            {/* Title */}
            <h2>
                {user && (
                    <span
                        className="mr-2 rounded-circle d-inline-block align-top"
                        style={{
                            backgroundImage: `url(${user.image ? user.image.url : IconScopeUser})`,
                            backgroundSize: "cover",
                            width: "40px",
                            height: "40px",
                        }}
                    />
                )}
                {title}
                <span className="d-inline" style={{ fontSize: "1.5rem", fontWeight: "normal" }}>
                    {" "}
                    ({name})
                </span>
            </h2>

            <div className="row my-4">
                {/* KPI value left column */}
                <div className="col" style={{ zIndex: 5 }}>
                    <div className="px-2">
                        <KpiStatus
                            id={id}
                            onSave={active && userCan(session, "TOOLS_KPI_EDIT") ? onClose : null}
                        />
                    </div>
                </div>

                <div className="col" />

                {/* Goals right column */}
                <div className="col-6">
                    <div className="border px-3 mr-2">
                        <h3 className="mb-0">
                            {userCan(session, "TOOLS_KPI_EDIT") && (
                                <a
                                    href="/"
                                    className="btn btn-primary border float-right d-block px-3 py-1"
                                    onClick={(e) =>
                                        e.preventDefault() || setActiveForm("goal-create")
                                    }
                                >
                                    +
                                </a>
                            )}
                            <p className="my-3">KPI Ziele</p>
                        </h3>
                        {map(kpiGoals, (goal) => (
                            <KpiGoal
                                {...goal}
                                kpiId={kpi.id}
                                unit={unit}
                                key={goal.id}
                                session={session}
                                onChange={onClose}
                            />
                        ))}
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center w-100 mt-3 border ml-5 mr-4 p-1"><img className="" src={placeholderHistoricValues} height="75%" width="75%" alt="historicValues" /></div>
            </div>
        </div>
    );
};
export default Kpi;
