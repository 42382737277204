import React from "react";
import iconAdmin from 'styles/images/admin.svg';
import iconDelete from 'styles/images/delete.svg';
import iconOwner from 'styles/images/owner.svg';
import iconUser from 'styles/images/user.svg';
import iconMail from 'styles/images/mail.svg';
import SuTooltip from 'components/Tooltip';

import { translate } from "lib/language";

const iconSvgs = {
    "admin": iconAdmin,
    "delete": iconDelete,
    "owner": iconOwner,
    "user": iconUser,
    "mail": iconMail,
};

const TableRow = (props) => {
    // Todo: Replace with real translation engine
    const translations = {
        firstname: "Vorname",
        lastname: "Nachname",
        email: "E-Mail",
        roles: "Berechtigungen",
        created_at: "Beigetreten"
    }

    const renderActions = () => {
        const actions = props.actionsCallback( props.entry.id );

        if(!actions) {
            return "";
        }

        const identifier = props.entry.id;
        const icons = actions.map((action, index) => (
            <SuTooltip key={action.title} placement={"bottom"} tooltipId={action.icon} text={action.title}>
                <span
                key={"actions_"+action.icon+"_"+identifier+"-"+index}
                className="mr-2 su-table-action-icon action-icon-big"
                onClick={(e) => { e.preventDefault(); action.callback(identifier); }}
                >
                    <img src={ iconSvgs[action.icon] } alt={ action.icon } />
                </span>
            </SuTooltip>
        ));

        return <td>{icons}</td>;
    }

    if(props.isHeading) {

        let columns = props.fields.map((field, index) => {
            return field;
        });

        if(props.actionsCallback) {
            columns.push("Aktionen");
        }

        return (
            <tr>
                { columns.map((columnLabel, index) => (
                    <th key={columnLabel}>{translations[columnLabel] || columnLabel}</th>
                )) }
            </tr>
        );

    }

    const cssClasses = [];
    if(props.entry.hasOwnProperty("verified")) {
        if(props.entry["verified"] === true) {
            cssClasses.push("su-verified-entry");
        } else {
            cssClasses.push("su-unverified-entry");
        }
    }

    return (
        <tr className={cssClasses} key={ props.entry.id }>
            {props.fields.map((field) => {
                if(props.entry.hasOwnProperty(field)) {

                    let value = props.entry[field];

                    if(field === "roles") {
                        let tmp = [];

                        if(props.entry.hasOwnProperty('owner_of_account') && props.entry.owner_of_account === true) {
                            tmp.push("Owner");
                        }
                        value.forEach((v,i) => { tmp.push( translate(v.role_languages) ); });
                        value = tmp.join(", ");
                    }

                    if(field.match(/_at/)) {
                        var date = new Date(value);
                        value = date.toLocaleDateString('de-de');
                    }

                    return <td key={field}>{value}</td>;
                } else {
                    return <td key={field}></td>;
                }
            })}
            {renderActions()}
        </tr>
    );
}
export default TableRow;