import { translate } from "lib/language"

const Image = (entry) => (
    <div className="su-border mb-5">
        <figure className="bg-white p-3">
            <img src={ translate(entry, "url") } alt={ translate(entry, "title") } title={ translate(entry, "title") }  />
            <figcaption>{ translate(entry, "caption") }</figcaption>
        </figure>
    </div>
)
export default Image