import * as service from '../api';
import { useState } from 'react';
import * as toasts from 'lib/toasts';

const PinAuth = ({
  pollChecksum,
  submitState,
  setSubmitState,
  onSubmit,
  setIdentity,
  allAnswers,
  requiredAnswers,
  teamName,
}) => {
  const [disabled, setDisabled] = useState(false);
  return (
    <div className="d-flex justify-content-center mt-4">
      <div className="d-inline-block">
        <p>
          Im finalen Schritt validieren wir nun, ob du zu <span className='font-weight-bold'>{`${teamName} `}</span>
           gehörst.
          <br />
          Dazu senden wir an deine Firmen-Email Adresse einen PIN.
          <br />
          <b>
          Deine Antworten speichern wir anonym ohne Bezug zu deiner E-Mail-Adresse
          </b>
          .
        </p>

        <EmailForm
          submitState={submitState}
          setSubmitState={setSubmitState}
          setIdentity={setIdentity}
          pollChecksum={pollChecksum}
          allAnswers={allAnswers}
          requiredAnswers={requiredAnswers}
          disabled={disabled}
          setDisabled={setDisabled}
        />

        <PinForm
          onSubmit={onSubmit}
          onReset={(e) => {
            setDisabled(false);
            setSubmitState('email');
          }}
          submitState={submitState}
          disabled={submitState === "valid" }
          setDisabled={setDisabled}
        />
      </div>
    </div>
  );
};
export default PinAuth;

const EmailForm = ({
  submitState,
  setSubmitState,
  setIdentity,
  pollChecksum,
  allAnswers,
  requiredAnswers,
  disabled,
  setDisabled,
}) => {
  const [email, setEmail] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();

    if (Object.keys(allAnswers).length === 0) {
      //no answers given -> no submit, show error toast
      toasts.makeErrorToast(
        'Bitte mindestens alle Pflichtfragen beantworten',
        'toastSubmit'
      );
    } else if (Object.keys(requiredAnswers).length === 0) {
      //no required answers but answers given -> submit
      toasts.makeSuccessToast('E-Mail gesendet', 'toastSubmit');
      setDisabled(true);
      setSubmitState('pin');
      service.requestPin(pollChecksum, setIdentity)(email);
    } else if (
      Object.keys(requiredAnswers).every(
        (key) => allAnswers[key] !== undefined
      )
    ) {
      //required answers exist and all required answers are included in allAnswers -> submit
      toasts.makeSuccessToast('E-Mail gesendet', 'toastSubmit');
      setDisabled(true);
      setSubmitState('pin');
      service.requestPin(pollChecksum, setIdentity)(email);
    } else {
      //not all required answers have an answer -> no submit, show error toast
      toasts.makeErrorToast(
        'Bitte zuerst alle Pflichtfragen (*) beantworten',
        'toastSubmit'
      );
    }
  };

  return (
    <div className="mb-4">
      <form className={"mt-3 "+
            (submitState === 'valid' ? 'd-none' : '')} action="#" onSubmit={onSubmit}>
        <label className="" htmlFor="email">
          E-Mail
        </label>
        <div className="row ml-0 mr-0">
          <input
            className="col-8"
            type="email"
            name="email"
            placeholder="Business Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <button
            className={
              'col-4 btn btn-primary ' + (disabled ? 'disabled' : '')
            }
            type="submit"
          >
            PIN anfordern
          </button>
        </div>
      </form>
    </div>
  );
};

const PinForm = ({
  submitState,
  onReset,
  onSubmit,
  disabled,
  setDisabled,
}) => {
  const [pin, setPin] = useState('');

  const onChangePin = (e) => {
    const newPin = e.target.value;
    setPin(newPin);

    if (newPin.length === 4) {
      onSubmit(newPin);
    }
  };

  const message = {
    invalid: (
      <div>
        <span
          className="font-size-1 color-orange su-clickable"
          onClick={() => {
            setPin('');
            onReset();
          }}
        >
          Neuen PIN anfordern
        </span>
        {/*<p className="mt-2 alert alert-danger">
          Ungültiger PIN. Bitte kontrolliere deine Eingabe oder
          fordere einen neuen PIN an.
        </p>*/}
      </div>
    ),
    valid: (
      <div className="alert alert-success">
        Danke für deine Teilname - du kannst dieses Fenster nun
        schließen. Wir wünschen dir noch einen tollen Tag!
      </div>
    ),
  }[submitState];

  return (
    <div className="row mb-3 ml-0 mr-0">
      <div
        className={
          'col-12 mb-2 pl-0 pr-0 ' +
          (submitState === 'valid' ? 'd-none' : '')
        }
      >
        Bitte gib den erhaltenen 4-stelligen PIN ein, um die Umfrage abzuschicken:
      </div>
      <div className="col-4 pl-0 pr-0 mb-3">
        <input
          type="text"
          style={
            submitState === 'invalid'
              ? { outline: '2px double red' }
              : {}
          }
          className={
            'form-control text-center ' +
            (submitState === 'valid' ? 'd-none' : '')
          }
          name="pin"
          disabled={submitState === 'valid' || submitState === 'email'}
          value={pin}
          placeholder="PIN hier eingeben"
          maxLength="4"
          onChange={onChangePin}
        />
      </div>

      <div className="col-12 px-0">{message}</div>
    </div>
  );
};
